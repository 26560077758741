import {
  SalesforceObjectType,
  SalesforceIntegrationObjectTypeMapping,
  salesforceAccountListSchema,
  salesforceCaseListSchema,
  salesforceContactListSchema,
  salesforceOpportunityListSchema,
  salesforceObjectListSchema,
} from '@valuecase/common'
import SellerApi from '../../../api/SellerApi'
import { useSearchIntegrationItemsQuery } from '../hooks/useSearchIntegrationItemsQuery'

function getSchema(objectType: SalesforceObjectType) {
  const schema =
    objectType === 'Opportunity'
      ? salesforceOpportunityListSchema
      : objectType === 'Case'
        ? salesforceCaseListSchema
        : objectType === 'Account'
          ? salesforceAccountListSchema
          : objectType === 'Contact'
            ? salesforceContactListSchema
            : salesforceObjectListSchema
  return schema
}

async function searchSalesforceObjects<U extends SalesforceObjectType>(
  searchTerm: string,
  objectType: U,
  objectTypeName: string,
) {
  const res = await SellerApi.get<any, undefined>(
    `integrations/salesforce/search?objectType=${encodeURIComponent(objectTypeName)}&searchTerm=${encodeURIComponent(
      searchTerm,
    )}`,
  )
  const schema = getSchema(objectType)
  const parsedResult = schema.parse(res.payload?.records)
  return parsedResult as SalesforceIntegrationObjectTypeMapping[U][]
}

/**
 * Hook to search Salesforce opportunities accessible to the current user (via OAuth2).
 */
export function useSearchSalesforceObjects<U extends SalesforceObjectType>({
  enabled,
  searchTerm,
  objectType,
  objectTypeName,
}: {
  enabled?: boolean
  searchTerm: string
  objectType: U
  objectTypeName: string
}) {
  return useSearchIntegrationItemsQuery<SalesforceIntegrationObjectTypeMapping, U>({
    queryKeyPrefix: 'searchSalesforceOpportunities',
    enabled,
    searchTerm,
    objectType,
    objectTypeName,
    searchFunction: searchSalesforceObjects,
  })
}
