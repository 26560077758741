import { useQuery } from '@tanstack/react-query'
import SellerApi from '@/api/SellerApi'
import { TSalesforceIntegrationSettingsEntity } from '@valuecase/common'

/**
 * Hook to fetch Salesforce integration settings including available entities
 */
export function useReadSalesforceIntegrationSettings() {
  return useQuery<TSalesforceIntegrationSettingsEntity>(
    ['salesforce', 'integration-settings'],
    async () => {
      const response = await SellerApi.get<TSalesforceIntegrationSettingsEntity, unknown>(
        '/integrations/salesforce/settings',
      )
      if (!response.success) {
        console.error('Failed to fetch Salesforce integration settings', response)
        throw new Error('Failed to fetch Salesforce integration settings')
      }
      return response.payload
    },
  )
}
