import React, { HTMLAttributes, useMemo } from 'react'
import { cn } from '../lib'
import { BundledIonIcons, CustomIcons } from './CustomIcons'

export function IonIcon({
  src,
  name,
  className,
  ...props
}: {
  src?: string
  name?: string
} & HTMLAttributes<HTMLElement>) {
  const shouldUseCustomIcon = useMemo(() => {
    return name && name in CustomIcons
  }, [name])

  const shouldUseBundledIonIcon = useMemo(() => {
    return name && name in BundledIonIcons
  }, [name])

  if (name && shouldUseCustomIcon) {
    const CustomIcon = CustomIcons[name]
    return (
      <div
        className={cn(
          'ionicon ion-icon inline-block contain-strict fill-current box-content w-[1em] h-[1em] hydrated',
          className,
        )}
        {...props}
      >
        <div className={'icon-inner w-full h-full block'}>
          <CustomIcon
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className={cn('block w-full h-full')}
          />
        </div>
      </div>
    )
  }

  if (name && shouldUseBundledIonIcon) {
    const BundledIonIcon = BundledIonIcons[name]
    return (
      <div
        className={cn(
          'ionicon ion-icon inline-block contain-strict fill-current box-content w-[1em] h-[1em] hydrated',
          className,
        )}
        {...props}
      >
        <div className={'icon-inner w-full h-full block'}>
          <BundledIonIcon
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className={cn(
              'block w-full h-full fill-current stroke-current',
              '[&>.ionicon-fill-none]:fill-none [&>.ionicon-stroke-width]:stroke-[32px]',
            )}
          />
        </div>
      </div>
    )
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ion-icon
      aria-hidden={'true'}
      class={cn('ionicon ion-icon hydrated', className)}
      name={name}
      src={getSrc({ name, src })}
      {...props}
    />
  )
}

function getSrc({
  name,
  src,
}: {
  name: string | undefined
  src: string | undefined
}): string | undefined {
  if (src) {
    return src
  }
  if (name) {
    const baseUrlInput = import.meta.env.BASE_URL || ''
    const baseUrl = baseUrlInput.endsWith('/') ? baseUrlInput : baseUrlInput + '/'
    return `${baseUrl}ionicons/svg/${name}.svg`
  }
  return undefined
}
