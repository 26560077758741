import { Activity, WeaklyTypedActivity } from '@valuecase/common'
import { JSX, useCallback } from 'react'
import { placeholderValuePairs } from './PlaceholderValuePair'
import { RichTextFormatHelper } from './RichTextFormatHelper'
import { useDateFormatting } from '@valuecase/ui-components'
import { DateTime } from 'luxon'

export const useActivityTitle = () => {
  const { formatDateShort } = useDateFormatting()

  const getActivityTitle = useCallback(
    (data: WeaklyTypedActivity) => {
      const { defaultPlaceholdableProps } = placeholderValuePairs(data)
      const getBlockTitle = (data: any): string | undefined => {
        try {
          return RichTextFormatHelper.getPlainTextFromContent(
            data.block?.versions[0].asset?.data?.title,
            defaultPlaceholdableProps,
          )
        } catch (e) {
          return undefined
        }
      }

      const { metadata } = data

      const buyerName = data.name || data.email || 'A stakeholder'
      const taskName = metadata?.taskName || metadata?.taskTitle || 'Unknown task'
      // legacy events of type MAP_MILESTONE_COMPLETED have taskName instead of milestoneName
      const milestoneName = metadata?.milestoneName || metadata?.taskName || 'Unknown milestone'
      const assigneeName = metadata?.assigneeName || 'Unknown asignee'
      const dueDate = metadata?.dueDate || 'Unknown due date'
      const action = metadata?.quickActionName || 'Unknown action'
      const listItemTitle = metadata?.listItemTitle || 'Unknown list item'
      const link = metadata?.link || 'Unknown link'
      const daysOffset = metadata?.daysOffset || 0

      const fileName =
        metadata?.fileName ||
        data.node?.data?.fileName ||
        data.node?.data?.linkName ||
        'Unknown file'

      const pageName = () => {
        const name =
          data.page?.versions?.[0].asset?.data?.title || data.node?.data?.title || 'Unknown page'
        return <span className='font-bold'>{name}</span>
      }

      const blockName = () => {
        return <span className='font-bold'>{getBlockTitle(data) || 'Unknown block'}</span>
      }

      const templates: Partial<Record<Activity['type'], () => JSX.Element>> = {
        VIEW: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> visited the page {pageName()}
          </div>
        ),
        ATTACHMENT_LINK_COPIED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> copied the link to the file{' '}
            <span className='font-bold'>{fileName}</span>
          </div>
        ),
        DIRECT_LINK_TO_FILE_CLICKED: () => (
          <div className='activity-body'>
            A direct link to the file <span className='font-bold'>{fileName}</span> has been
            clicked.
          </div>
        ),
        ATTACHMENT_PREVIEW: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> opened the preview for file{' '}
            <span className='font-bold'>{fileName}</span>
          </div>
        ),
        ATTACHMENT_DOWNLOAD: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> downloaded the file{' '}
            <span className='font-bold'>{fileName}</span>
          </div>
        ),
        ATTACHMENT_PREVIEW_FULLSCREEN: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> opened the file{' '}
            <span className='font-bold'>{fileName}</span> in the block {blockName()} in full screen
            mode
          </div>
        ),

        // MAP
        MAP_MILESTONE_TASK_DETAILS_OPENED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> opened the task details of the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),

        MAP_MILESTONE_TASK_COMPLETED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> completed the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),
        MAP_MILESTONE_TASK_ADDED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> added the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),
        MAP_MILESTONE_TASK_CHANGED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> changed a task name to{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),
        MAP_MILESTONE_TASK_DELETED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> deleted the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),

        // MAP TASK ASSIGNEE
        MAP_MILESTONE_TASK_ASSIGNEE_ADDED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> assigned{' '}
            <span className='font-bold'>{assigneeName}</span> to the task{' '}
            <span className='font-bold'>{taskName}</span>.
          </div>
        ),
        MAP_MILESTONE_TASK_ASSIGNEE_DELETED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> deleted the assignee{' '}
            <span className='font-bold'>{assigneeName}</span> from task{' '}
            <span className='font-bold'>{taskName}</span>.
          </div>
        ),

        // MAP TASK DUE DATE
        MAP_MILESTONE_TASK_DUE_DATE_ADDED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> set the due date{' '}
            <span className='font-bold'>
              {formatDateShort(DateTime.fromISO(dueDate, { zone: 'utc' }))}
            </span>{' '}
            for task <span className='font-bold'>{taskName}</span>.
          </div>
        ),
        MAP_MILESTONE_TASK_DUE_DATE_DELETED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> deleted the due date of task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),
        MAP_MILESTONE_TASK_DUE_DATE_CHANGED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> changed the due date of the task{' '}
            <span className='font-bold'>{taskName}</span> to{' '}
            {formatDateShort(DateTime.fromISO(dueDate, { zone: 'utc' }))}
          </div>
        ),

        // MAP TASK FILES
        MAP_MILESTONE_TASK_ATTACHMENT_UPLOADED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> uploaded a file to the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),
        MAP_MILESTONE_TASK_DOWNLOAD_FILE: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> downloaded the file{' '}
            <span className='font-bold'>{fileName}</span> from the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),
        MAP_MILESTONE_TASK_DELETE_FILE: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> deleted the file{' '}
            <span className='font-bold'>{fileName}</span> from the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),

        // MAP TASK OTHER
        MAP_MILESTONE_TASK_QUICK_ACTION_CLICK: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> clicked on the quick action{' '}
            <span className='font-bold'>{action}</span> of the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),

        // MAP DESCRIPTION
        MAP_MILESTONE_TASK_DESCRIPTION_ADDED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> added a description to the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),
        MAP_MILESTONE_TASK_DESCRIPTION_CHANGED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> edited the description of the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),
        MAP_MILESTONE_TASK_DESCRIPTION_DELETED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> deleted the description of the task{' '}
            <span className='font-bold'>{taskName}</span>
          </div>
        ),

        // MAP MILESTONE
        MAP_MILESTONE_ADDED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> added the milestone{' '}
            <span className='font-bold'>{milestoneName}</span> on page {pageName()}.
          </div>
        ),
        MAP_MILESTONE_DELETED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> deleted the milestone{' '}
            <span className='font-bold'>{milestoneName}</span> on page {pageName()}.
          </div>
        ),
        MAP_MILESTONE_COMPLETED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> completed the milestone{' '}
            <span className='font-bold'>{milestoneName}</span>
          </div>
        ),

        // BUSINESS CASE
        BUSINESS_CASE_RECALCULATED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> recalculated the calculator results on
            page {pageName()}.
          </div>
        ),

        SPACE_LINK_COPIED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> copied the invite link
          </div>
        ),

        PDF_CONTENT_VIEW: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> read pages 1 to {metadata.maxPageRead}{' '}
            of the document <span className='font-bold'>{metadata.fileName}</span> in the block{' '}
            {blockName()}.
          </div>
        ),
        TESTIMONIAL_VIEW: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> interacted {metadata.interactions} times
            with the card block {blockName()}
          </div>
        ),
        INLINE_VIDEO_PLAY: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> watched the video{' '}
            <span className='font-bold'>{metadata.fileName}</span> in the block {blockName()}
          </div>
        ),
        BLOCK_INTRO_VIDEO_PLAY: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> watched the block intro video of the
            block {blockName()}
          </div>
        ),
        COLLABORATIVE_BLOCK_EDITED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> edited the text box {blockName()}
          </div>
        ),
        INTERACTIVE_LIST_ITEM_OPENED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> opened the accordion item{' '}
            <span className='font-bold'>{listItemTitle}</span>
          </div>
        ),

        COMMENT: () => {
          if (data.node?.type === 'BLOCK_MAP_MILESTONE_TASK') {
            return (
              <div className='activity-body'>
                <span className='buyerTitle'>{buyerName}</span> added a comment to the task{' '}
                <span className='font-bold'>{taskName}</span>
              </div>
            )
          } else {
            return (
              <div className='activity-body'>
                <span className='buyerTitle'>{buyerName}</span> wrote a comment on block{' '}
                <span className='font-bold'>{blockName()}</span>
              </div>
            )
          }
        },

        SPACE_COMMENT: () => {
          return (
            <div className='activity-body'>
              <span className='buyerTitle'>{buyerName}</span> added a general comment to the space{' '}
              <span className='font-bold'>{data.node?.data?.companyName || 'Untitled'}</span>
            </div>
          )
        },

        BLOCK_LINK_COPIED: () => {
          return (
            <div className='activity-body'>
              <span className='buyerTitle'>{buyerName}</span> copied the link to block{' '}
              <span className='font-bold'>{blockName()}</span>
            </div>
          )
        },

        TASK_LINK_COPIED: () => {
          return (
            <div className='activity-body'>
              <span className='buyerTitle'>{buyerName}</span> copied the link to task{' '}
              <span className='font-bold'>{taskName}</span>
            </div>
          )
        },

        BLOCK_FILE_UPLOADED: () => {
          return (
            <div className='activity-body'>
              <span className='buyerTitle'>{buyerName}</span> uploaded a file to block{' '}
              <span className='font-bold'>{blockName()}</span>
            </div>
          )
        },
        ARCHIVED_VIEW: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> tried to access this archived space.
            Maybe a good opportunity to reconnect?
          </div>
        ),
        BUYER_NOTIFICATION_SENT_MILESTONE_DONE: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>
              A notification email was sent to{' '}
              <span className='font-bold'>{metadata?.notifiedUserEmail || 'a stakeholder'}</span>
            </span>{' '}
            for a completed milestone on block <span className='font-bold'>{blockName()}</span>
          </div>
        ),
        BUYER_NOTIFICATION_SENT_TASK_ASSIGNED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>
              A notification email was sent to <span className='font-bold'>{buyerName}</span>
            </span>{' '}
            because a task was assigned to them on block{' '}
            <span className='font-bold'>{blockName()}</span>
          </div>
        ),
        BUYER_NOTIFICATION_SENT_COMMENT: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>
              A notification email was sent to <span className='font-bold'>{buyerName}</span>
            </span>{' '}
            for a comment on block <span className='font-bold'>{blockName()}</span>
          </div>
        ),
        BUYER_NOTIFICATION_SENT_SPACE_COMMENT: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>
              A notification email was sent to <span className='font-bold'>{buyerName}</span>
            </span>{' '}
            for a general comment on the space
          </div>
        ),
        BUYER_NOTIFICATION_SENT_TASK_DUE_TODAY: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>
              A notification email was sent to <span className='font-bold'>{buyerName}</span>
            </span>{' '}
            because one of their tasks is due.
          </div>
        ),
        BUYER_NOTIFICATION_SENT_TASK_DUE_X_DAYS_AGO: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>
              A notification email was sent to <span className='font-bold'>{buyerName}</span>
            </span>{' '}
            because one of their tasks is overdue by {daysOffset}{' '}
            {daysOffset === 1 ? 'day' : 'days'}.
          </div>
        ),
        BUYER_NOTIFICATION_SENT_TASK_DUE_IN_X_DAYS: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>
              A notification email was sent to <span className='font-bold'>{buyerName}</span>
            </span>{' '}
            because one of their tasks is due in {Math.abs(daysOffset)}{' '}
            {Math.abs(daysOffset) === 1 ? 'day' : 'days'}.
          </div>
        ),

        LINK_CLICKED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> opened the link{' '}
            <span className={'font-bold'}>{link}</span> on page {pageName()}.
          </div>
        ),

        FORM_QUESTION_ANSWERED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> responded{' '}
            <span className='font-bold'>{data.metadata?.answer}</span> to the question{' '}
            <span className='font-bold'>{data.metadata?.questionText}</span> on page {pageName()}
          </div>
        ),
        FORM_QUESTION_EDITED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> edited the question{' '}
            <span className='font-bold'>{data.metadata?.questionText}</span> with the answer{' '}
            <span className='font-bold'>{data.metadata?.answer}</span> on page {pageName()}
          </div>
        ),
        FORM_COMPLETED: () => (
          <div className='activity-body'>
            <span className='buyerTitle'>{buyerName}</span> completed the form{' '}
            <span className='font-bold'>{blockName()}</span> on page {pageName()}
          </div>
        ),
      }

      try {
        const template = templates[data.type]
        if (!template) {
          return null
        }
        return template()
      } catch (e) {
        return null
      }
    },
    [formatDateShort],
  )

  return {
    getActivityTitle,
  }
}
