import {
  ColumnDef,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  VisibilityState,
} from '@tanstack/react-table'
import { getCompanyInitials } from '@valuecase/common'
import {
  AvatarThumbnail,
  Badge,
  cn,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
  Highlight,
  Input2,
  MenuDropdown,
  MenuDropdownItem,
  MenuDropdownSeparator,
  ModalControls,
  OneLineTruncatedText,
  useDateFormatting,
  useOverlay,
} from '@valuecase/ui-components'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useLDflags } from '../../../launchdarkly/Launchdarkly'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  useReactTable,
  SortingState,
} from '@tanstack/react-table'
import { DataTable } from '@valuecase/ui-components'
import { usePagination } from '@valuecase/ui-components'
import TemplateCreatorModal from '../TemplateCreator/TemplateCreatorModal'
import TemplateDeleteModal from '../TemplateDelete/TemplateDeleteModal'
import TemplateEditorModal, { EditTemplateData } from '../TemplateEditor/TemplateEditorModal'
import { TemplateCreatorLibraryItemData } from '../types/TemplateCreatorLibraryItemData'
import { TemplateCreatorLibraryItemsData } from '../types/TemplateCreatorLibraryItemsData'
import { TemplateCreatorTemplateDetails } from '../types/TemplateCreatorTemplateDetails'
import TemplatesEmptyState from './assets/templates_empty_state.png'
import { TemplateArchivingTrigger } from './components/TemplateArchiving/TemplateArchivingTrigger'
import TeamsColumn from '@/modules/teams/TeamsColumn'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { useLocalStorage } from '@/utils/useLocalStorage'
import { useSearch, useNavigate } from '@tanstack/react-router'
import { templatesRoute } from '@/modules/navigation/Router'
import { useDebounceCallback } from 'usehooks-ts'
import { SpaceOwnerFilterDropdown } from '@/modules/filtering/SpaceOwnerFilterDropdown'
import { useHasPermission } from '@/auth/permissions'

export interface Props {
  templates: TemplateCreatorLibraryItemsData
  showCreateTemplate: (templateToSelect?: TemplateCreatorLibraryItemData) => void
}

const STORAGE_COLUMN_VISIBILITY_KEY = 'templatesTable_columnVisibility'

const TemplatesList: FC<Props> = ({ templates, showCreateTemplate }) => {
  const overlay = useOverlay()
  const storage = useLocalStorage()
  const { trackEvent } = useTrackEvent()
  const controls = useRef<ModalControls | null>(null)
  const { flags, isLoading: isLoadingFlags } = useLDflags()
  const navigate = useNavigate()
  const hasSpacesAllAdministrationPermission = useHasPermission('SPACES_ALL_ADMINISTRATION')
  const { formatDateWithRelativeWords } = useDateFormatting()

  const { ownerIds, search, status } = useSearch({ from: templatesRoute.id })
  const [searchValue, setSearchValue] = useState(search || '')

  const setSearch = useCallback(
    (search: string) => {
      trackEvent({
        event: 'templates-search',
        eventProperties: {
          indexPageName: 'Templates List',
        },
      })
      navigate({
        to: '/templates',
        search: (prev) => ({ ...prev, search }),
      })
    },
    [navigate, trackEvent],
  )

  const setSearchDebounced = useDebounceCallback(setSearch, 300)

  const handleTemplateStatusFilterChange = useCallback(
    (status: 'active' | 'archived' | 'all') => {
      navigate({
        to: '/templates',
        search: (prev) => ({ ...prev, status }),
      })
      trackEvent({
        event: 'templates-status-change',
        eventProperties: {
          indexPageName: 'Templates List',
          status,
        },
      })
    },
    [navigate, trackEvent],
  )

  const handleResetFilters = useCallback(() => {
    setSearchValue('')
    handleTemplateStatusFilterChange('active')
    navigate({
      to: '/templates',
      search: (prev) => ({
        ...prev,
        search: undefined,
        ownerIds: undefined,
        status: undefined,
      }),
    })
  }, [navigate, handleTemplateStatusFilterChange])

  const showFilterResetButton = useMemo(() => {
    return searchValue.length > 0 || status !== 'active' || ownerIds
  }, [searchValue.length, status, ownerIds])

  const filteredTemplates = useMemo(() => {
    switch (status) {
      case 'archived':
        return templates.filter((template) => template.archived)
      case 'active':
        return templates.filter((template) => !template.archived)
      case 'all':
        return templates
    }
  }, [status, templates])

  const showCreateTemplateOverlay = useCallback(
    (templateToSelect?: TemplateCreatorLibraryItemData) => {
      overlay.show(
        <TemplateCreatorModal controlsRef={controls} initialSelectedTemplate={templateToSelect} />,
      )
    },
    [overlay],
  )

  const showEditTemplateOverlay = useCallback(
    (templateData: EditTemplateData) => {
      overlay.show(<TemplateEditorModal controlsRef={controls} templateData={templateData} />)
    },
    [overlay],
  )

  const showDeleteTemplateOverlay = useCallback(
    (template: TemplateCreatorTemplateDetails) => {
      overlay.show(
        <TemplateDeleteModal
          onSuccessCallback={() => {
            trackEvent({
              event: 'templates-delete-confirm',
              eventProperties: {
                indexPageName: 'Delete Template Modal',
                templateId: template?.id || 'unknownTemplateId',
              },
            })
          }}
          controlsRef={controls}
          template={template}
        />,
      )
    },
    [overlay, trackEvent],
  )

  const getTemplateUrl = useCallback((templateId: string): string => {
    const url = new URL(location.href)

    url.pathname = `/spaces/${templateId}`
    url.search = ''

    return url.href
  }, [])

  const columns = useMemo<ColumnDef<TemplateCreatorLibraryItemData>[]>(() => {
    const columns: ColumnDef<TemplateCreatorLibraryItemData>[] = [
      {
        id: 'title',
        accessorKey: 'title',
        header: 'Template Name & Description',
        enableHiding: false,
        cell: (ctx) => (
          <div className='flex items-center gap-3'>
            <div
              className={cn(
                'flex h-11 w-11 items-center justify-center rounded-lg bg-grey-s1 flex-shrink-0',
                'border border-grey-s2',
              )}
            >
              <span className='text-2xl'>{ctx.row.original.emojiIcon || '🚀'}</span>
            </div>
            <div className='flex flex-col'>
              <span className='text-sm font-semibold'>
                <OneLineTruncatedText>
                  <Highlight searchTerm={search ?? ''}>{ctx.row.original.title}</Highlight>
                </OneLineTruncatedText>
              </span>
              <span className='text-grey-s5 text-sm'>
                <OneLineTruncatedText>
                  <Highlight searchTerm={search ?? ''}>{ctx.row.original.description}</Highlight>
                </OneLineTruncatedText>
              </span>
            </div>
          </div>
        ),
        size: 250,
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: 'Created',
        cell: (ctx) => (
          <span className='capitalize'>
            {formatDateWithRelativeWords(ctx.getValue() as string, 'short')}
          </span>
        ),
        size: 90,
      },
      {
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        header: 'Last changed',
        cell: (ctx) => (
          <span className='capitalize'>
            {formatDateWithRelativeWords(ctx.getValue() as string, 'short')}
          </span>
        ),
        size: 132,
      },
      {
        id: 'spacesCreated',
        accessorKey: 'spacesCreated',
        header: 'Spaces Created',
        size: 144,
      },
      {
        id: 'createdByDetails',
        accessorKey: 'createdByDetails',
        header: 'Created by',
        cell: (ctx) => (
          <div className='flex items-center gap-3'>
            {ctx.row.original.createdByDetails.picture ? (
              <AvatarThumbnail
                type='image'
                imageUrl={ctx.row.original.createdByDetails.picture}
                size='LARGE'
                shape='CIRCLE'
              />
            ) : (
              <AvatarThumbnail
                type='gravatarInitials'
                initials={getCompanyInitials(ctx.row.original.createdByDetails.name)}
                size='LARGE'
                shape='CIRCLE'
              />
            )}
            <span>{ctx.row.original.createdByDetails?.name}</span>
          </div>
        ),
        accessorFn: (row) => row.createdByDetails?.id,
        filterFn: 'arrIncludesSome',
        size: 180,
      },
    ]

    if (flags && flags['teams-management'] === true) {
      columns.push({
        id: 'teams',
        header: 'Teams',
        cell: (ctx) => <TeamsColumn teams={ctx.row.original.teams} />,
      })
    }

    columns.push(
      {
        id: 'language',
        accessorKey: 'language',
        header: 'Language',
        enableSorting: false,
        size: 70,
      },
      {
        id: 'actions',
        header: '',
        size: 80,
        enableHiding: false,
        cell: (ctx) => (
          <div className='flex justify-start w-16'>
            <div className='flex items-center gap-2'>
              <MenuDropdown
                Trigger={<Button2 leadingIcon='ellipsis-vertical' size='small' variant='plain' />}
                placement='bottom-start'
              >
                <MenuDropdownItem
                  label='Open'
                  onClick={() => {
                    trackEvent({
                      event: 'templates-open-current_tab',
                      eventProperties: {
                        indexPageName: 'Templates List',
                        templateId: ctx.row.original?.rootNodeId || 'undefined',
                      },
                    }).finally(() => {
                      if (ctx.row.original.rootNodeId) {
                        window.location.href = getTemplateUrl(ctx.row.original.rootNodeId)
                      }
                    })
                  }}
                  LeftIcon={'log-in-outline'}
                  variant='default'
                />
                <MenuDropdownItem
                  onClick={() => {
                    trackEvent({
                      event: 'templates-open-new_tab',
                      eventProperties: {
                        indexPageName: 'Templates List',
                        templateId: ctx?.row.original.rootNodeId || 'undefined',
                      },
                    })

                    if (ctx.row.original.rootNodeId) {
                      window.open(
                        getTemplateUrl(ctx.row.original.rootNodeId),
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                  }}
                  label='Open in new tab'
                  LeftIcon={'open-outline'}
                  variant='default'
                />

                <MenuDropdownSeparator />

                <MenuDropdownItem
                  onClick={() => {
                    trackEvent({
                      event: 'templates-edit-open',
                      eventProperties: {
                        indexPageName: 'Templates List',
                        templateId: ctx?.row.original.id || 'undefined',
                      },
                    })
                    showEditTemplateOverlay({
                      id: ctx.row.original.id,
                      title: ctx.row.original.title,
                      description: ctx.row.original.description,
                      icon: ctx.row.original.emojiIcon,
                      rootNodeId: ctx.row.original.rootNodeId,
                      teamIds: ctx.row.original.teams.map((team) => team.id),
                    })
                  }}
                  label='Edit name & description'
                  LeftIcon={'create-outline'}
                  variant='default'
                />
                <MenuDropdownItem
                  label='Duplicate template'
                  LeftIcon={'copy-outline'}
                  onClick={() => {
                    trackEvent({
                      event: 'templates-duplicate',
                      eventProperties: {
                        indexPageName: 'Templates List',
                        templateId: ctx?.row.original.id || 'undefined',
                      },
                    })
                    showCreateTemplate({
                      createdAt: ctx.row.original.createdAt,
                      createdBy: ctx.row.original.createdBy,
                      createdByDetails: ctx.row.original.createdByDetails,
                      id: ctx.row.original.id,
                      title: ctx.row.original.title + ' copy',
                      updatedAt: ctx.row.original.updatedAt,
                      description: ctx.row.original.description,
                      rootNodeId: ctx.row.original.rootNodeId,
                      emojiIcon: ctx.row.original.emojiIcon,
                      language: ctx.row.original.language,
                      archived: ctx.row.original.archived,
                      spacesCreated: 0,
                      teams: ctx.row.original.teams,
                    })
                  }}
                />

                <MenuDropdownSeparator />
                {!isLoadingFlags && flags && flags.archiving && ctx.row.original.rootNodeId && (
                  <TemplateArchivingTrigger
                    templateId={ctx.row.original.rootNodeId}
                    data={{
                      title: ctx.row.original.title,
                      description: ctx.row.original.description,
                      icon: ctx.row.original.emojiIcon || '🚀',
                    }}
                    isCurrentlyArchived={ctx.row.original.archived ?? false}
                  />
                )}
                <MenuDropdownItem
                  onClick={() => {
                    trackEvent({
                      event: 'templates-delete-open_modal',
                      eventProperties: {
                        indexPageName: 'Templates List',
                        templateId: ctx.row.original?.id || 'unknownTemplateId',
                      },
                    })
                    showDeleteTemplateOverlay(ctx.row.original)
                  }}
                  label='Delete Template'
                  LeftIcon={'trash-outline'}
                  variant='danger'
                />
              </MenuDropdown>
            </div>
          </div>
        ),
      },
    )

    return columns
  }, [
    flags,
    search,
    formatDateWithRelativeWords,
    isLoadingFlags,
    trackEvent,
    getTemplateUrl,
    showEditTemplateOverlay,
    showCreateTemplate,
    showDeleteTemplateOverlay,
  ])

  const { pageIndex, pageSize, setPagination } = usePagination()
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const [sorting, setSorting] = useState<SortingState>([{ id: 'createdAt', desc: true }])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    storage.getJSONValue(STORAGE_COLUMN_VISIBILITY_KEY) ?? {
      updatedAt: false,
      spacesCreated: false,
      teams: false,
    },
  )

  const columnFilters = useMemo(() => {
    const filters: ColumnFiltersState = []

    if (search) {
      filters.push({
        id: 'title',
        value: search,
      })
    }

    if (ownerIds?.length) {
      filters.push({
        id: 'createdByDetails',
        value: ownerIds,
      })
    }

    return filters
  }, [search, ownerIds])

  const table = useReactTable({
    data: filteredTemplates,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: (newSorting) => {
      trackEvent({
        event: 'templates-sort',
        eventProperties: {
          indexPageName: 'Template List',
        },
      })
      setSorting(newSorting)
    },
    onPaginationChange: (state) => {
      if (typeof state === 'function') {
        const newState = state(pagination)
        setPagination(newState)
        trackEvent({
          event: 'templates-change_page',
          eventProperties: {
            indexPageName: 'Templates List',
            templateListNewPageNumber: newState.pageIndex,
          },
        })
      }
    },
    onColumnVisibilityChange: (state) => {
      if (typeof state === 'function') {
        storage.setJSONValue(STORAGE_COLUMN_VISIBILITY_KEY, state(columnVisibility))
        setColumnVisibility(state)
      }
    },
    state: {
      sorting,
      pagination,
      columnFilters,
      columnVisibility,
    },
  })

  const selectedOwners = useMemo(() => (ownerIds ? new Set(ownerIds) : undefined), [ownerIds])
  const ownerFilter = useMemo(() => {
    return (
      <SpaceOwnerFilterDropdown
        labelForSelection='Template creators'
        labelForNoSelection='All Template Creators'
        searchPlaceholder={'Search template creators'}
        selectedIds={selectedOwners}
        setSelectedIds={(ids) =>
          navigate({
            to: '/templates',
            search: (prev) => ({ ...prev, ownerIds: ids ? [...ids] : undefined }),
          })
        }
      />
    )
  }, [selectedOwners, navigate])

  const statusFiltered = useMemo(() => status !== 'active', [status])

  const templateStatusFilter = useMemo(() => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <DropdownMenuButton
            className={cn({
              'border-primary-s5 text-primary-s5 font-semibold': statusFiltered,
            })}
            trailingIconClassName={cn({ 'text-primary-s5': statusFiltered })}
          >
            {status === 'active' && 'Active Templates'}
            {status === 'archived' && 'Archived Templates'}
            {status === 'all' && 'All Templates'}
            {statusFiltered && (
              <Badge variant={'solid'} className='ml-2'>
                1
              </Badge>
            )}
          </DropdownMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='start'>
          <DropdownMenuRadioGroup
            value={status}
            onValueChange={(value: string) => handleTemplateStatusFilterChange(value as any)}
          >
            <DropdownMenuRadioItem value='active'>Active Templates</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value={'archived'}>Archived Templates</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value={'all'}>All Templates</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }, [statusFiltered, status, handleTemplateStatusFilterChange])

  return (
    <>
      {templates.length === 0 && (
        <div className='w-full h-full flex flex-col gap-10 items-center mt-16'>
          <div className='flex flex-col gap-4 justify-center items-center'>
            <Button2 trailingIcon='add' onClick={() => showCreateTemplateOverlay()}>
              Create new template
            </Button2>
          </div>
          <div className='max-w-6xl mt-8'>
            <img className='w-full' src={TemplatesEmptyState} />
          </div>
        </div>
      )}
      {templates?.length > 0 && (
        <>
          <div className='flex justify-between'>
            <div className='flex items-center gap-2'>
              <Input2
                leadingIcon='search'
                className='w-[200px]'
                placeholder='Search'
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  setSearchDebounced(e.target.value)
                }}
              />
              {selectedOwners && ownerFilter}
              {statusFiltered && templateStatusFilter}
            </div>
            <div className='flex items-center gap-2'>
              {showFilterResetButton && (
                <Button2
                  variant={'plain-subtle'}
                  leadingIcon='refresh'
                  onClick={handleResetFilters}
                >
                  Reset
                </Button2>
              )}
              {hasSpacesAllAdministrationPermission && (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button2 variant={'outlined-white'} leadingIcon='options' className='ml-auto'>
                      Filters
                    </Button2>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className='w-[276px] flex flex-col gap-6 p-4' align='end'>
                    {ownerFilter}
                    {templateStatusFilter}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button2 variant={'outlined-white'} leadingIcon='c_table-settings'>
                    Table Columns
                  </Button2>
                </DropdownMenuTrigger>
                <DropdownMenuContent align='end'>
                  {table
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .map((column) => {
                      return (
                        <DropdownMenuCheckboxItem
                          key={column.id}
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) => column.toggleVisibility(!!value)}
                        >
                          {typeof column.columnDef.header === 'string'
                            ? column.columnDef.header
                            : column.id}
                        </DropdownMenuCheckboxItem>
                      )
                    })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <DataTable
            isLoading={false}
            table={table}
            rowClickHandler={(row) => {
              trackEvent({
                event: 'templates-open-current_tab',
                eventProperties: {
                  indexPageName: 'Templates List',
                  templateId: row.original?.rootNodeId || 'undefined',
                },
              })
              if (row.original.rootNodeId) {
                window.location.href = getTemplateUrl(row.original.rootNodeId)
              }
            }}
            paginationClickHandler={() =>
              trackEvent({
                event: 'templates-change_page',
                eventProperties: {
                  indexPageName: 'Template List',
                },
              })
            }
            tableWrapperClassName={'rounded-lg border border-grey-s2'}
            rowClassNames={(row) => {
              return row.original.archived ? 'opacity-60' : undefined
            }}
            resetFiltersClickHandler={handleResetFilters}
          />
        </>
      )}
    </>
  )
}

export default TemplatesList
