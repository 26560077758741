import styled from 'styled-components'

const templateInfoInputToTwoColLayoutBreakpoint = 570 // pixels

export const TemplateEditorLayout = styled.div`
  display: flex;
  overflow: auto;
  height: 100%;
  flex-direction: column;
`

export const TemplateEditorErrorWrapper = styled.div`
  color: #f45359;
  font-size: 12px;
  padding: 16px;
  font-weight: 400;
  text-align: right;
  overflow: hidden;
  max-height: 97px;
`

export const TemplateEditorForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

export const TemplateEditorHeaderWrapper = styled.div`
  position: relative;
  padding: 0 8px 40px 8px;
`

export const TemplateEditorHeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 33px;
  color: #31333a;
  text-align: center;
`

export const TemplateEditorHeaderSubtitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s5};
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
`

export const TemplateEditorStep1Layout = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
`

export const TemplateEditorCurrentStepWrapper = styled.div`
  min-height: 456px;
  width: 100%;
  height: 100%;
  display: flex;
`

export const TemplateEditorExistingTemplateDetails = styled.div`
  overflow: auto;
`

export const TemplateEditorStep2Layout = styled.div`
  width: 100%;
  display: grid;
  background-color: ${({ theme }) => theme.grey.s1};
  padding: 24px 32px 24px 32px;
  row-gap: 16px;
  grid-template-rows: repeat(5, max-content) 1fr;
  overflow: auto;
  height: 100%;
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    grid-template-areas:
      'iconLabel iconInput'
      'nameLabel nameInput'
      'usageLabel usageInput';
    grid-template-columns: 224px 1fr;
    grid-template-rows: max-content max-content 1fr;
    column-gap: 24px;
    align-items: center;
  }
`

export const TemplateEditorLibraryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgba(243, 245, 248, 0.6);
  border-right: 1px solid ${({ theme }) => theme.grey.s2};
  overflow: auto;
`

export const TemplateEditorTemplatePreviewWrapper = styled.div`
  overflow: auto;
`

export const TemplateEditorFactsWrapper = styled.div``

export const TemplateEditorFooter = styled.div`
  padding: 0 32px;
  min-height: 97px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const TemplateEditorFooterRight = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
`

export const TemplateEditorUnorderedTemplateList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  row-gap: '.5em';
`

export const TemplateEditorTemplateListItem = styled.li`
  margin: 0 10px 10px 12px;
  max-width: calc(100% - 22px);
  overflow: hidden;

  &:first-child {
    margin-top: 17px;
  }

  &:last-child {
    margin-bottom: 17px;
  }
`

export const TemplateEditorTemplateListItemIcon = styled.span`
  font-size: 21px;
  margin-right: 11px;
`

export const TemplateEditorTemplateListItemText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TemplateEditorLibraryRadio = styled.input`
  display: none;
`

export const TemplateEditorLibraryLabel = styled.label`
  font-weight: 600;
  color: ${({ theme }) => theme.grey.s6};
  display: block;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  min-height: 50px;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.grey.s2};
  }
`

export const TemplateEditorLibraryItemCheckmark = styled.span`
  margin-left: auto;
  font-size: 24px;
  width: 24px;
  color: ${({ theme }) => theme.primary.s5};
  display: flex;
  align-items: center;
  margin-right: 8px;
`

export const TemplateEditorTemplateFactsHeader = styled.h3`
  font-weight: bold;
  margin: 28px 32px 0 32px;
`

export const TemplateEditorPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TemplateEditorBackButtonWrapper = styled.div`
  align-self: center;
  justify-self: flex-start;
`

export const TemplateEditorNextButtonWrapper = styled.div`
  align-self: center;
  justify-self: flex-end;
`

export const TemplateEditorPreviewImageLabelWrapper = styled.div`
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    padding-top: 11px;
    align-self: flex-start;
    grid-area: iconLabel;
  }
`

export const TemplateEditorPreviewIconInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    grid-area: iconInput;
  }
`
export const TemplateEditorNameLabelWrapper = styled.div`
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    grid-area: nameLabel;
  }
`
export const TemplateEditorNameInputWrapper = styled.div`
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    grid-area: nameInput;
  }
`
export const TemplateEditorUsageLabelWrapper = styled.div`
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    align-self: flex-start;
    padding-top: 17px;
    grid-area: usageLabel;
  }
`
export const TemplateEditorUsageInputWrapper = styled.div`
  display: flex;
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    align-self: flex-start;
    height: 100%;
    grid-area: usageInput;
  }
`

export const TemplateEditorUsageTextarea = styled.textarea`
  color: ${({ theme }) => theme.grey.s6};
  height: 100%;
  border-radius: 8px;
  margin: 0 0;
  outline: none;
  border: none;
  background: transparent;
  resize: none;
  font-size: 14px;
  flex-grow: 1;
  padding: 16px 24px;
  display: flex;
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.grey.s2};
  transition:
    0.2s ease box-shadow,
    0.2s ease border;
  border-radius: 8px;
  background: ${({ theme }) => theme.light};

  &::placeholder {
    color: ${({ theme }) => theme.grey.s4};
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.primary.s2};
    outline: none;
  }

  &:hover {
    border-color: ${({ theme }) => theme.grey.s2};
  }

  &:focus {
    border-color: ${({ theme }) => theme.primary.s4};
  }
`

export const TemplateEditorPreviewImageWrapper = styled.div`
  min-height: 184px;
`

export const TemplateEditorPreviewImage = styled.img`
  display: block;
  border-radius: 8px;
  margin: 16px 16px 0 16px;
  box-sizing: border-box;
  width: calc(100% - 32px);
  height: auto;
`

export const TemplateEditorTemplateFactsHeaderIcon = styled.span`
  font-size: 10px;
`

export const TemplateEditorTemplateFactsHeaderText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s6};
`

export const TemplateEditorTemplateFactsDescription = styled.div`
  margin: 4px 32px 0 32px;
  color: ${({ theme }) => theme.grey.s5};
  font-weight: 400;
  font-size: 14px;
`

export const TemplateEditorPreviewIconButton = styled.button`
  width: 68px;
  height: 68px;
  font-size: 44px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.grey.s2};
`

export const TemplateEditorPreviewIconHint = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s3};
  margin-left: 14px;
`

export const TemplateEditorInfoInputLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s6};
`
