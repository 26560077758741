import { useMemo } from 'react'
import { TaskColumnView } from './TaskColumnView'
import { useOverdueTasks } from '@valuecase/ui-components'
import { EmptyStateColumnWrapper } from './EmptyStateColumnWrapper'
import { useNavigate } from '@tanstack/react-router'
import { OverdueTasksEmptyState } from './empty-states'
import { useTasksFilter } from './useTasksFilter'
import { TActionPlanTaskExpanded } from '@valuecase/common'

export const OverdueTasksColumn = () => {
  const navigate = useNavigate()
  const { spaceCreatedAfter, ownerIds } = useTasksFilter()
  const tasksQuery = useOverdueTasks({ spaceCreatedAfter, ownerIds })

  const emptyState = useMemo(() => {
    return (
      <EmptyStateColumnWrapper>
        <OverdueTasksEmptyState />
      </EmptyStateColumnWrapper>
    )
  }, [])

  return (
    <TaskColumnView
      id='dashboard-tasks-overdue-column'
      iconName='calendar'
      iconClassName='text-red-s4'
      title='Overdue'
      taskStatus='overdue'
      tasksCount={tasksQuery.data?.totalItems || 0}
      tasks={(tasksQuery.data?.items as TActionPlanTaskExpanded[]) || []}
      emptyState={emptyState}
      isLoading={tasksQuery.isLoading}
      onViewAllClick={() =>
        navigate({
          to: '/tasks',
          search: (prev) => ({ ...prev, view: 'list', taskStatus: 'overdue' }),
        })
      }
    />
  )
}
