import styled from 'styled-components'
import {
  ConcurrentRequestResult,
  ConcurrentRequestStatus,
  Hairline,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@valuecase/ui-components'
import { useGetTenantCustomProperties } from '../../../spaceProperties/useGetTenantCustomProperties'
import { cn } from '../../../../utils/Cn'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGetTemplateUsedCustomProperties } from '@/modules/spaceProperties/useGetTemplateUsedCustomProperties'
import { AxiosResponse } from 'axios'
import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useConcurrentRequests } from '@valuecase/ui-components'
import { TCustomProperty } from '@valuecase/common'

const ContainerWrapper = styled.div`
  width: calc(100% + 68px);
  margin-left: -36px;
`
const Container = styled.div`
  width: 100%;
  height: 456px;
  overflow-y: auto;
`

type GptCompletionResponseData = { message: string }

type EntryProps = {
  data: TCustomProperty
  propKey?: string
  value: string
  onChange: (key: string, value: string) => void
  gptCompletionRequest?: ConcurrentRequestResult<GptCompletionResponseData>
}

export type SpaceProperty = {
  name: string
  value: string
}

function Entry(props: EntryProps) {
  const { data, onChange, propKey, value, gptCompletionRequest } = props

  const prevCompletionStatus = useRef<ConcurrentRequestStatus | undefined>(
    gptCompletionRequest?.status,
  )

  const handleValueChange = useCallback(
    (value: string) => {
      if (data.key) {
        onChange(data.key, value)
      }
    },
    [data.key, onChange],
  )

  useEffect(() => {
    const isNowFulfilled =
      prevCompletionStatus.current !== 'fulfilled' && gptCompletionRequest?.status === 'fulfilled'
    prevCompletionStatus.current = gptCompletionRequest?.status
    if (isNowFulfilled && gptCompletionRequest?.data?.message) {
      handleValueChange(gptCompletionRequest.data.message)
    }
  }, [gptCompletionRequest?.data?.message, gptCompletionRequest?.status, handleValueChange])

  return (
    <div className='flex justify-between items-center'>
      <div className='flex gap-2 items-center'>
        <div className='flex items-end'>
          <h2 className='text-grey-s6 font-semibold text-sm mr-2'>{data.displayName}</h2>
          {data.isOptional && <span className='text-grey-s5 text-xs'>(Optional)</span>}
        </div>
      </div>
      <div className='flex w-140 flex-col'>
        {data.options.length === 0 && (
          <Input
            value={value}
            fullWidth
            onInput={handleValueChange}
            placeholder={`Enter ${data.displayName}`}
          />
        )}
        {data.options.length > 0 && (
          <Select value={value} onValueChange={handleValueChange}>
            <SelectTrigger>
              <SelectValue placeholder={`Select ${data.displayName}`} />
            </SelectTrigger>
            <SelectContent className={'z-[9999]'}>
              {data.options.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}

        {gptCompletionRequest?.status === 'pending' && (
          <div className='flex items-center mt-2 text-xs'>
            <div className='animate-spin rounded-full h-2 w-2 border-b-2 border-grey-s6' />
            <span className='ml-2 text-grey-s6'>Generating...</span>
          </div>
        )}
        {gptCompletionRequest?.status === 'fulfilled' &&
          value &&
          value === gptCompletionRequest.data?.message && (
            <div className='flex items-center mt-2 text-xs'>
              <span className='text-grey-s6'>✨ AI generated response</span>
            </div>
          )}
        {gptCompletionRequest?.status === 'rejected' && (
          <div className='flex items-center mt-2 text-xs'>
            <span className='text-red-500'>Error generating prompt</span>
          </div>
        )}
      </div>
    </div>
  )
}

type SpaceCreationCustomPropertiesStepProps = {
  show: boolean
  onUpdateCustomProperties: (spaceProperties: SpaceProperty[]) => void
  selectedTemplateId?: string | null
  flags: Record<string, boolean | undefined>
  contactFirstName: string
  contactLastName: string
  companyName: string
}

async function getCompletion(
  prompt: string,
  signal: AbortSignal,
): Promise<AxiosResponse<GptCompletionResponseData, any>> {
  return await spacesApi.get(`gpt?prompt=${encodeURIComponent(prompt.trim())}`, { signal })
}

export function SpaceCreationCustomPropertiesStep(props: SpaceCreationCustomPropertiesStepProps) {
  const [requiredCustomProperties, setRequiredCustomProperties] = useState<TCustomProperty[]>([])
  const { customProperties } = useGetTenantCustomProperties()
  const { templateUsedCustomProperties } = useGetTemplateUsedCustomProperties(
    props.selectedTemplateId,
  )
  const [values, setValues] = useState<Record<string, string>>({})
  const propertiesWithGptPrompts = useMemo(() => {
    return customProperties?.filter((item) => item.gptPrompt) || []
  }, [customProperties])
  const concurrentRequestConfig = useMemo(() => {
    return {
      enabled: !!props.flags?.['gpt-for-space-properties'] && props.show,
      items:
        propertiesWithGptPrompts.map((item) => ({
          requestKey: item.key || '',
          requestValue:
            item.gptPrompt
              .trim()
              .replaceAll('{{contactFirstName}}', props.contactFirstName)
              .replaceAll('{{contactLastName}}', props.contactLastName)
              .replaceAll('{{companyName}}', props.companyName) || '',
        })) || [],
      maxConcurrent: 1,
      performRequest: getCompletion,
    }
  }, [
    propertiesWithGptPrompts,
    props.companyName,
    props.contactFirstName,
    props.contactLastName,
    props.flags,
    props.show,
  ])

  const [gptRequests, cancelRequests] = useConcurrentRequests(concurrentRequestConfig)

  useEffect(() => {
    return () => {
      // When the component unmounts, cancel all pending GPT requests
      cancelRequests()
    }
  }, [cancelRequests])

  useEffect(() => {
    if (customProperties) {
      if (templateUsedCustomProperties) {
        setRequiredCustomProperties(
          customProperties.filter((item: TCustomProperty) =>
            templateUsedCustomProperties.includes(item.key),
          ),
        )
      } else {
        setRequiredCustomProperties(customProperties)
      }
    }
  }, [customProperties, templateUsedCustomProperties])

  const handleChange = useCallback(
    (displayName: string, value: string) => {
      if (values[displayName] === value) {
        return
      }
      const newValues = { ...values, [displayName]: value }
      const filteredValues = Object.keys(newValues)
        .map((key) => {
          return {
            name: key,
            value: newValues[key],
          }
        })
        .filter((item) => item.value !== '')
      props.onUpdateCustomProperties(filteredValues)
      setValues(newValues)
    },
    [props, values],
  )

  if (!props.show) {
    return null
  }

  return (
    <div className='w-full'>
      <ContainerWrapper className='bg-grey-s1'>
        <Hairline />
        <Container className='px-8 py-6'>
          <div className={cn('w-full flex flex-1 flex-col gap-4')}>
            {(requiredCustomProperties || []).map((customProperty) => (
              <Entry
                key={customProperty.id}
                data={customProperty}
                propKey={customProperty.key}
                value={(customProperty.key && values[customProperty.key]) || ''}
                onChange={handleChange}
                gptCompletionRequest={gptRequests.get(customProperty.key || '')}
              />
            ))}
          </div>
        </Container>
        <Hairline />
      </ContainerWrapper>
    </div>
  )
}
