import { ComponentPropsWithoutRef, useCallback, useEffect, useState, useRef, useMemo } from 'react'
import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteItemBase,
  Input2,
} from '@valuecase/ui-components'
import { z } from 'zod'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'

export const CompanyAutocomplete = ({
  value,
  onValueChange,
  onTakeSuggestion,
  ...props
}: ComponentPropsWithoutRef<typeof Input2> & {
  value: string
  onValueChange: (value: string) => void
  onTakeSuggestion: (value: CompanyAutocompleteItem) => void
}) => {
  const [suggestions, setSuggestions] = useState<CompanyAutocompleteItem[]>([])
  const [open, setOpen] = useState(true)
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const [selectedSuggestionValue, setSelectedSuggestionValue] = useState<string | null>(null)
  const { flags } = useLDflags()
  const { trackEvent } = useTrackEvent()

  const getQueries = useCallback(
    async (searchValue: string) => {
      if (
        !searchValue ||
        searchValue.length < 2 ||
        !flags?.['company-suggestions-in-space-create'] ||
        !import.meta.env.VITE_BRANDFETCH_CLIENT_ID
      ) {
        setSuggestions([])
        return
      }
      try {
        const url = `https://api.brandfetch.io/v2/search/${encodeURIComponent(searchValue)}?c=${
          import.meta.env.VITE_BRANDFETCH_CLIENT_ID
        }`
        const res = await fetch(url)
        if (res.ok) {
          const data = await res.json()
          const results = brandfetchSearchResult.parse(data)
          const autocompleteItems = results.map((result) => ({
            id: result.brandId,
            value: result.name,
            domain: result.domain || '',
            icon: result.icon || '',
          }))
          setSuggestions(autocompleteItems)
        }
      } catch (err) {
        console.error('Something went wrong searching for autocomplete companies', err)
      }
    },
    [flags],
  )

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getQueries(value)
    }, 300)
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [getQueries, value])

  const _onTakeSuggestion = useCallback(
    (item: CompanyAutocompleteItem) => {
      setSelectedSuggestionValue(item.value)
      onTakeSuggestion?.(item)
      onValueChange(item.value)
      trackEvent({
        event: 'company-autocomplete-suggestion-selected',
        eventProperties: {
          suggestionSource: item.id === 'custom-value' ? 'user' : 'brandfetch',
        },
      })
      setOpen(false)
      autocompleteRef.current?.focus()
    },
    [onTakeSuggestion, onValueChange, trackEvent],
  )

  const _onValueChange = useCallback(
    (value: string) => {
      onValueChange(value)
      setOpen(true)
    },
    [onValueChange],
  )

  const _open = useMemo(() => {
    if (!open) {
      return false
    }
    if (suggestions.length === 0) {
      return false
    }
    if (selectedSuggestionValue === value) {
      return false
    }
    return true
  }, [open, suggestions.length, selectedSuggestionValue, value])

  return (
    <Autocomplete
      ref={autocompleteRef}
      {...props}
      value={value}
      onValueChange={_onValueChange}
      suggestions={suggestions}
      placeholder='Enter company name'
      open={_open}
      setOpen={setOpen}
    >
      <AutocompleteItem
        id={'custom-value'}
        onSelect={() => _onTakeSuggestion({ id: 'custom-value', value })}
      >
        <span>&ldquo;{value}&rdquo;</span>
      </AutocompleteItem>
      {suggestions.map((option) => (
        <AutocompleteItem
          key={option.id}
          id={option.id}
          icon={option.icon}
          onSelect={() => _onTakeSuggestion(option)}
        >
          <span>
            <span>{option.value}</span> <small className='text-grey-s5'>{option.domain}</small>
          </span>
        </AutocompleteItem>
      ))}
    </Autocomplete>
  )
}

const brandfetchSearchResultItem = z.object({
  brandId: z.string(),
  name: z.string(),
  domain: z.string().nullish(),
  icon: z.string().nullish(),
})

const brandfetchSearchResult = z.array(brandfetchSearchResultItem)

export type CompanyAutocompleteItem = AutocompleteItemBase & { domain?: string | null }
