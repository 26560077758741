import { IonIcon, useDateFormatting } from '@valuecase/ui-components'
import { FC, useMemo } from 'react'
import { TemplateCreatorTemplateFactsProps } from '../types/TemplateCreatorTemplateFactsProps'
import blankTemplateSrc from './blank.jpg'
import {
  TemplateCreatorFactsWrapper,
  TemplateCreatorPreviewImage,
  TemplateCreatorPreviewImageWrapper,
  TemplateCreatorTemplateFactsDescription,
  TemplateCreatorTemplateFactsHeader,
  TemplateCreatorTemplateFactsHeaderIcon,
  TemplateCreatorTemplateFactsHeaderText,
} from './TemplateCreatorParts.styled'
export const TemplateCreatorFacts: FC<TemplateCreatorTemplateFactsProps> = ({
  template,
  isAnEmptyTemplate: startFromScratch,
  startTemplateWithAiSelected,
}) => {
  const { formatDateShort } = useDateFormatting()

  const showTenantTemplateDetails = useMemo(() => {
    return template?.createdAt !== undefined && template?.spacesCreated !== undefined
  }, [template])

  const previewImageUrl = Array.isArray(template?.previewImages) && template?.previewImages[0]?.url
  return (
    <TemplateCreatorFactsWrapper>
      {startTemplateWithAiSelected && (
        <div className='flex flex-col p-6'>
          <TemplateCreatorPreviewImageWrapper>
            <div className='block rounded-lg mb-0 box-border w-[calc(100%-32px)] h-auto'>
              <IonIcon name={'c_ai-template-preview'} className={'w-[511px] h-[184px]'} />
            </div>
          </TemplateCreatorPreviewImageWrapper>

          <div className='flex flex-col gap-2 mt-5'>
            <span className='font-bold text-grey-s6'>
              ✨ Create a Template with our AI Assistant
            </span>
            <span className='text-grey-s5'>
              Describe your use case to design a customised template in minutes.
            </span>
          </div>
        </div>
      )}
      {!!template && !startTemplateWithAiSelected && (
        <>
          {startFromScratch && (
            <TemplateCreatorPreviewImageWrapper>
              <TemplateCreatorPreviewImage src={blankTemplateSrc} />
            </TemplateCreatorPreviewImageWrapper>
          )}
          {previewImageUrl && (
            <TemplateCreatorPreviewImageWrapper>
              <TemplateCreatorPreviewImage src={previewImageUrl} />
            </TemplateCreatorPreviewImageWrapper>
          )}
          <TemplateCreatorTemplateFactsHeader>
            <TemplateCreatorTemplateFactsHeaderIcon>
              {template.emojiIcon || '🗒️'}
            </TemplateCreatorTemplateFactsHeaderIcon>{' '}
            <TemplateCreatorTemplateFactsHeaderText>
              {template?.title}
            </TemplateCreatorTemplateFactsHeaderText>
          </TemplateCreatorTemplateFactsHeader>
          <TemplateCreatorTemplateFactsDescription>
            {template.description}
          </TemplateCreatorTemplateFactsDescription>

          {showTenantTemplateDetails && (
            <div className={'flex flex-wrap gap-2 px-8 py-4'}>
              <div
                className={
                  // that's a legacy style which is just aligned to the create space dialog, replace once
                  'py-1.5 px-[7px] rounded-lg bg-primary-s2 text-xxs font-[750] text-[rgb(119,_147,_255)]'
                }
              >
                created {formatDateShort(template.createdAt)}
              </div>

              <div
                className={
                  'py-1.5 px-[7px] rounded-lg bg-primary-s2 text-xxs font-[750] text-[rgb(119,_147,_255)]'
                }
              >
                <p>{template.language || 'English'}</p>
              </div>

              <div
                className={
                  'py-1.5 px-[7px] rounded-lg bg-primary-s2 text-xxs font-[750] text-[rgb(119,_147,_255)]'
                }
              >
                {template.spacesCreated} {template.spacesCreated === 1 ? 'Space' : 'Spaces'}
              </div>
            </div>
          )}
        </>
      )}
    </TemplateCreatorFactsWrapper>
  )
}
