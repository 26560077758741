import { Dialog, DialogContent, DialogFooter } from '@valuecase/ui-components'
import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { useCurrentTenantQuery } from '../tenant/hooks/useReadTenant'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

interface PricingDialogProps {
  open: boolean
  onClose: () => void
  onCloseWithoutPurchase?: () => void
}

const PricingDialog = ({ open, onClose, onCloseWithoutPurchase }: PricingDialogProps) => {
  const { tenant } = useCurrentTenantQuery()
  const tenantId = useMemo(() => tenant?.payload?.id, [tenant])

  const handleClose = useCallback(() => {
    onClose()
    onCloseWithoutPurchase?.()
  }, [onClose, onCloseWithoutPurchase])

  useEffect(() => {
    if (!open) return
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      if (script.parentNode) {
        document.body.removeChild(script)
      }
    }
  }, [open])

  const stripePricingTable = useMemo(() => {
    const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
    const pricingTableId = import.meta.env.VITE_STRIPE_PRICING_TABLE_ID

    if (!publishableKey || !pricingTableId) {
      return (
        <div className='flex flex-col w-full gap-1 '>
          <span className='text-center text-sm font-bolt text-s6'>
            Error: Stripe keys not set.{' '}
          </span>
          <span className='text-center text-sm text-s6'>Publishable Key: {publishableKey}</span>
          <span className='text-center text-sm text-s6'>Pricing Table ID: {pricingTableId}</span>
        </div>
      )
    }

    return (
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={publishableKey}
        client-reference-id={tenantId}
      />
    )
  }, [tenantId])

  if (!open) {
    return null
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent
        className='
        max-w-[95vw] 
        max-h-[95vh]
        w-[95vw]
        h-[95vh]
        h-xxs:w-[95vw]
        h-xs:w-[95vw]
        h-sm:w-[95vw]
        h-md:w-[75vw]
        h-lg:w-[65vw]
        h-xxs:h-[95vh]
        h-xs:h-[95vh]
        h-sm:h-[95vh]
        h-md:h-[90vh]
        h-lg:h-[65vh]
        rounded-lg
        bg-[#fafafa]
      '
      >
        <div
          className='
          w-full h-full 
          flex items-center justify-center 
        overflow-auto

        '
        >
          <div className='w-full min-w-[600px] h-full'>{stripePricingTable}</div>
        </div>

        <DialogFooter />
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(PricingDialog)
