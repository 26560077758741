import { Button2 } from '@valuecase/ui-components'

const IntercomLearnMoreButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button2
      variant={'outlined'}
      size={'small'}
      leadingIcon='book'
      className='w-24 bg-white hover:bg-white hover:text-grey-s6 text-grey-s5 border-grey-s2'
      onClick={onClick}
    >
      Learn more
    </Button2>
  )
}

export default IntercomLearnMoreButton
