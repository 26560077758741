import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CheckMark,
  Spinner,
  useNotifications,
  IonIcon,
} from '@valuecase/ui-components'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@valuecase/ui-components/src/components/ui/select'
import { useQuery, useMutation } from '@tanstack/react-query'
import SellerApi from '../../api/SellerApi'
import {
  defaultSalesforceEntityTypes,
  TSalesforceIntegrationSettingsEntity,
  TSalesforceIntegrationSettingsUpsertDto,
  TSalesforceObjectTypeList,
} from '@valuecase/common'

interface ConfigureSalesforceIntegrationDialogProps {
  isShown: boolean
  onClose: () => void
}

export const ConfigureSalesforceIntegrationDialog: React.FC<
  ConfigureSalesforceIntegrationDialogProps
> = ({ isShown, onClose }) => {
  const [profilesWithAccess, setProfilesWithAccess] = useState<null | string[]>()
  const [setupUserOAuth2Id, setSetupUserOAuth2Id] = useState<string | null>(null)
  const [syncUserOAuth2Id, setSyncUserOAuth2Id] = useState<string | null>(null)
  const [selectedObjectTypes, setSelectedObjectTypes] = useState<string[]>([])
  const { success: showSuccess, error: showError } = useNotifications()

  // Get available object types
  const { data: objectTypesResponse, isLoading: objectTypesAreLoading } = useQuery(
    ['salesforce-object-types'],
    () =>
      SellerApi.get<{ objectTypes: TSalesforceObjectTypeList }, unknown>(
        '/integrations/salesforce/object-types',
      ),
    { enabled: isShown },
  )

  const availableObjectTypes = useMemo<TSalesforceObjectTypeList>(() => {
    if (!objectTypesResponse?.success) {
      return []
    }
    if (Array.isArray(objectTypesResponse.payload.objectTypes)) {
      return objectTypesResponse.payload.objectTypes
    }
    if (objectTypesAreLoading) {
      return []
    }
    return []
  }, [objectTypesResponse, objectTypesAreLoading])

  // Get available integration users
  const { data: usersResponse, isLoading: usersAreLoading } = useQuery(
    ['salesforce-users'],
    () =>
      SellerApi.get<{ users: { oAuth2TokenId: string; name: string }[] }, unknown>(
        '/integrations/salesforce/users',
      ),
    { enabled: isShown },
  )
  const availableUsers = useMemo(() => {
    if (!usersResponse?.success) {
      return []
    }
    if (Array.isArray(usersResponse.payload.users)) {
      return usersResponse.payload.users
    }
    if (usersAreLoading) {
      return []
    }
    return []
  }, [usersResponse, usersAreLoading])

  // Get available profiles
  const { data: profilesResponse, isLoading: profilesAreLoading } = useQuery(
    ['salesforce-profiles'],
    () =>
      SellerApi.get<{ profiles: { name: string }[] }, unknown>('/integrations/salesforce/profiles'),
    { enabled: isShown },
  )
  const availableProfiles = useMemo(() => {
    if (!profilesResponse?.success) {
      return []
    }
    if (Array.isArray(profilesResponse.payload.profiles)) {
      return profilesResponse.payload.profiles
    }
    if (profilesAreLoading) {
      return []
    }
    return []
  }, [profilesResponse, profilesAreLoading])

  // Get current settings
  const { data: settingsResponse, isLoading: settingsAreLoading } = useQuery(
    ['salesforce-settings'],
    () =>
      SellerApi.get<TSalesforceIntegrationSettingsEntity, unknown>(
        '/integrations/salesforce/settings',
      ),
    { enabled: isShown },
  )
  useEffect(() => {
    if (!settingsResponse?.success) {
      return
    }
    if (!settingsResponse.payload) {
      setSelectedObjectTypes([...defaultSalesforceEntityTypes])
      setProfilesWithAccess([])
    }
    if (settingsResponse.payload) {
      setProfilesWithAccess(settingsResponse.payload.meta.profilesWithAccess)
      if (Array.isArray(settingsResponse.payload.entitySettings)) {
        setSelectedObjectTypes(
          settingsResponse.payload.entitySettings.map((entitySetting) => entitySetting.entityName),
        )
      } else {
        setSelectedObjectTypes([])
      }
      setSetupUserOAuth2Id(settingsResponse.payload.setupUserTokenId || null)
      setSyncUserOAuth2Id(settingsResponse.payload.syncUserTokenId || null)
    }
  }, [settingsResponse])

  const mutation = useMutation({
    mutationFn: (newSettings: TSalesforceIntegrationSettingsUpsertDto) =>
      SellerApi.put('/integrations/salesforce/settings', newSettings),
  })

  const handleSave = useCallback(async () => {
    try {
      await mutation.mutateAsync({
        thirdParty: 'Salesforce',
        meta: { profilesWithAccess },
        setupUserOAuth2Id: setupUserOAuth2Id || null,
        syncUserOAuth2Id: syncUserOAuth2Id || null,
        entitySettings: selectedObjectTypes.map((objectType) => ({
          entityName: objectType,
          label:
            availableObjectTypes.find((item) =>
              typeof item === 'string' ? item === objectType : item.name === objectType,
            )?.label || null,
          labelPlural:
            availableObjectTypes.find((item) =>
              typeof item === 'string' ? item === objectType : item.name === objectType,
            )?.labelPlural || null,
          customPropertiesEnabled: true,
          customActivitiesEnabled: true,
        })),
      })
      showSuccess('Salesforce integration settings saved')
    } catch (error) {
      console.error(error)
      showError('Failed to save Salesforce integration settings')
    } finally {
      onClose()
    }
  }, [
    mutation,
    profilesWithAccess,
    setupUserOAuth2Id,
    syncUserOAuth2Id,
    selectedObjectTypes,
    showSuccess,
    availableObjectTypes,
    showError,
    onClose,
  ])

  // Handle object type selection
  const handleObjectTypeChange = useCallback((objectType: string) => {
    setSelectedObjectTypes((prev) => {
      if (prev.includes(objectType)) {
        return prev.filter((item) => item !== objectType)
      }
      return [...prev, objectType]
    })
  }, [])

  // Handle profile selection
  const handleProfileChange = useCallback((profile: string) => {
    setProfilesWithAccess((prev) => {
      if (!prev) {
        return [profile]
      }
      if (prev.includes(profile)) {
        return prev.filter((item) => item !== profile)
      }
      return [...prev, profile]
    })
  }, [])

  const profilesWithAccessSet = useMemo(
    () => new Set(profilesWithAccess || []),
    [profilesWithAccess],
  )

  // Check if any data is still loading
  const isLoading = useMemo(
    () => objectTypesAreLoading || usersAreLoading || profilesAreLoading || settingsAreLoading,
    [objectTypesAreLoading, usersAreLoading, profilesAreLoading, settingsAreLoading],
  )

  return (
    <Dialog open={isShown} onOpenChange={onClose}>
      <DialogContent className='flex flex-col p-0 max-h-screen overflow-auto'>
        <DialogTitle className='p-8 pb-0'>Configure Salesforce Integration</DialogTitle>
        {isLoading ? (
          <div className='flex-grow flex items-center justify-center min-h-[400px]'>
            <Spinner />
          </div>
        ) : (
          <div className='flex flex-col w-full h-full'>
            <div className='flex-1 px-8 pb-4'>
              <div className='rounded-md bg-blue-50 p-4 mb-4'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <IonIcon name='information-circle-outline' className='h-5 w-5 text-blue-400' />
                  </div>
                  <div className='ml-3'>
                    <p className='text-sm text-blue-700'>
                      These Salesforce integration settings apply for the whole organization.{' '}
                      <a
                        href='https://valuecase.notion.site/Salesforce-Integration-Overview-1afdd109e7fe8018975cd7e8349d8a2f'
                        target='_blank'
                        rel='noreferrer noopener'
                        className='font-semibold'
                      >
                        Learn more
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-4 w-full'>
                <div className='flex flex-col gap-2'>
                  <p className='text-sm font-semibold'>Object types to sync to</p>
                  <p className='text-sm text-gray-500'>
                    For each of the selected objects, custom properties and activities will be set
                    up and Valuecase data synced to Salesforce.
                  </p>
                  <div className='flex flex-col gap-2'>
                    {availableObjectTypes.map((objectType) => (
                      <div key={objectType.name} className='flex items-center gap-2'>
                        <div
                          className='flex items-center'
                          onClick={() => handleObjectTypeChange(objectType.name)}
                        >
                          <CheckMark
                            disabled={mutation.isLoading}
                            checked={selectedObjectTypes.includes(objectType.name)}
                          />
                        </div>
                        <label className='text-sm'>{objectType.label}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className='flex flex-col gap-2'>
                  <p className='text-sm font-semibold'>Integration set up user</p>
                  <p className='text-sm text-gray-500'>
                    The selected user must be connected to Salesforce and have the necessary
                    permissions to set up the integration: create custom fields, objects, and
                    optionally set their field-level permissions (if profiles are selected below).
                  </p>
                  <Select
                    disabled={mutation.isLoading}
                    value={setupUserOAuth2Id || ''}
                    onValueChange={setSetupUserOAuth2Id}
                  >
                    <SelectTrigger className='w-full'>
                      <SelectValue placeholder='Select a set up user' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {availableUsers.map((user) => (
                          <SelectItem key={user.oAuth2TokenId} value={user.oAuth2TokenId}>
                            {user.name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <div className='flex flex-col gap-2'>
                  <p className='text-sm font-semibold'>(Optional) Integration sync user</p>
                  <p className='text-sm text-gray-500'>
                    By default, the integration will use the user who created a link to a Salesforce
                    object to sync Valuecase data and activities to the linked object. If a sync
                    user is specified here, the integration will use this user to sync data and
                    activities to all linked objects. This user can be the same as the set up user
                    or a different one.
                  </p>
                  <Select
                    disabled={mutation.isLoading}
                    value={syncUserOAuth2Id || ''}
                    onValueChange={setSyncUserOAuth2Id}
                  >
                    <SelectTrigger className='w-full'>
                      <SelectValue placeholder='Select a sync user' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {availableUsers.map((user) => (
                          <SelectItem key={user.oAuth2TokenId} value={user.oAuth2TokenId}>
                            {user.name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <div className='flex flex-col gap-2'>
                  <p className='text-sm font-semibold'>
                    (Optional) Profiles to enable access to Valuecase fields and activities
                  </p>
                  <p className='text-sm text-gray-500'>
                    The integration set up will add field-level access to the selected profiles for
                    the custom fields and objects created. If no profiles are selected, the
                    integration will not add field-level access to any profiles.
                  </p>
                  <div className='flex flex-col gap-2'>
                    {availableProfiles.map((profile) => (
                      <div key={profile.name} className='flex items-center gap-2'>
                        <div
                          className='flex items-center'
                          onClick={() => handleProfileChange(profile.name)}
                        >
                          <CheckMark
                            disabled={mutation.isLoading}
                            checked={profilesWithAccessSet.has(profile.name)}
                          />
                        </div>
                        <label className='text-sm'>{profile.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='sticky bottom-0 bg-white p-4 border-t border-gray-200 flex flex-row gap-3 justify-end mt-auto'>
              <Button2
                disabled={mutation.isLoading}
                variant='outlined'
                leadingIcon='close-circle-outline'
                onClick={onClose}
              >
                Cancel
              </Button2>
              <Button2 disabled={mutation.isLoading} onClick={handleSave}>
                Save
              </Button2>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
