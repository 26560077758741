import {
  CommandGroup,
  FilterCheckboxItem,
  FilterDropdown,
  FilterDropdownProps,
} from '@valuecase/ui-components'
import { useReadAvailableSpaceOwners } from '../spaces/hooks/useReadAvailableSpaceOwners'
import { useMemo } from 'react'
import { getSubWithoutPrefix, useAuthState } from '@/auth/auth'
import { getCleanSub } from '@valuecase/common'

export const SpaceOwnerFilterDropdown = ({
  selectedIds,
  setSelectedIds,
  searchPlaceholder,
  labelForNoSelection,
  labelForSelection,
  disabled,
}: Pick<FilterDropdownProps<string>, 'selectedIds' | 'setSelectedIds' | 'disabled'> & {
  searchPlaceholder?: string
  labelForNoSelection?: string
  labelForSelection?: string
}) => {
  const { availableSpaceOwners } = useReadAvailableSpaceOwners()

  const sortedOwners = useMemo(() => {
    if (!availableSpaceOwners || availableSpaceOwners.length === 0) {
      return []
    }
    return [...availableSpaceOwners]
      .map((owner) => ({
        id: getCleanSub(owner.id),
        title: `${owner.firstName} ${owner.lastName}`,
        email: owner.email,
        firstName: owner.firstName,
        lastName: owner.lastName,
      }))
      .sort((a, b) => a.title.localeCompare(b.title))
  }, [availableSpaceOwners])

  const auth = useAuthState()

  const authorizedOwner = useMemo(() => {
    const ownerId = getSubWithoutPrefix(auth)
    if (!ownerId) {
      return null
    }
    return sortedOwners.find((owner) => owner.id === ownerId) ?? null
  }, [auth, sortedOwners])

  return (
    <FilterDropdown
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      searchPlaceholder={searchPlaceholder ?? 'Search owners'}
      labelForNoSelection={labelForNoSelection ?? 'All owners'}
      labelForSelection={labelForSelection ?? 'Owners'}
      disabled={disabled}
      customCount={
        selectedIds?.size === 1 && authorizedOwner && selectedIds.has(authorizedOwner?.id)
          ? 'Me'
          : undefined
      }
    >
      {authorizedOwner && (
        <CommandGroup className='border-t border-grey-s2 border-b py-1'>
          <FilterCheckboxItem id={authorizedOwner.id}>
            <div className='flex flex-col gap-1'>
              <span>
                {authorizedOwner.title} - <span className='font-bold'>me</span>
              </span>
              <span className='text-grey-s5 text-xs'>{authorizedOwner.email}</span>
            </div>
          </FilterCheckboxItem>
        </CommandGroup>
      )}
      {sortedOwners.map((owner) => (
        <FilterCheckboxItem key={owner.id} id={owner.id}>
          <div className='flex flex-col gap-1'>
            {owner.title}
            <span className='text-grey-s5 text-xs'>{owner.email}</span>
          </div>
        </FilterCheckboxItem>
      ))}
    </FilterDropdown>
  )
}
