import {
  createRootRoute,
  createRoute,
  LinkOptions,
  Navigate,
  Router,
  useNavigate,
} from '@tanstack/react-router'
import { Permission } from '@valuecase/seller-backend'
import { useEffect, useMemo, lazy, Suspense } from 'react'
import { z } from 'zod'
import { useHasPermission } from '../../auth/permissions'
import { ActivityFeed } from '../activityFeed/ActivityFeed'
import Analytics from '../analytics/Analytics'
import { AssetLibrary } from '../assetLibrary/AssetLibrary'
import CompanySettings from '../companySettings/CompanySettings'
import ImprintAnPrivacy from '../imprint&privacy/ImprintAnPrivacy'
import Integrations from '../integrations/Integrations'
import MailNotifications from '../mailNotifications/MailNotifications'
import ProfileAccount from '../profileAccount/ProfileAccount'
import SpaceProperties from '../spaceProperties/SpaceProperties'
import EmbeddedSpaceCreate from '../spaces/EmbeddedSpaceCreate'
import { Spaces } from '../spaces/Spaces'
import { Tasks } from '../tasks/Tasks'
import {
  actionPlanTasksQuerySchema,
  getCleanSub,
  taskAnalyticsSearchSchema,
} from '@valuecase/common'
import TeamsManagement from '../teams/TeamsManagement'
import Templates from '../templates/Templates'
import TenantsAdmin from '../tenantManagement/TenantsAdmin'
import UserManagement from '../userManagement/UserManagement'
import Layout from './layout/Layout'
import TaskAnalytics from '../analytics/TaskAnalytics'
import { queryClient } from '@/main'
import { spacesQueryFiltersSchema } from '../spaces/hooks/useReadSpaces'
import { useAuthState } from '@/auth/auth'

const RedirectWithOwnerId = ({ to }: { to: '/spaces' | '/tasks' }) => {
  const auth = useAuthState()

  const userId = useMemo(() => {
    if (auth.authorized) {
      return getCleanSub(auth.sub)
    }
    return null
  }, [auth])

  return <Navigate to={to} search={userId ? { ownerIds: [userId] } : undefined} />
}

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
type RouteGuardProps = {
  necessaryPermission: Permission
  Component: () => JSX.Element
  onDeniedRedirectTo?: LinkOptions['to']
}
//RouteGuard redirects useres to Dashboard, currently Spaces if permissions are not sufficient to access Route
function RouteGuard({
  necessaryPermission,
  Component,
  onDeniedRedirectTo,
}: RouteGuardProps): JSX.Element {
  const permissionGranted = useHasPermission(necessaryPermission)
  const nav = useNavigate()

  useEffect(() => {
    if (!permissionGranted) {
      nav({ to: onDeniedRedirectTo || '/' })
    }
  }, [permissionGranted, nav, onDeniedRedirectTo])

  if (permissionGranted) {
    return <Component />
  }

  return <>Redirecting...</>
}

const rootRoute = createRootRoute()

// Renders a standalone space creation dialog that is not part of the main layout
const embedSpacesCreateRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/embed/spaces/create',
  component: EmbeddedSpaceCreate,
})

const dashboardRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'dashboard',
  component: Layout,
  validateSearch: z.object({
    paymentSuccess: z.coerce.boolean().optional(),
    subscriptionSuccess: z.coerce.boolean().optional(),
    pricing: z.string().optional(),
  }),
})
const homeRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/',
  component: () => <RedirectWithOwnerId to='/spaces' />,
})
export const spacesRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/spaces',
  validateSearch: spacesQueryFiltersSchema.extend({
    createSpaceFromTemplate: z.coerce.string().optional(),
  }),
  component: Spaces,
})
export const templatesRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/templates',
  validateSearch: z.object({
    // Property is used for duplicating a public template from the template gallery
    // and can be used outside of the app
    createTemplateFrom: z.coerce.string().optional(),
    search: z.string().optional(),
    ownerIds: z.array(z.string()).optional(),
    status: z.enum(['active', 'archived', 'all']).default('active'),
  }),
  component: () => <RouteGuard necessaryPermission={'TEMPLATES_MANAGE'} Component={Templates} />,
})
const assetLibraryRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/assetLibrary',
  validateSearch: z.object({
    folderId: z.string().nullish().optional(),
    addAsset: z.coerce.string().optional(),
  }),
  component: () => (
    <RouteGuard necessaryPermission={'ASSET_LIBRARY_VIEW'} Component={AssetLibrary} />
  ),
})
const activityFeedRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/activityFeed',
  component: ActivityFeed,
  validateSearch: z.object({
    activityTypeGroups: z.array(z.string()).optional(),
    ownerIds: z.array(z.string()).optional(),
  }),
  onLeave: () => {
    queryClient.refetchQueries(['activityFeedCount'])
    queryClient.invalidateQueries(['activities'])
  },
})
const analyticsRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/analytics/teamActivityAnalytics',
  validateSearch: z.object({
    timeRange: z.enum(['7d', '30d', '3m', '6m']).default('30d'),
  }),
  component: Analytics,
})

export const taskAnalyticsRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: 'analytics/actionPlanAnalytics',
  validateSearch: taskAnalyticsSearchSchema,
  component: TaskAnalytics,
})

const taskRouteSearchSchema = actionPlanTasksQuerySchema
  .pick({
    search: true,
  })
  .extend({
    taskStatus: actionPlanTasksQuerySchema.shape.taskStatus.or(z.enum(['all'])).default('all'),
    view: z.enum(['columns', 'list']).default('columns'),
    searchTimeRange: z
      .union([
        z.object({
          months: z.number(),
        }),
        z.object({
          days: z.number(),
        }),
      ])
      .optional(),
    ownerIds: z.array(z.string()).optional(),
  })

export type TaskRouteSearch = z.infer<typeof taskRouteSearchSchema>

const tasksRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: 'tasks',
})

export const tasksOverviewRoute = createRoute({
  getParentRoute: () => tasksRoute,
  path: '/',
  validateSearch: taskRouteSearchSchema,
  component: Tasks,
})

export const legacyTaskListRoute = createRoute({
  getParentRoute: () => tasksRoute,
  path: '/list',
  component: () => <RedirectWithOwnerId to='/tasks' />,
})
const mySettingsLayoutRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  id: 'mySettings',
})
const profileAccountRoute = createRoute({
  getParentRoute: () => mySettingsLayoutRoute,
  path: 'mySettings/profileAccount',
  component: ProfileAccount,
})
const mailNotificationsRoute = createRoute({
  getParentRoute: () => mySettingsLayoutRoute,
  path: 'mySettings/mailNotifications',
  component: MailNotifications,
})
const integrationsRoute = createRoute({
  getParentRoute: () => mySettingsLayoutRoute,
  path: 'mySettings/integrations',
  component: Integrations,
})

const administrationLayoutRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  id: 'administration',
})
const companySettingsRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/administration/companySettings',
  component: () => (
    <RouteGuard
      necessaryPermission={'TENANT_SETTINGS_MANAGE'}
      Component={CompanySettings}
      onDeniedRedirectTo='/spaces'
    />
  ),
})
const teamteamManagementRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/administration/teamManagement',
  component: () => (
    <RouteGuard
      necessaryPermission={'TENANT_SETTINGS_MANAGE'}
      Component={TeamsManagement}
      onDeniedRedirectTo='/spaces'
    />
  ),
})
const userManagementRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/administration/userManagement',
  component: () => (
    <RouteGuard
      necessaryPermission={'USERS_MANAGE'}
      Component={UserManagement}
      onDeniedRedirectTo='/spaces'
    />
  ),
})

const spacePropertiesRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/spaceProperties',
  component: () => (
    <RouteGuard
      necessaryPermission={'SPACE_PROPERTIES_MANAGE'}
      Component={SpaceProperties}
      onDeniedRedirectTo='/spaces'
    />
  ),
})

const workflowsRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: 'workflows',
  component: () => (
    <RouteGuard
      necessaryPermission={'TEMPLATES_MANAGE'}
      Component={() => {
        const LazyWorkflowList = lazy(() => import('../workflows/WorkflowList'))
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyWorkflowList />
          </Suspense>
        )
      }}
      onDeniedRedirectTo='/spaces'
    />
  ),
})

const createWorkflowRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: 'workflows/create',
  component: () => (
    <RouteGuard
      necessaryPermission={'TEMPLATES_MANAGE'}
      Component={() => {
        const LazyCreateWorkflowScreen = lazy(
          () => import('../workflows/components/CreateWorkflowScreen'),
        )
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyCreateWorkflowScreen />
          </Suspense>
        )
      }}
      onDeniedRedirectTo='/spaces'
    />
  ),
})

const editWorkflowRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: 'workflows/$workflowId',
  component: () => (
    <RouteGuard
      necessaryPermission={'TEMPLATES_MANAGE'}
      Component={() => {
        const LazyCreateWorkflowScreen = lazy(
          () => import('../workflows/components/CreateWorkflowScreen'),
        )
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyCreateWorkflowScreen workflowId={editWorkflowRoute.useParams().workflowId} />
          </Suspense>
        )
      }}
      onDeniedRedirectTo='/spaces'
    />
  ),
})

const legacySpacePropertiesRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/administration/spaceProperties',
  component: () => (
    <RouteGuard
      necessaryPermission={'TENANT_SETTINGS_MANAGE'}
      Component={SpaceProperties}
      onDeniedRedirectTo='/spaces'
    />
  ),
})

const imprintAndPrivacyRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/imprintAndPrivacy',
  component: ImprintAnPrivacy,
})
const tenantsAdminRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/tenants-admin',
  component: () => (
    <RouteGuard necessaryPermission={'ALL_TENANTS_ADMIN'} Component={TenantsAdmin} />
  ),
})

const routeTree = rootRoute.addChildren([
  dashboardRoute.addChildren([
    homeRoute,
    spacesRoute,
    templatesRoute,
    tasksRoute.addChildren([tasksOverviewRoute, legacyTaskListRoute]),
    assetLibraryRoute,
    activityFeedRoute,
    analyticsRoute,
    taskAnalyticsRoute,
    mySettingsLayoutRoute.addChildren([
      profileAccountRoute,
      mailNotificationsRoute,
      integrationsRoute,
    ]),
    administrationLayoutRoute.addChildren([
      companySettingsRoute,
      userManagementRoute,
      teamteamManagementRoute,
      legacySpacePropertiesRoute,
      spacePropertiesRoute,
      workflowsRoute,
      createWorkflowRoute,
      editWorkflowRoute,
    ]),
    tenantsAdminRoute,
    imprintAndPrivacyRoute,
  ]),
  embedSpacesCreateRoute,
])

// Set up a ReactRouter instance
export const router = new Router({
  routeTree,
  defaultPreload: 'intent',
})
