import { useCallback, useMemo, useState } from 'react'
import {
  Input2,
  Button2,
  cn,
  useNotifications,
  useBreakpoints,
  AvatarThumbnail,
  FileUploadArea,
} from '@valuecase/ui-components'
import { fetchLogoFromUrl } from '../../../utils/getLogoFromUrl'
import { TSelfSignUp } from '../types/TSelfSignUp'
import FilesDropArea from '@/modules/spaces/components/spaceCreationFlow/FilesDropArea'
import FilesDropAreaBrowse from '@/modules/spaces/components/spaceCreationFlow/FilesDropAreaBrowse'
import { useS3Upload } from '@/api/s3'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { useAuthState } from '@/auth/auth'
import { CompanyAutocomplete, CompanyAutocompleteItem } from '@/ui-components/CompanyAutocomplete'
import { isValidCompanyWebsiteUrl } from '@valuecase/common'

type SpaceCreationStepProps = {
  form: TSelfSignUp
  onUpdateForm: (field: keyof TSelfSignUp, value: string | File | null | undefined) => void
}

export const SpaceCreationStep = ({ form, onUpdateForm }: SpaceCreationStepProps) => {
  const [logoUrl, setLogoUrl] = useState('')
  const [websiteError, setWebsiteError] = useState('')
  const { error } = useNotifications()
  const { upload } = useS3Upload()
  const { trackEvent } = useTrackEvent()
  const authState = useAuthState()
  const userId = useMemo(() => (authState.authorized ? authState.sub : undefined), [authState])
  const isWebsiteValid = useCallback(() => {
    return isValidCompanyWebsiteUrl(logoUrl)
  }, [logoUrl])

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        const result = await upload(file)
        onUpdateForm('logoBlob', file)
        onUpdateForm('spaceLogo', result.s3ObjectKey)
        trackEvent({
          event: 'self_sign_up-space_creation-loadlogo',
          eventProperties: {
            logo_url: result.s3ObjectKey,
            userId,
          },
        })
      } catch (err) {
        console.error('Failed to handle file upload:', err)
        error('Failed to upload logo')
      }
    },
    [upload, onUpdateForm, trackEvent, userId, error],
  )

  const getLogoFromUrl = useCallback(
    async (url: string) => {
      if (!url) return
      try {
        const logoResult = await fetchLogoFromUrl(url)
        if (logoResult) {
          handleUploadFile(logoResult.file)
          onUpdateForm('contactWebsiteUrl', logoResult.url)
        }
      } catch (err) {
        error('No logo found for the given URL.')
      }
    },
    [handleUploadFile, onUpdateForm, error],
  )

  const handleWebsiteInputBlur = useCallback(async () => {
    if (logoUrl && !isWebsiteValid()) {
      setWebsiteError('Please input a correct URL')
      return
    }

    if (logoUrl) {
      await getLogoFromUrl(logoUrl)
    }
  }, [isWebsiteValid, logoUrl, getLogoFromUrl])

  const handleDeleteLogo = useCallback(() => {
    onUpdateForm('logoBlob', undefined)
    onUpdateForm('spaceLogo', null)
  }, [onUpdateForm])

  const { isMobile } = useBreakpoints()

  const onTakeSuggestion = useCallback(
    (value: CompanyAutocompleteItem) => {
      onUpdateForm('companyName', value.value)
      onUpdateForm('logoBlob', undefined)
      setLogoUrl(value.domain || '')
      if (value.domain) {
        getLogoFromUrl(value.domain)
      }
    },
    [onUpdateForm, getLogoFromUrl],
  )

  if (isMobile) {
    return (
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-2'>
          <span className='text-sm font-semibold text-grey-s6'>Customer company name</span>
          <CompanyAutocomplete
            placeholder="Enter your customer's company name"
            value={form.companyName}
            onValueChange={(value) => onUpdateForm('companyName', value)}
            onTakeSuggestion={onTakeSuggestion}
          />
        </div>

        <div className='flex flex-col gap-2'>
          <span className='text-sm font-semibold text-grey-s6'>Customer name</span>
          <div className='flex flex-col gap-4'>
            <Input2
              placeholder="Enter your customer's first name"
              value={form.contactFirstName}
              onChange={(e) => onUpdateForm('contactFirstName', e.target.value)}
            />
            <Input2
              placeholder="Enter your customer's last name"
              value={form.contactLastName}
              onChange={(e) => onUpdateForm('contactLastName', e.target.value)}
            />
          </div>
        </div>

        <div className='flex flex-col gap-2'>
          <span className='text-sm font-semibold text-grey-s6'>Customer website</span>
          <Input2
            placeholder='Enter website URL'
            value={logoUrl}
            onChange={(e) => {
              setLogoUrl(e.target.value)
              setWebsiteError('')
              if (!e.target.value) {
                onUpdateForm('contactWebsiteUrl', undefined)
              }
            }}
            onBlur={handleWebsiteInputBlur}
          />
        </div>

        <div className='flex flex-col gap-2'>
          <span className='text-sm font-semibold text-grey-s6'>Customer logo</span>
          {!form.logoBlob ? (
            <div className='flex gap-2 w-full'>
              <FileUploadArea
                className='w-full'
                onFileSelected={(files) => {
                  handleUploadFile(files[0])
                }}
                acceptedFileTypes={{
                  mimeTypes: ['image/*'],
                  acceptedFilesDescription: 'PNG, JPG, SVG',
                }}
                maxFileSizeInMB={5}
              />
            </div>
          ) : (
            <div className='flex gap-1 items-center'>
              <AvatarThumbnail
                type='image'
                shape={'SQUARE'}
                imageUrl={URL.createObjectURL(form.logoBlob)}
                size={'EXTRALARGE'}
              />
              <Button2
                variant='danger-plain'
                leadingIcon='trash-outline'
                onClick={handleDeleteLogo}
              >
                Delete logo
              </Button2>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={cn('w-full flex flex-col')}>
      <div className={cn('p-8 h-fit bg-white/50 rounded-lg flex flex-col gap-4')}>
        <div className='flex items-center w-full'>
          <div className='w-[27.15%] flex items-center'>
            <span className='text-sm font-semibold text-grey-s6'>Customer Company</span>
          </div>
          <div className='flex-1'>
            <CompanyAutocomplete
              placeholder="Enter your customer's company name"
              value={form.companyName}
              onValueChange={(value) => onUpdateForm('companyName', value)}
              onTakeSuggestion={onTakeSuggestion}
            />
          </div>
        </div>

        <div className='flex items-center w-full'>
          <div className='w-[27.15%] flex items-center'>
            <span className='text-sm font-semibold text-grey-s6'>Contact name</span>
          </div>
          <div className='flex-1 flex gap-2'>
            <Input2
              placeholder="Enter your customer's first name"
              value={form.contactFirstName}
              onChange={(e) => onUpdateForm('contactFirstName', e.target.value)}
            />
            <Input2
              placeholder="Enter your customer's last name"
              value={form.contactLastName}
              onChange={(e) => onUpdateForm('contactLastName', e.target.value)}
            />
          </div>
        </div>

        <div className='flex w-full'>
          <div className='w-[27.15%] pt-3'>
            <span className='text-sm font-semibold text-grey-s6'>
              Customers <br /> Company logo
            </span>
          </div>
          <div className='flex-1'>
            {!form.logoBlob ? (
              <div className='flex gap-2 w-full'>
                <div className='w-1/2'>
                  <FilesDropArea fillParent onFilesDropped={handleUploadFile}>
                    <FilesDropAreaBrowse
                      supportedMimeTypes={'image/*'}
                      supportedText={'PNG, JPG, SVG'}
                      onFilesSelected={handleUploadFile}
                      allowMultipleFiles={false}
                    />
                  </FilesDropArea>
                </div>
                <div className='w-1/2 flex flex-col gap-2 p-3'>
                  <p className='text-sm text-grey-s5 mb-4'>
                    <span className='font-bold'>Or</span> enter your customer&apos;s website URL to
                    load the logo automatically.
                  </p>
                  <div className='flex  gap-2'>
                    <div className='relative'>
                      <Input2
                        placeholder='Enter website URL'
                        value={logoUrl}
                        onChange={(e) => {
                          setLogoUrl(e.target.value)
                          setWebsiteError('')
                          if (!e.target.value) {
                            onUpdateForm('contactWebsiteUrl', undefined)
                          }
                        }}
                        onBlur={handleWebsiteInputBlur}
                      />
                      {websiteError && (
                        <p className='absolute text-warning-s5 bottom-0 translate-y-[calc(100%+8px)]'>
                          {websiteError}
                        </p>
                      )}
                    </div>
                    <Button2
                      variant='outlined'
                      trailingIcon='arrow-forward-outline'
                      onClick={() => getLogoFromUrl(logoUrl)}
                      disabled={!isWebsiteValid() || !logoUrl}
                    >
                      Load logo
                    </Button2>
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex gap-4 items-end h-[178px]'>
                <div className='w-[178px] h-[178px] overflow-hidden rounded-lg border border-grey-s2 bg-grey-s1'>
                  <img
                    src={URL.createObjectURL(form.logoBlob)}
                    alt='company logo'
                    className='w-full h-full object-contain'
                  />
                </div>
                <Button2 variant='danger' leadingIcon='trash-outline' onClick={handleDeleteLogo}>
                  Delete logo
                </Button2>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
