import { TActionPlanTaskExpanded } from '@valuecase/common'
import { UserAvatarWithNotificationSettings } from '@valuecase/ui-components'
import { useMemo } from 'react'

interface AssigneesCellProps {
  assignees: TActionPlanTaskExpanded['assignedUsers'] | null
}

export const AssigneesCell = ({ assignees = [] }: AssigneesCellProps) => {
  const sortedAssignees = useMemo(() => {
    return (assignees ?? []).sort((a, b) => {
      // First priority: SELLER comes first
      if (a.dealSide === 'SELLER' && b.dealSide !== 'SELLER') return -1
      if (b.dealSide === 'SELLER' && a.dealSide !== 'SELLER') return 1

      // Second priority: dueToday notification enabled comes first
      const aHasDueToday = a.WhitelistedUserNotificationsSettings?.dueToday
      const bHasDueToday = b.WhitelistedUserNotificationsSettings?.dueToday
      if (aHasDueToday && !bHasDueToday) return -1
      if (bHasDueToday && !aHasDueToday) return 1

      return 0
    })
  }, [assignees])

  if (sortedAssignees.length === 0) {
    return null
  }

  if (sortedAssignees.length <= 2) {
    return (
      <div className='flex items-center gap-1'>
        {sortedAssignees.map((assignee) => (
          <UserAvatarWithNotificationSettings
            key={assignee.id}
            shape='CIRCLE'
            user={{
              email: assignee.email ?? undefined,
              name: assignee.firstName ?? undefined,
              WhitelistedUserNotificationsSettings: assignee.WhitelistedUserNotificationsSettings,
              dealSide: assignee.dealSide,
            }}
            monitoredSettings={['dueToday']}
            size='SMALL'
          />
        ))}
      </div>
    )
  }

  return (
    <div className='flex items-center'>
      <div className='flex items-center'>
        {sortedAssignees.map((assignee) => (
          <div key={assignee.id} className='flex items-center -mr-1'>
            <UserAvatarWithNotificationSettings
              shape='CIRCLE'
              user={{
                email: assignee.email ?? undefined,
                name: assignee.firstName ?? undefined,
                WhitelistedUserNotificationsSettings: assignee.WhitelistedUserNotificationsSettings,
                dealSide: assignee.dealSide,
              }}
              monitoredSettings={['dueToday']}
              size='SMALL'
            />
          </div>
        ))}
      </div>
    </div>
  )
}
