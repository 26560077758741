import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertTitle,
  Button2,
} from '@valuecase/ui-components'

import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useAuthState } from '@/auth/auth'

const AccountLockedAlert = ({ onUpgradeClick }: { onUpgradeClick: () => void }) => {
  const authState = useAuthState()
  const accountIsLocked = useMemo(() => authState.isLocked, [authState.isLocked])
  const { trackEvent } = useTrackEvent()
  const [showInterestAlert, setShowInterestAlert] = useState(false)
  const { flags } = useLDflags()
  const [isOpen, setIsOpen] = useState(false)
  const handleUpgradeNow = useCallback(() => {
    trackEvent({
      event: 'Trial-end-meeting',
    })
    if (flags && flags['stripe-paywall']) {
      onUpgradeClick()
    } else {
      window.open('https://calendly.com/lennart-prange/call-with-lennart', '_blank')
    }
  }, [trackEvent, onUpgradeClick, flags])

  const handleTalkToSales = useCallback(() => {
    trackEvent({
      event: 'Trial-end-checkout',
    })
    setShowInterestAlert(true)
    window.open('https://calendly.com/lennart-prange/call-with-lennart', '_blank')
  }, [trackEvent])

  useEffect(() => {
    // Use useEffect to only show dialog after everthing is loaded and we can determine if the user is locked
    if (accountIsLocked) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [accountIsLocked, flags])

  if (!isOpen) {
    return null
  }

  return (
    <AlertDialog open={true}>
      <AlertDialogContent>
        <AlertDialogHeader iconClassName='text-blue-s4' iconName={'prism'}>
          Did You Enjoy Your Trial?
        </AlertDialogHeader>
        <AlertDialogDescription>
          Your 14-day free trial period has ended, but all of your templates and spaces are safe. To
          retain access, you need to upgrade to a paid plan.
          <br />
          Contact <span className='font-bold'>lennart@valuecase.com</span> for any questions.
          {showInterestAlert && (
            <Alert variant='success' iconName='mail' className='text-left mt-8'>
              <AlertTitle>Thanks a lot for your interest!</AlertTitle>
              <AlertDescription>
                Someone from the team will be in contact very shortly.
              </AlertDescription>
            </Alert>
          )}
        </AlertDialogDescription>
        <AlertDialogFooter className='flex items-center justify-center px-8'>
          <Button2 className='w-full' variant={'subtle'} onClick={handleTalkToSales}>
            Talk to Sales
          </Button2>
          <Button2 className='w-full' variant={'solid'} onClick={handleUpgradeNow}>
            Upgrade Now
          </Button2>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default AccountLockedAlert
