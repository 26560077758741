import Topbar from '../topbar/Topbar'

import { Auth, useAuthState } from '@/auth/auth'
import { Outlet, useSearch } from '@tanstack/react-router'
import { useNotifications } from '@valuecase/ui-components'
import { useEffect, useMemo } from 'react'
import IntercomComponent from '../Intercom/Intercom'
import { Sidebar } from '../sidebar/Sidebar'
import { S } from './Layout.styled'

const Layout = () => {
  const search = useSearch({ from: '/dashboard' })
  const paymentSuccess = useMemo(() => search.paymentSuccess === true, [search.paymentSuccess])

  const { isLocked } = useAuthState()
  const { success } = useNotifications()
  /**
   * This handles the redirect from the Stripe hosted checkout page and forces a re-auth in order to unlock the account.
   */
  useEffect(() => {
    if (paymentSuccess && isLocked) {
      Auth.login(window.location.pathname + '?subscriptionSuccess=true')
    }
  }, [paymentSuccess, isLocked])

  return (
    <S.Layout>
      <Topbar />
      <S.SecondRow>
        <Sidebar />
        <S.Main className='[scrollbar-gutter:stable]'>
          <S.Margin>
            <Outlet />
          </S.Margin>
        </S.Main>
      </S.SecondRow>
      <IntercomComponent hideDefaultLauncher={false} />
    </S.Layout>
  )
}

export default Layout
