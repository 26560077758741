import { useCallback, useEffect, useState } from 'react'
import { SpacePropertyEntry } from './SpacePropertyEntry'
import { useGetTenantCustomProperties } from './useGetTenantCustomProperties'
import { useLDflags } from '../../launchdarkly/Launchdarkly'
import { useGetTenantUsedCustomProperties } from './useGetTenantUsedCustomProperties'
import { cn } from '@/utils/Cn'
import { TCustomProperty } from '@valuecase/common'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { Divider } from '@valuecase/ui-components'
import IntercomLearnMoreButton from '../navigation/Intercom/IntercomLearnMoreButton'

const SpaceProperties = () => {
  const [properties, setProperties] = useState<
    (Pick<TCustomProperty, 'displayName' | 'key' | 'options'> & { id?: string })[]
  >([])
  const { flags } = useLDflags()
  const { customProperties } = useGetTenantCustomProperties()
  const isCreatingNewProperty =
    !!customProperties?.length && properties.length > customProperties?.length

  const [allTenantUsedProps, setAllTenantUsedProps] = useState(null)

  const { tenantUsedCustomProperties } = useGetTenantUsedCustomProperties()

  useEffect(() => {
    if (tenantUsedCustomProperties) {
      setAllTenantUsedProps(tenantUsedCustomProperties)
    }
  }, [tenantUsedCustomProperties])

  useEffect(() => {
    if (customProperties) {
      setProperties(customProperties.sort((a, b) => a.createdAt.localeCompare(b.createdAt)))
    }
  }, [customProperties])

  function handleAddProperty() {
    setProperties([...properties, { displayName: '', key: '', options: [] }])
  }

  function handleCancelCreation() {
    setProperties([...properties].slice(0, properties.length - 1))
  }

  const handleLearnMoreClick = useCallback(() => {
    window.Intercom('showArticle', 172915)
  }, [])

  return (
    <div className={'flex flex-col py-11 w-full'}>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-118'}>
          <div className={'flex gap-3 items-center'}>
            <h1 className={'text-2xl font-bold'}>Space Properties</h1>
            <IntercomLearnMoreButton onClick={handleLearnMoreClick} />
          </div>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Add variable text properties to your spaces to make them more personalized and engaging.
          </p>
        </div>
      </div>

      <Divider className='my-6' />

      <div className='flex gap-2'>
        <div className='flex flex-col w-96 gap-1'>
          <h4 className='text-grey-s6 text-sm font-[650]'>Display name in Valuecase</h4>
          <p className='text-grey-s5 text-sm'>
            The tag which will be shown when adding this placeholder in your template. Currently
            these elements can&apos;t be deleted.{' '}
          </p>
        </div>
        <div className='flex flex-col w-96 gap-1'>
          <h4 className='text-grey-s6 text-sm font-[650]'>Custom property key name</h4>
          <p className='text-grey-s5 text-sm'>
            If API Feature is available for your account, you are able to add a key name here.
          </p>
        </div>
      </div>

      {properties.length > 0 && (
        <div className='flex flex-col gap-6 mt-3'>
          {properties.map((property, index: number) => {
            const listOfSpacesThePropertyIsBeingUsedIn =
              property?.key && allTenantUsedProps !== null
                ? allTenantUsedProps[property.key] || []
                : null

            return (
              <SpacePropertyEntry
                data={{ ...property }}
                key={`${index}-${property.id}`}
                onCancel={handleCancelCreation}
                usedIn={listOfSpacesThePropertyIsBeingUsedIn}
              />
            )
          })}
        </div>
      )}

      <div
        className={cn('mt-6', {
          // typically parent would be responsible for padding, but layout from several layers up
          // would need to be reworked, unjustifiable for this small change for now
          'pb-20': !flags?.['gpt-for-space-properties'],
        })}
      >
        <Button2
          disabled={isCreatingNewProperty}
          leadingIcon='add-outline'
          onClick={handleAddProperty}
        >
          Add property
        </Button2>
      </div>
      {!!flags?.['gpt-for-space-properties'] && (
        <div className='mt-3 max-w-[50rem] text-xs text-grey-s5 mb-3 pb-20'>
          * When generating examples, placeholders{' '}
          {'{{contactFirstName}}, {{contactLastName}}, {{companyName}}'} are replaced with values
          John, Doe, and Apple respectively. When used while creating a space, these placeholders
          will be replaced with the actual values of the contact and company.
        </div>
      )}
    </div>
  )
}

export default SpaceProperties
