import {
  Props,
  UseCaseSelector,
} from '@/modules/templates/TemplateCreator/TemplateGeneration/stages/TemplateGenerationPromptStage'
import { useCallback } from 'react'

export const UseCaseStep = ({
  onUpdateForm,
  onUseCaseSelect,
}: Pick<Props, 'form' | 'onUpdateForm' | 'onUseCaseSelect'>) => {
  const handleUseCaseChange = useCallback(
    (useCase: string) => {
      onUpdateForm('useCase', useCase)
      onUseCaseSelect?.(useCase)
    },
    [onUpdateForm, onUseCaseSelect],
  )

  return (
    <div className='flex flex-col gap-6 w-full'>
      <UseCaseSelector onUseCaseSelect={handleUseCaseChange} />
    </div>
  )
}
