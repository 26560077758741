import SellerApi from '../../../api/SellerApi'
import { HubSpotDeal } from './types/HubSpotDeal'
import { useSearchIntegrationItemsQuery } from '../hooks/useSearchIntegrationItemsQuery'
import { HubSpotCompany, HubSpotContact } from './types'

export type HubSpotIntegrationObjectTypeMapping = {
  deals: HubSpotDeal
  companies: HubSpotCompany
  contacts: HubSpotContact
}

export type HubSpotIntegrationObjectType = keyof HubSpotIntegrationObjectTypeMapping

async function searchHubSpotObjects<U extends keyof HubSpotIntegrationObjectTypeMapping>(
  searchTerm: string,
  objectType: U,
) {
  const res = await SellerApi.get<any, undefined>(
    `integrations/hubspot/search?objectType=${objectType}&searchTerm=${encodeURIComponent(
      searchTerm,
    )}`,
  )
  return res.payload.results as HubSpotIntegrationObjectTypeMapping[U][] | undefined
}

/**
 * Hook to search HubSpot objects accessible to the current user (via OAuth2).
 */
export function useSearchHubSpotObjects<U extends keyof HubSpotIntegrationObjectTypeMapping>({
  enabled,
  searchTerm,
  objectType,
}: {
  enabled?: boolean
  searchTerm: string
  objectType: U
}) {
  return useSearchIntegrationItemsQuery<HubSpotIntegrationObjectTypeMapping, U>({
    queryKeyPrefix: 'searchHubSpotObjects',
    enabled,
    searchTerm,
    objectType,
    objectTypeName: objectType,
    searchFunction: searchHubSpotObjects,
  })
}
