import {
  IWhitelistedUser,
  IWhitelistedUserDealSide,
  MonitorableNotificationSettingsArray,
} from '@valuecase/common'
import { PropsWithChildren, useMemo } from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../components/ui'
import { IonIcon } from '../../Icons/IonIcon'
import { getNotificationText, useNotificationStatus } from './notification-utils'

export type UserNotificationSettingsProps = {
  WhitelistedUserNotificationsSettings?: IWhitelistedUser['WhitelistedUserNotificationsSettings']
  dealSide: IWhitelistedUserDealSide
  name?: string
  email?: string
  firstName?: string
  lastName?: string
}

const UserInfoTooltip = ({
  user,
  children,
  monitoredSettings,
}: {
  user: UserNotificationSettingsProps
  monitoredSettings?: MonitorableNotificationSettingsArray
} & PropsWithChildren) => {
  const status = useNotificationStatus(user?.WhitelistedUserNotificationsSettings)

  const notificationText = getNotificationText({
    status,
    settings: user?.WhitelistedUserNotificationsSettings,
    monitoredSettings,
  })

  const notificationIcon = useMemo(() => {
    return status.allNotificationsEnabled || status.someNotificationsEnabled
      ? 'notifications-outline'
      : 'notifications-off-outline'
  }, [status.allNotificationsEnabled, status.someNotificationsEnabled])

  const name = user?.firstName
    ? `${user?.firstName} ${user?.lastName}`
    : user?.name
      ? user?.name
      : user?.email ?? ''

  return (
    <Tooltip>
      <TooltipTrigger className='relative p-0 flex items-center'>
        {children}
        {!status.allNotificationsDisabled && (
          <div className='absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 flex items-center'>
            <IonIcon className='text-white h-3 w-3' name='c_notifications-inner-stroke' />
          </div>
        )}
      </TooltipTrigger>

      <TooltipContent side='bottom' align='center'>
        <div className='flex flex-row items-start gap-1 h-fit py-1 px-2'>
          <div className='flex flex-col h-full w-fit justify-start'>
            <IonIcon className='h-3 w-3 mt-1' name={notificationIcon} />
          </div>
          <div className='flex flex-col gap-0 text-start'>
            <span className='text-xxs font-bold text-white'>{name}</span>
            {user.WhitelistedUserNotificationsSettings && (
              <span className='text-xxs text-white text-grey-s5 '>{notificationText}</span>
            )}
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  )
}

export default UserInfoTooltip
