import TeamSelector from '@/modules/teams/TeamSelector'
import { isValidCompanyWebsiteUrl, TeamUnion } from '@valuecase/common'
import { Button2, Input2, Hairline, cn } from '@valuecase/ui-components'
import { useCallback, useMemo, useState } from 'react'
import FilesDropArea from './FilesDropArea'
import FilesDropAreaBrowse from './FilesDropAreaBrowse'
import { Step1DataType } from './SpaceCreationFlow'
import { CompanyAutocomplete, CompanyAutocompleteItem } from '@/ui-components/CompanyAutocomplete'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { validate as validateEmail } from 'email-validator'
import { useAuthState } from '@/auth/auth'

type SpaceCreationForm = {
  values: Step1DataType
  websiteUrl?: string | undefined
  onUpdateForm: (key: keyof Step1DataType, value: string | File | null | undefined) => void
  setWebsiteUrl: React.Dispatch<React.SetStateAction<string | undefined>>
  loadLogoFromWebsiteUrl: (websiteUrl: string) => Promise<void>
  handleUploadFile: (file: File) => Promise<void>
  selectedTeamIds?: Set<string>
  setSelectedTeamIds?: (teamIds: Set<string> | undefined) => void
  teams?: TeamUnion[]
  tenantName?: string
  className?: string
}

export function SpaceCreationForm({
  values,
  onUpdateForm,
  websiteUrl,
  setWebsiteUrl,
  loadLogoFromWebsiteUrl,
  handleUploadFile,
  selectedTeamIds,
  setSelectedTeamIds,
  teams = [],
  tenantName = '',
  className,
}: SpaceCreationForm) {
  const [websiteError, setWebsiteError] = useState('')
  const [emailError, setEmailError] = useState('')
  const hasTeams = useMemo(() => teams.length > 0, [teams])
  const authState = useAuthState()
  const currentUserEmail = useMemo(() => (authState.authorized ? authState.email : ''), [authState])

  const { flags } = useLDflags()

  const handleWebsiteUrlInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setWebsiteUrl(e.target.value)
      setWebsiteError('')
      if (!e.target.value) {
        onUpdateForm('website', undefined)
      }
    },
    [onUpdateForm, setWebsiteUrl],
  )

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const email = e.target.value
      onUpdateForm('contactEmail', email || undefined)
      if (email && !validateEmail(email)) {
        setEmailError('Please enter a valid email address')
      } else if (email && email.toLowerCase() === currentUserEmail.toLowerCase()) {
        setEmailError(
          'You cannot enter your own email as the buyer email. (Hint: Aliases such as youremail+alias@email.com are allowed.)',
        )
      } else {
        setEmailError('')
      }
    },
    [onUpdateForm, currentUserEmail],
  )

  const validateWebsiteUrl = useCallback(
    (
      url: string | undefined,
    ): { success: true; error: undefined } | { success: false; error: string } => {
      if (!url) {
        // Empty urls are valid
        return { success: true, error: undefined }
      }
      if (url.length > 255) {
        return { success: false, error: 'URL must be less than 255 characters' }
      }
      if (!isValidCompanyWebsiteUrl(url)) {
        return { success: false, error: 'Please input a correct URL' }
      }
      return { success: true, error: undefined }
    },
    [],
  )

  const handleWebsiteUrlUpdated = useCallback(
    async (url: string | undefined) => {
      setWebsiteError('')
      const validationResponse = validateWebsiteUrl(url)
      if (validationResponse.success && !!url) {
        await loadLogoFromWebsiteUrl(url)
      } else if (!validationResponse.success) {
        setWebsiteError(validationResponse.error)
      }
    },
    [validateWebsiteUrl, loadLogoFromWebsiteUrl],
  )

  const loadLogoButtonIsDisabled = useMemo(
    () => !(websiteUrl && validateWebsiteUrl(websiteUrl).success),
    [websiteUrl, validateWebsiteUrl],
  )

  const handleTakeSuggestion = useCallback(
    async (value: CompanyAutocompleteItem) => {
      onUpdateForm('companyName', value.value)
      onUpdateForm('logoBlob', undefined)
      setWebsiteUrl(value.domain || '')
      if (value.domain) {
        await handleWebsiteUrlUpdated(value.domain)
      }
    },
    [onUpdateForm, setWebsiteUrl, handleWebsiteUrlUpdated],
  )

  const handleDeleteLogo = useCallback(() => {
    onUpdateForm('logoBlob', undefined)
    onUpdateForm('logo', null)
  }, [onUpdateForm])

  return (
    <div className={cn('w-full flex flex-col', className)}>
      <div className='h-[456px] -ml-8 w-[calc(100%+64px)]'>
        <Hairline />
        <div className='p-6 h-[456px] bg-grey-s1 flex flex-col gap-4'>
          {hasTeams && setSelectedTeamIds && (
            <div className='flex items-center w-full'>
              <div className='w-[27.15%] flex items-center'>
                <span className='text-sm font-semibold text-grey-s6'>Team access</span>
              </div>
              <div className='flex-1'>
                <TeamSelector
                  selectedTeamIds={selectedTeamIds}
                  teams={teams}
                  setSelectedTeamIds={setSelectedTeamIds}
                  tenantName={tenantName}
                  triggerButtonSize='default'
                />
              </div>
            </div>
          )}

          <div className='flex items-center w-full'>
            <div className='w-[27.15%] flex items-center'>
              <span className='text-sm font-semibold text-grey-s6'>Company name</span>
            </div>
            <div className='flex-1'>
              <CompanyAutocomplete
                data-intercom-target='Company Name Input Field'
                placeholder='Enter company name'
                value={values.companyName || ''}
                onValueChange={(value) => onUpdateForm('companyName', value)}
                onTakeSuggestion={handleTakeSuggestion}
              />
            </div>
          </div>

          <div className='flex items-center w-full'>
            <div className='w-[27.15%] flex items-center'>
              <span className='text-sm font-semibold text-grey-s6'>Contact details</span>
            </div>
            <div className='flex-1 flex gap-2'>
              <Input2
                data-intercom-target='First Name Input Field'
                placeholder='First name'
                value={values.contactFirstName}
                onChange={(e) => onUpdateForm('contactFirstName', e.target.value)}
              />
              <Input2
                data-intercom-target='Last Name Input Field'
                placeholder='Last name'
                value={values.contactLastName}
                onChange={(e) => onUpdateForm('contactLastName', e.target.value)}
              />
            </div>
          </div>

          {flags && flags['templatized-notifications'] && (
            <div className='flex items-center w-full'>
              <div className='w-[27.15%] flex items-center' />
              <div className='flex-1 flex-col flex gap-2'>
                <Input2
                  data-intercom-target='Email Input Field'
                  placeholder='Email address'
                  type='email'
                  value={values.contactEmail || ''}
                  onChange={handleEmailChange}
                />
                <span
                  className={cn('text-xs pl-1 text-grey-s5', { 'text-warning-s5': !!emailError })}
                >
                  {emailError ||
                    'No invite email will be sent. Manage notifications in Space settings.'}
                </span>
              </div>
            </div>
          )}

          <div className='flex w-full'>
            <div className='w-[27.15%]'>
              <span className='text-sm font-semibold text-grey-s6'>Company logo</span>
            </div>
            <div className='flex-1'>
              {!values.logoBlob ? (
                <div className='flex gap-6 items-center'>
                  <div className='h-[178px] w-[264px] max-w-[40%]'>
                    <FilesDropArea fillParent onFilesDropped={handleUploadFile}>
                      <FilesDropAreaBrowse
                        supportedMimeTypes={'image/*'}
                        supportedText={'PNG, JPG, SVG'}
                        onFilesSelected={handleUploadFile}
                        allowMultipleFiles={false}
                      />
                    </FilesDropArea>
                  </div>
                  <div className='w-[1px] h-full bg-grey-s2' />
                  <div className='flex gap-2 items-end'>
                    <div className='flex max-w-[242px] flex-col relative'>
                      <p className='text-sm text-grey-s5 mb-4'>
                        <span className='font-bold'>Or</span> enter your stakeholder&apos;s website
                        URL to load the logo automatically.
                      </p>
                      <Input2
                        data-intercom-target='Insert Link Input Field'
                        placeholder='Insert link'
                        value={websiteUrl}
                        onChange={handleWebsiteUrlInputChange}
                        onBlur={() => handleWebsiteUrlUpdated(websiteUrl)}
                      />
                      {websiteError && (
                        <p className='absolute text-warning-s5 bottom-0 translate-y-[calc(100%+8px)]'>
                          {websiteError}
                        </p>
                      )}
                    </div>
                    <Button2
                      variant='outlined'
                      trailingIcon='arrow-forward-outline'
                      onClick={() => handleWebsiteUrlUpdated(websiteUrl)}
                      disabled={loadLogoButtonIsDisabled}
                    >
                      Load logo
                    </Button2>
                  </div>
                </div>
              ) : (
                <div className='flex gap-4 items-end h-[178px]'>
                  <div className='w-[178px] h-[178px] overflow-hidden rounded-lg border border-grey-s2 bg-grey-s1'>
                    <img
                      src={URL.createObjectURL(values.logoBlob)}
                      alt='company logo'
                      className='w-full h-full object-contain'
                    />
                  </div>
                  <Button2
                    variant='outlined'
                    trailingIcon='trash-outline'
                    onClick={handleDeleteLogo}
                    className='text-warning-s5 border-warning-s5 hover:bg-warning-s2'
                  >
                    Delete logo
                  </Button2>
                </div>
              )}
            </div>
          </div>
        </div>
        <Hairline />
      </div>
    </div>
  )
}
