import {
  CheckMark,
  cn,
  IonIcon,
  AvatarThumbnail,
  UpdateTaskParams,
  useConfetti,
  ascendToParentWithType,
} from '@valuecase/ui-components'
import { FC, MouseEventHandler, ReactNode, useCallback, useMemo } from 'react'
import { getCompanyInitials, TActionPlanTaskExpanded } from '@valuecase/common'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { AssigneesCell } from './AssigneesCell'
import { useLDflags } from '@/launchdarkly/Launchdarkly'

export const TaskCardView: FC<{
  task: TActionPlanTaskExpanded
  timeToDueDate: ReactNode
  onClick: MouseEventHandler<HTMLDivElement>
  handleUpdateTask: ({ id, data }: UpdateTaskParams) => Promise<void>
}> = ({ task, timeToDueDate, onClick, handleUpdateTask }) => {
  const { showOnceOver } = useConfetti()

  const companyInitials = useMemo(
    () => getCompanyInitials(task.spaceCompanyName),
    [task.spaceCompanyName],
  )

  const handleTaskCheckMarkClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      const newCheckedValue = !task.checked
      const element = e.target instanceof Node && ascendToParentWithType(e.target, HTMLElement)
      if (newCheckedValue && element) {
        showOnceOver(element, { width: 200, height: 200 })
      }
      handleUpdateTask({ id: task.id, data: { checked: newCheckedValue } })
    },
    [handleUpdateTask, showOnceOver, task.checked, task.id],
  )

  const { flags } = useLDflags()
  const assigneesEnabled = useMemo(() => flags && flags['dashboard-map-assignees'], [flags])

  return (
    <Button2 asChild>
      <div
        onClick={onClick}
        className='flex flex-col w-full bg-white/85 rounded-lg p-2 gap-2 cursor-pointer hover:bg-white/100 hover:shadow transition'
      >
        <div className='flex flex-row items-start gap-3'>
          <CheckMark
            className={cn('flex-shrink-0', { 'text-success-s5': task.checked })}
            checked={task.checked}
            onClick={handleTaskCheckMarkClick}
          />
          <div className='flex gap-2'>
            {task.spaceLogoUrl ? (
              <AvatarThumbnail size='MEDIUM' type='image' imageUrl={task.spaceLogoUrl} />
            ) : (
              <AvatarThumbnail size='MEDIUM' type='gravatarInitials' initials={companyInitials} />
            )}
            <div className='flex flex-col flex-1 min-w-0'>
              <div className='text-xxs text-grey-s5 leading-[1.4em]'>{task.spaceCompanyName}</div>
              <div
                className={cn('text-sm font-medium leading-[1.286em]', {
                  'text-grey-s6': !task.checked,
                  'text-grey-s5 line-through': task.checked,
                })}
              >
                {task.title}
              </div>
              <div className='text-xxs text-grey-s5 leading-[1.4em]'>{task.milestoneTitle}</div>
            </div>
          </div>
        </div>

        <div className='flex flex-row items-center w-full'>
          <div className='flex-1'>
            {assigneesEnabled && <AssigneesCell assignees={task.assignedUsers} />}
          </div>
          {task.dueDate && (
            <div className='flex items-center gap-2 ml-auto'>
              <IonIcon name='calendar' className='text-xs text-grey-s5' />
              <div className='text-xs text-grey-s5'>{timeToDueDate}</div>
            </div>
          )}
        </div>
      </div>
    </Button2>
  )
}
