import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  ColumnFiltersState,
  SortingState,
} from '@tanstack/react-table'

import useReadTeams from '@/modules/teams/hooks/useReadTeams'
import { TeamWithMembers, User } from '@valuecase/common'
import { ListRolesApiResponse } from '@valuecase/seller-backend'
import {
  AlertDialog,
  AlertDialogContent,
  Button2,
  DataTable,
  Input2,
  IonIcon,
  MenuDropdown,
  MenuDropdownItem,
  MenuDropdownSeparator,
  useNotifications,
} from '@valuecase/ui-components'
import { useTheme } from 'styled-components'
import { useAuthState } from '../../../auth/auth'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import { useReadAvailableRoles } from '../../auth/hooks/useReadAvailableRoles'
import { useCancelUser } from '../hooks/useCancelUser'
import { useInviteUser } from '../hooks/useInviteUser'
import { useUpdateUserRole } from '../hooks/useUpdateUserRole'
import { useDeleteUserModal } from '../modals/deleteUser/DeleteUserModal'
import EditUserModal from '../modals/editUser/EditUserModal'
import {
  CurrentUserIcon,
  UserListIcon,
  UserRoleButton,
  UserRoleButtonTopText,
  UserRoleDescription,
  UserRoleSelectionItem,
  UserRoleSelectionItemDescription,
  UserRoleSelectionItemHeader,
  UserRoleSelectionWrapper,
  UserRoleWrapper,
  UsersListEmailTextWrapper,
  UsersListIconBox,
  UsersListInvitedPlacedholder,
  UsersListNameAndDescriptionWrapper,
  UsersListNameTextWrapper,
  UsersListNameWrapper,
} from './UsersLists.styled'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useQueryClient } from '@tanstack/react-query'
import { VLoaderAnimation } from '@/ui-components/VLoader/VLoader'

interface Props {
  users: User[]
}

const UsersList: FC<Props> = ({ users }) => {
  const { trackEvent } = useTrackEvent()
  const authState = useAuthState()
  const theme = useTheme()
  const { flags } = useLDflags()
  const { updateUserRole } = useUpdateUserRole()
  const { roles } = useReadAvailableRoles()
  const userId = authState.authorized === true && authState.sub
  const roleName = (authState.authorized === true && authState.role?.name) || ''
  const { success } = useNotifications()
  const { show: showDeleteUserModal } = useDeleteUserModal()
  //Used to track "users-role-change" mixpanel event
  const [oldRoleForMixpanel, set_oldRoleForMixpanel] = useState<string | undefined>(undefined)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const { data: teams } = useReadTeams()
  const [searchValue, setSearchValue] = useState('')
  const [sorting, setSorting] = useState<SortingState>([{ id: 'email', desc: false }])

  const renderTeamsList = useCallback(
    (userId: string): JSX.Element => {
      const userTeams = teams?.filter((team) => team.memberIds.includes(userId))
      const sortedUserTeams = userTeams?.sort((a, b) => a.name.localeCompare(b.name))
      const userTeamNames = sortedUserTeams?.map((userTeam) => userTeam.name).join(', ')
      return <div className='text-xs'>{userTeamNames}</div>
    },
    [teams],
  )

  const queryClient = useQueryClient()

  const { inviteUser } = useInviteUser(() => {
    success('User invite resent')
  })

  const [cancellingInvite, setCancellingInvite] = useState(false)
  const cancellingRefreshTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const { cancelUser } = useCancelUser(() => {
    // The Auth0 backend takes a few seconds to process invite cancellation, so we show a loading spinner
    cancellingRefreshTimeout.current = setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['readUsers'] })
      setCancellingInvite(false)
      success('User invite canceled')
    }, 5000)
  })

  useEffect(() => {
    return () => {
      if (cancellingRefreshTimeout.current) {
        clearTimeout(cancellingRefreshTimeout.current)
        cancellingRefreshTimeout.current = null
      }
    }
  }, [])

  const roleIdToRoleMap = useMemo(
    () =>
      new Map<string, ListRolesApiResponse[0]>(
        roles?.map((role) => [role.id, role as ListRolesApiResponse[0]]),
      ),
    [roles],
  )

  const cancelUserInvite = useCallback(
    (userId: any) => {
      cancelUser(userId)
    },
    [cancelUser],
  )

  const resendInvite = useCallback(
    (user: any) => {
      const splitName = user.fullName.split(' ')
      const firstName = splitName[0]
      const lastName = splitName[1]
      inviteUser({
        email: user.email,
        firstName,
        lastName,
        roleId: user.roleId,
        teamIds: [],
      })
    },
    [inviteUser],
  )

  const columnFilters = useMemo(() => {
    const filters: ColumnFiltersState = []
    if (searchValue) {
      filters.push({
        id: 'fullName',
        value: searchValue,
      })
    }
    return filters
  }, [searchValue])

  const columns = useMemo<ColumnDef<User>[]>(() => {
    const columns: ColumnDef<User>[] = [
      {
        id: 'fullName',
        accessorKey: 'fullName',
        header: () => (
          <div className='flex items-center gap-2'>
            <span>Name</span>
          </div>
        ),
        cell: (ctx) => (
          <UsersListNameWrapper className='h-11'>
            <UsersListIconBox>
              {!!ctx.row.original.profilePictureUrl && (
                <UserListIcon src={ctx.row.original.profilePictureUrl} />
              )}
            </UsersListIconBox>
            <UsersListNameAndDescriptionWrapper>
              <UsersListNameTextWrapper>
                {ctx.row.original.invited ? (
                  <UsersListInvitedPlacedholder>Invitation sent</UsersListInvitedPlacedholder>
                ) : (
                  ctx.row.original.fullName
                )}
              </UsersListNameTextWrapper>
            </UsersListNameAndDescriptionWrapper>
            {userId === ctx.row.original.id ? <CurrentUserIcon>ME</CurrentUserIcon> : null}
          </UsersListNameWrapper>
        ),
        size: 250,
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: 'Email',
        cell: (ctx) => (
          <UsersListEmailTextWrapper className='h-11'>
            {ctx.row.original.email}
          </UsersListEmailTextWrapper>
        ),
        size: flags?.['teams-management'] ? 250 : 300,
        enableSorting: true,
      },
      {
        id: 'role',
        header: 'Role & Permissions',
        cell: (ctx) => {
          const userRoleIsKnown = !!ctx.row.original.roleId
          const isOwnUserRow = ctx.row.original.id === userId
          const userRoleName =
            (ctx.row.original.roleId && roleIdToRoleMap.get(ctx.row.original.roleId)?.name) ||
            'Unknown'

          return (
            <div className='h-11 flex items-center'>
              {!userRoleIsKnown && 'Unknown role'}
              {userRoleIsKnown && isOwnUserRow && (
                <UserRoleWrapper>
                  <UserRoleButton disabled={true}>
                    <UserRoleButtonTopText>{userRoleName}</UserRoleButtonTopText>
                    <UserRoleDescription>
                      Permissions:{' '}
                      {!ctx.row.original.roleId
                        ? 'Unknown'
                        : roleIdToRoleMap.get(ctx.row.original.roleId)?.description || ''}
                    </UserRoleDescription>
                  </UserRoleButton>
                </UserRoleWrapper>
              )}
              {userRoleIsKnown && !isOwnUserRow && (
                <UserRoleWrapper>
                  <MenuDropdown
                    Trigger={
                      <UserRoleButton
                        onClick={() => {
                          set_oldRoleForMixpanel(
                            roleIdToRoleMap.get(ctx.row.original.roleId as string)?.name,
                          )
                        }}
                      >
                        <UserRoleButtonTopText>
                          {(ctx.row.original.roleId &&
                            roleIdToRoleMap.get(ctx.row.original.roleId)?.name) ||
                            'Unknown'}
                          <IonIcon
                            name='chevron-down'
                            style={{
                              height: '16px',
                              width: '16px',
                              marginLeft: '4px',
                              position: 'relative',
                              top: '4px',
                              color: theme.grey.s4,
                            }}
                          />
                        </UserRoleButtonTopText>
                        <UserRoleDescription>
                          Permissions:{' '}
                          {!ctx.row.original.roleId
                            ? 'Unknown'
                            : roleIdToRoleMap.get(ctx.row.original.roleId)?.description || ''}
                        </UserRoleDescription>
                      </UserRoleButton>
                    }
                    placement={'bottom-start'}
                  >
                    <UserRoleSelectionWrapper>
                      {!!roles &&
                        roles.map((role, i) => (
                          <div key={`${ctx.row.original.id}-${role.id}`}>
                            {i !== 0 && <MenuDropdownSeparator />}
                            <UserRoleSelectionItem
                              onClick={() => {
                                trackEvent({
                                  event: 'users-role-change',
                                  eventProperties: {
                                    indexPageName: 'User Management',
                                    permissionTypeSet: role.name,
                                    oldPermissionType: oldRoleForMixpanel,
                                  },
                                })
                                set_oldRoleForMixpanel(undefined)
                                updateUserRole({
                                  roleId: role.id,
                                  userId: ctx.row.original.id,
                                })
                              }}
                            >
                              <UserRoleSelectionItemHeader>
                                {role.name}
                                {role.id === ctx.row.original.roleId && (
                                  <IonIcon
                                    name='checkmark'
                                    style={{
                                      height: '20px',
                                      width: '20px',
                                      color: theme.grey.s4,
                                      marginLeft: '8px',
                                      position: 'relative',
                                      top: '4px',
                                    }}
                                  />
                                )}
                              </UserRoleSelectionItemHeader>
                              <UserRoleSelectionItemDescription>
                                {role.description}
                              </UserRoleSelectionItemDescription>
                            </UserRoleSelectionItem>
                          </div>
                        ))}
                    </UserRoleSelectionWrapper>
                  </MenuDropdown>
                </UserRoleWrapper>
              )}
            </div>
          )
        },
        size: flags?.['teams-management'] ? 250 : 450,
      },
      {
        id: 'actions',
        header: 'Actions',
        cell: (ctx) => {
          const isUserActive = (ctx.row.original as any).active == true
          return (
            <div className='flex justify-start w-16 h-11 items-center'>
              <MenuDropdown
                Trigger={<Button2 leadingIcon='ellipsis-vertical' size='small' variant='plain' />}
                placement={'bottom-start'}
              >
                {!isUserActive && (
                  <>
                    <MenuDropdownItem
                      label='Resend invite'
                      onClick={() => {
                        trackEvent({
                          event: 'users-newuser-resend',
                          eventProperties: {
                            indexPageName: 'User Management',
                          },
                        })
                        resendInvite(ctx.row.original)
                      }}
                      LeftIcon={'send-outline'}
                      variant='default'
                    />
                    <MenuDropdownItem
                      onClick={() => {
                        trackEvent({
                          event: 'users-newuser-cancel_existing_invite',
                          eventProperties: {
                            indexPageName: 'User Management',
                          },
                        })
                        setCancellingInvite(true)
                        cancelUserInvite(ctx.row.original.id)
                      }}
                      label='Cancel user invite'
                      LeftIcon={'close-circle-outline'}
                      variant='danger'
                    />
                  </>
                )}

                {isUserActive && (
                  <MenuDropdownItem
                    label='Edit'
                    onClick={() => {
                      setSelectedUser(ctx.row.original)
                    }}
                    LeftIcon={'create-outline'}
                    variant='default'
                  />
                )}

                {isUserActive && (
                  <MenuDropdownItem
                    label='Delete user'
                    onClick={() => {
                      showDeleteUserModal({
                        user: {
                          id: ctx.row.original.id,
                          email: ctx.row.original.email,
                          fullName: ctx.row.original.fullName,
                        },
                      })
                    }}
                    LeftIcon={'trash-outline'}
                    variant='danger'
                  />
                )}
              </MenuDropdown>
            </div>
          )
        },
        size: 66,
      },
    ]

    if (flags?.['teams-management']) {
      columns.splice(columns.length - 1, 0, {
        id: 'teams',
        header: 'Teams',
        cell: (ctx) => (
          <div className='h-11 flex items-center'>{renderTeamsList(ctx.row.original.id)}</div>
        ),
        size: 250,
      })
    }

    return columns
  }, [
    flags,
    userId,
    trackEvent,
    roleIdToRoleMap,
    theme.grey.s4,
    roles,
    oldRoleForMixpanel,
    updateUserRole,
    renderTeamsList,
    resendInvite,
    cancelUserInvite,
    showDeleteUserModal,
  ])

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: (newSorting) => {
      trackEvent({
        event: 'users-sort',
        eventProperties: {
          indexPageName: 'User Management',
        },
      })
      setSorting(newSorting)
    },
    state: {
      sorting,
      columnFilters,
    },
  })

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex justify-between'>
        <div className='flex items-center gap-2'>
          <Input2
            leadingIcon='search'
            className='w-[200px]'
            placeholder='Search users...'
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
              trackEvent({
                event: 'users-search',
                eventProperties: {
                  indexPageName: 'User Management',
                },
              })
            }}
          />
        </div>
      </div>

      <DataTable
        isLoading={false}
        table={table}
        tableWrapperClassName={'rounded-lg border border-grey-s2'}
        rowClassNames={(row) => {
          return (row.original as any).active ? undefined : 'opacity-60'
        }}
      />

      <EditUserModal
        teams={teams as TeamWithMembers[]}
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
      <AlertDialog open={cancellingInvite}>
        <AlertDialogContent className={'min-h-[500px]'}>
          <VLoaderAnimation />
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default UsersList
