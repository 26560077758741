import { forwardRef, useEffect, useMemo, useState } from 'react'
import { Input2, InputProps2 } from './input'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { HexColorPicker } from 'react-colorful'

export type ColorPickerProps = Omit<InputProps2, 'trailingIcon' | 'leadingIcon'> & {
  value?: string
  onColorChange?: (color: string) => void
}

const validHexCode = (hex: string) => /^#[0-9A-F]{6}$/i.test(hex)

const InputColorPicker = forwardRef<HTMLInputElement, ColorPickerProps>(
  ({ value, onColorChange, disabled, ...props }, ref) => {
    const [internalValue, setInternalValue] = useState<string | undefined>(value)

    useEffect(() => {
      setInternalValue(value)
    }, [value])

    const isValidColor = useMemo(
      () => internalValue && validHexCode(internalValue),
      [internalValue],
    )

    const validColorOrBlack = useMemo(
      () => (isValidColor ? internalValue : '#000000'),
      [internalValue, isValidColor],
    )

    return (
      <Input2
        disabled={disabled}
        minLength={7}
        maxLength={7}
        value={internalValue}
        leadingIcon={'color-palette'}
        variant={isValidColor ? 'default' : 'error'}
        onChange={(e) => setInternalValue(e.target.value)}
        ref={ref}
        onBlur={() => {
          if (internalValue && validHexCode(internalValue)) {
            onColorChange?.(internalValue)
          }
        }}
        {...props}
      >
        <Popover
          modal
          onOpenChange={(open) => {
            if (!open && internalValue && validHexCode(internalValue)) {
              onColorChange?.(internalValue)
            }
          }}
        >
          <PopoverTrigger
            asChild
            onClick={(e) => {
              e.stopPropagation() // stops firing the onBlur event on the input
            }}
          >
            {!disabled && (
              <div
                className={
                  'cursor-pointer w-8 h-[calc(100%+1rem)] rounded-r-md -mr-3 border-l-[1px] border-l-grey-s2 flex-shrink-0'
                }
                style={{ backgroundColor: validColorOrBlack }}
              ></div>
            )}
          </PopoverTrigger>
          <PopoverContent hideClose className={'p-0 w-56'} align={'end'} side={'bottom'}>
            <HexColorPicker
              color={validColorOrBlack}
              onChange={(color) => {
                setInternalValue(color.toUpperCase())
              }}
              className={'[&.react-colorful]:w-auto [&.react-colorful]:border-none'}
            />
          </PopoverContent>
        </Popover>
      </Input2>
    )
  },
)

InputColorPicker.displayName = 'InputColorPicker'

export { InputColorPicker }
