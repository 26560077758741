import { useAuthState } from '@/auth/auth'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { OnboardTenantInput, ValuecaseAuthState } from '@valuecase/common'
import { Button2, useBreakpoints, useNotifications } from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTemplateGeneration } from '../templates/TemplateCreator/TemplateGeneration/context/TemplateGenerationContext'
import {
  FlowStep,
  FullScreenFlow,
} from '../templates/TemplateCreator/TemplateGeneration/FullScreenFlow'
import {
  MobileTemplateGenerationUseCaseStage,
  MobileTemplateGenerationPromptSelectStage,
  TemplateGenerationPromptStage,
  MobileTemplateGenerationPromptChangeStage,
} from '../templates/TemplateCreator/TemplateGeneration/stages/TemplateGenerationPromptStage'
import { useCurrentTenantQuery } from '../tenant/hooks/useReadTenant'
import CreateTemplateAndSpaceLoadingStep from './steps/CreateTemplateAndSpaceLoadingStep'
import { SpaceCreationStep } from './steps/SpaceCreationStep'
import TenantSetupStep from './steps/TenantSetupStep'
import { TSelfSignUp } from './types/TSelfSignUp'
import IntercomComponent from '../navigation/Intercom/Intercom'
import { useOnboardTenant } from '../tenant/hooks/useOnboardTenant'
import { GoToDesktopStep } from './steps/GoToDesktopStep'
import { WebinarSignupStep } from './steps/WebinarSignupStep'
import { UseCaseStep } from './steps/UseCaseStep'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { TemplateGalleryStep } from './steps/TemplateGalleryStep'
import { CreateSpaceFromTemplateStep } from './steps/CreateSpaceFromTemplateStep'
import { SpaceInput } from '../spaces/hooks/types'
import { useCreateSpace } from '../spaces/hooks/useCreateSpace'
import { useReadPublicTemplates } from '../templates/hooks/useReadPublicTemplates'
import { useCreateTemplate } from '../templates/hooks/useCreateTemplate'

export const OnboardingFlow = ({ isOpen }: { isOpen: boolean }) => {
  const { stageTemplateRequest, startTemplateGeneration } = useTemplateGeneration()
  const authState = useAuthState() as ValuecaseAuthState
  const [currentStep, setCurrentStep] = useState(0)
  const { tenant } = useCurrentTenantQuery()
  const tenantData = useMemo(() => tenant?.payload, [tenant?.payload])
  const { isMobile } = useBreakpoints()
  const { success } = useNotifications()

  const { createSpaceAsync } = useCreateSpace()
  const { templates: publicTemplates } = useReadPublicTemplates()
  const { createTemplateAsync } = useCreateTemplate()

  const { flags } = useLDflags()
  const useTemplateGallery = useMemo(() => flags && flags['self-sign-up-template-gallery'], [flags])

  const handleBack = useCallback(() => {
    setCurrentStep((prev) => Math.max(0, prev - 1))
  }, [])

  const handleNext = useCallback(() => {
    setCurrentStep((prev) => prev + 1)
  }, [])

  const handleUpdateForm = useCallback((field: keyof TSelfSignUp, value: string | File) => {
    setForm((prev) => ({ ...prev, [field]: value }))
  }, [])

  const [templateFromGalleryCreationPromise, setTemplateFromGalleryCreationPromise] =
    useState<Promise<any> | null>(null)

  const shouldUseMobileSteps = useMemo(() => {
    return isMobile
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty dependency array ensures this only runs once on mount

  const [form, setForm] = useState<TSelfSignUp>({
    useCase: 'onboarding',
    theme: tenantData?.theme ?? 'light',
    context: '',

    // Tenant fields
    logoImage: tenantData?.logoImage,
    websiteUrl: tenantData?.websiteUrl ?? '',
    tenantName: tenantData?.tenantName ?? '',
    primaryColor: tenantData?.primaryColor ?? '#B98DDB',
    backgroundImage: tenantData?.backgroundImage,

    //SPACE FIELDS
    companyName: '',
    contactFirstName: '',
    contactLastName: '',
    contactWebsiteUrl: '',
    // Space Logo to render
    logoBlob: null,
    // Space Logo to upload
    spaceLogo: '',
    isWebinarSignup: false,
  })

  const { onboardTenantAsync, onboardTenant } = useOnboardTenant()

  const userId = useMemo(() => (authState.authorized ? authState.sub : undefined), [authState])

  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    trackEvent({
      event: 'self_sign_up-start',
      eventProperties: {
        includePageProperties: true,
        includeEventSource: true,
        userId,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tenantSettingsInputsSatisfied = useMemo(() => {
    return form.websiteUrl.length > 0 && form.tenantName.length > 0 && form.primaryColor.length > 0
  }, [form])

  const spaceCreationInputsSatisfied = useMemo(() => {
    return (
      form.companyName.length > 2 &&
      form.companyName.length < 255 &&
      form.contactFirstName.length > 2 &&
      form.contactFirstName.length < 255 &&
      form.contactLastName.length > 2 &&
      form.contactLastName.length < 255
    )
  }, [form])

  const templateGenerationInputsSatisfied = useMemo(() => {
    return form.context.length > 0 && form.useCase.length > 0
  }, [form])

  const handleOnboardTenant = useCallback(async () => {
    const payload: OnboardTenantInput = {
      websiteUrl: form.websiteUrl,
      tenantName: form.tenantName,
      primaryColor: form.primaryColor,
    }

    if (form.logoImage) {
      payload.logoImage = form.logoImage
    }

    if (form.backgroundImage) {
      payload.backgroundImage = form.backgroundImage
    }

    await onboardTenantAsync(payload)

    trackEvent({
      event: 'self_sign_up-ai_template-visit',
      eventProperties: {
        includePageProperties: true,
        includeEventSource: true,
        userId,
      },
    })
    setCurrentStep((prev) => prev + 1)
  }, [form, onboardTenantAsync, userId, trackEvent])

  const handleWebinarSelect = useCallback(
    async (field: keyof TSelfSignUp, value: boolean) => {
      setForm((prev) => ({ ...prev, [field]: value }))
      handleNext()

      trackEvent({
        event: 'self_sign_up-webinar_signup',
        eventProperties: {
          signup: value,
          userId,
        },
      })

      if (value && authState.authorized) {
        try {
          // Hook created by Lennart to track the user's email for the webinar signup
          await fetch('https://hook.eu1.make.com/au2e0ua9d4h6ujff2lhqmpgwfvytxfap', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: authState.email,
            }),
          })

          success('Sign-up successful! Check your inbox for the next steps!', {
            position: 'top-center',
          })
        } catch (error) {
          console.error('Failed to sign up for webinar:', error)
        }
      }
    },
    [handleNext, success, trackEvent, userId, authState],
  )

  const trackAIPromptSelect = useCallback(
    (context: string) => {
      trackEvent({
        event: 'self_sign_up-ai_template-example_prompt',
        eventProperties: {
          userId,
          useCase: context,
        },
      })
    },
    [userId, trackEvent],
  )

  const trackAIUseCaseSelect = useCallback(
    (useCase: string) => {
      trackEvent({
        event: 'self_sign_up-ai_template-select_case',
        eventProperties: {
          'AI template use case': useCase,
          userId,
        },
      })
    },
    [userId, trackEvent],
  )

  const trackAIPromptChange = useCallback(
    (context: string) => {
      trackEvent({
        event: 'self_sign_up-ai_template-write_prompt',
        eventProperties: {
          context: context,
          userId,
        },
      })
    },
    [userId, trackEvent],
  )

  const copyTemplateFromGallery = useCallback(
    async (templateId: string | null) => {
      // if no template is found, an empty Template will be created
      const sourceTemplate = publicTemplates?.find((template) => template.id === templateId)

      const templateData = {
        title: sourceTemplate?.title ?? 'Starter Template',
        description: sourceTemplate?.description ?? '',
        sourceTemplateId: sourceTemplate?.rootNodeId,
        icon: { source: 'emoji-native' as const, emojiNative: sourceTemplate?.emojiIcon ?? '🗒️' },
      }

      return await createTemplateAsync(templateData)
    },
    [createTemplateAsync, publicTemplates],
  )

  const createSpaceFromCopiedTemplate = useCallback(
    async (templateId: string | null) => {
      try {
        const spaceData: SpaceInput = {
          parentTemplateId: templateId ?? '',
          companyName: form.companyName,
          contactFirstName: form.contactFirstName,
          contactLastName: form.contactLastName,
          websiteUrl: form.contactWebsiteUrl,
        }

        if (form.spaceLogo || form.spaceLogo === null) {
          spaceData.logo = form.spaceLogo
        }

        return await createSpaceAsync(spaceData)
      } catch (error) {
        return null
      }
    },
    [
      createSpaceAsync,
      form.companyName,
      form.contactFirstName,
      form.contactLastName,
      form.contactWebsiteUrl,
      form.spaceLogo,
    ],
  )

  const startDesktopAITemplateGeneration = useCallback(async () => {
    try {
      // Initiating template generation is a two step process hence we call both in sequence.
      const generationRequest = await stageTemplateRequest({
        data: {
          useCase: form.useCase as 'onboarding' | 'sales' | 'other',
          context: form.context,
        },
        skipPreview: true,
        fallbackEnabled: true,
      })
      await startTemplateGeneration(generationRequest.id)
      trackEvent({
        event: 'self_sign_up-space_creation-visit',
        eventProperties: {
          includePageProperties: true,
          includeEventSource: true,
          userId,
        },
      })
      setCurrentStep((prev) => prev + 1)
    } catch (error) {
      console.error(error)
    }
  }, [
    form.context,
    form.useCase,
    stageTemplateRequest,
    startTemplateGeneration,
    userId,
    trackEvent,
  ])

  const startMobileAITemplateGeneration = useCallback(async () => {
    const generationRequest = await stageTemplateRequest({
      data: {
        useCase: form.useCase as 'onboarding' | 'sales' | 'other',
        context: form.context,
        spaceCreationProperties: {
          companyName: form.companyName,
          contactFirstName: form.contactFirstName,
          contactLastName: form.contactLastName,
          logo: form.spaceLogo,
        },
      },
      skipPreview: true,
      fallbackEnabled: true,
    })
    await startTemplateGeneration(generationRequest.id)
    trackEvent({
      event: 'self_sign_up-space_creation-visit',
      eventProperties: {
        includePageProperties: true,
        includeEventSource: true,
        userId,
      },
    })
    onboardTenant({
      isOnboarded: true,
    })
  }, [
    form.companyName,
    form.contactFirstName,
    form.contactLastName,
    form.context,
    form.spaceLogo,
    form.useCase,
    onboardTenant,
    stageTemplateRequest,
    startTemplateGeneration,
    trackEvent,
    userId,
  ])

  const trackCreateSpaceAndNext = useCallback(() => {
    trackEvent({
      event: 'self_sign_up-space_creation-complete',
      eventProperties: {
        userId,
      },
    })
    handleNext()
  }, [trackEvent, userId, handleNext])

  const templateGallerySteps = useMemo(() => {
    return [
      {
        id: 'use-case-selection',
        icon: 'crop',
        component: UseCaseStep,
        title: 'Select Your Use Case',
        titleSubtext:
          "We'll recommend templates tailored to your use case to help you get started quickly.",
        componentProps: {
          onUseCaseSelect: (useCase: string) => {
            trackEvent({
              event: 'self_sign_up-use_case',
              eventProperties: {
                userId,
                useCase,
              },
            })
            handleNext()
          },
        },
      },
      {
        id: 'template-selection',
        icon: 'book',
        component: TemplateGalleryStep,
        title: 'Choose a Template to Get Started',
        titleSubtext:
          'Select a template that fits your goal to create your first custom workspace.',
        componentProps: {
          onTemplateSelect: (templateId: string | null, templateTitle: string) => {
            setTemplateFromGalleryCreationPromise(copyTemplateFromGallery(templateId))
            trackEvent({
              event: 'self_sign_up-template_selected',
              eventProperties: {
                userId,
                selfSignUpTemplate: templateTitle,
              },
            })
            handleNext()
          },
        },
        leftButton: (
          <Button2
            variant={isMobile ? 'outlined-white' : 'ghost'}
            leadingIcon='arrow-back-outline'
            onClick={handleBack}
          >
            Change Use Case
          </Button2>
        ),
      },
    ]
  }, [isMobile, handleBack, trackEvent, userId, handleNext, copyTemplateFromGallery])

  const templateSteps = useMemo<FlowStep[]>(() => {
    if (useTemplateGallery) {
      return templateGallerySteps
    }

    // AI generator steps
    return [
      {
        id: 'template',
        icon: 'c_ai-sparkles',
        component: TemplateGenerationPromptStage,
        componentProps: {
          onPromptSelect: trackAIPromptSelect,
          onUseCaseSelect: trackAIUseCaseSelect,
          onPromptChange: trackAIPromptChange,
        },
        title: 'Create your first Space (with the help of AI)',
        titleSubtext:
          'Give us a few details about your use case or use one of our examples. AI will create the first Valuecase Space for you.',
        leftButton: (
          <Button2 variant='plain' leadingIcon='arrow-back-outline' onClick={handleBack}>
            Back
          </Button2>
        ),
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={startDesktopAITemplateGeneration}
            disabled={!templateGenerationInputsSatisfied}
          >
            Next
          </Button2>
        ),
      },
    ]
  }, [
    handleBack,
    startDesktopAITemplateGeneration,
    trackAIPromptChange,
    trackAIPromptSelect,
    trackAIUseCaseSelect,
    templateGallerySteps,
    templateGenerationInputsSatisfied,
    useTemplateGallery,
  ])

  const steps = useMemo<FlowStep[]>(
    () => [
      {
        id: 'tenant-settings',
        component: TenantSetupStep,
        icon: 'color-wand',
        title: "Welcome to Valuecase! Let's set up your brand.",
        titleSubtext:
          "We'll create your design based on your input below. No worries, you can change everything later.",
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={handleOnboardTenant}
            disabled={!tenantSettingsInputsSatisfied}
          >
            Next
          </Button2>
        ),
      },
      {
        id: 'webinar',
        icon: 'gift',
        component: WebinarSignupStep,
        title: 'Join our free Weekly Live Training',
        titleSubtext:
          'Learn about the full potential of Valuecase with in-depth feature walkthroughs, expert tips, and live Q&A.',
        componentProps: {
          onUpdateForm: handleWebinarSelect,
        },
      },
      ...templateSteps,
      {
        id: 'space',
        icon: 'briefcase',
        component: SpaceCreationStep,
        title: 'Final Step: Which customer is your first Space for?',
        titleSubtext: 'We’ll use this to automatically personalise your Space.',
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={async () => {
              trackCreateSpaceAndNext()

              if (useTemplateGallery) {
                const templateIdOrNull =
                  templateFromGalleryCreationPromise !== null
                    ? (await templateFromGalleryCreationPromise).id
                    : null
                const spaceOrNull = await createSpaceFromCopiedTemplate(templateIdOrNull)

                await onboardTenantAsync({
                  isOnboarded: true,
                  error: spaceOrNull === null,
                })

                if (spaceOrNull !== null) {
                  window.location.href = `/spaces/${spaceOrNull.id}?token=${spaceOrNull.unversionedData.auth.token}`
                } else {
                  window.location.href = `/dashboard/spaces/`
                }
              }
            }}
            disabled={!spaceCreationInputsSatisfied}
          >
            Create Space
          </Button2>
        ),
      },
      ...[
        useTemplateGallery
          ? {
              id: 'creating-space',
              component: CreateSpaceFromTemplateStep,
              takesUpFullScreen: true,
            }
          : {
              id: 'loading',
              component: CreateTemplateAndSpaceLoadingStep,
              takesUpFullScreen: true,
            },
      ],
    ],
    [
      handleOnboardTenant,
      tenantSettingsInputsSatisfied,
      handleWebinarSelect,
      templateSteps,
      spaceCreationInputsSatisfied,
      useTemplateGallery,
      trackCreateSpaceAndNext,
      templateFromGalleryCreationPromise,
      createSpaceFromCopiedTemplate,
      onboardTenantAsync,
    ],
  )

  const mobileTemplateSteps = useMemo<FlowStep[]>(() => {
    if (useTemplateGallery) {
      return templateGallerySteps
    }

    return [
      {
        id: 'template-use-case',
        icon: 'c_ai-sparkles',
        component: MobileTemplateGenerationUseCaseStage,
        componentProps: {
          onUseCaseSelect: trackAIUseCaseSelect,
        },
        title: 'Create your first Space with AI',
        titleSubtext: 'Give us a few details about your use case or use one of our examples.',
        leftButton: (
          <Button2 variant='outlined' leadingIcon='arrow-back-outline' onClick={handleBack}>
            Back
          </Button2>
        ),
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={handleNext}
            disabled={!form.useCase}
          >
            Next
          </Button2>
        ),
      },
      {
        id: 'template-context-selection',
        icon: 'c_ai-sparkles',
        component: MobileTemplateGenerationPromptSelectStage,
        componentProps: {
          onPromptSelect: trackAIPromptSelect,
        },
        title: 'Create your first Space with AI',
        titleSubtext: 'Give us a few details about your use case or use one of our examples.',
        leftButton: (
          <Button2 variant='outlined' leadingIcon='arrow-back-outline' onClick={handleBack}>
            Back
          </Button2>
        ),
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={handleNext}
            disabled={!form.context}
          >
            Next
          </Button2>
        ),
      },
      {
        id: 'template-prompt-change',
        icon: 'c_ai-sparkles',
        component: MobileTemplateGenerationPromptChangeStage,
        componentProps: {
          onPromptChange: trackAIPromptChange,
        },
        title: 'Create your first Space with AI',
        titleSubtext: 'Give us a few details about your use case or use one of our examples.',
        leftButton: (
          <Button2 variant='outlined' leadingIcon='arrow-back-outline' onClick={handleBack}>
            Back
          </Button2>
        ),
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={handleNext}
            disabled={!form.context}
          >
            Next
          </Button2>
        ),
      },
    ]
  }, [
    form.context,
    form.useCase,
    handleBack,
    handleNext,
    trackAIPromptChange,
    trackAIPromptSelect,
    trackAIUseCaseSelect,
    templateGallerySteps,
    useTemplateGallery,
  ])

  const mobileSteps = useMemo<FlowStep[]>(
    () => [
      {
        id: 'tenant-settings',
        component: TenantSetupStep,
        icon: 'color-wand',
        title: "Welcome to Valuecase! Let's set up your brand.",
        titleSubtext:
          "We'll create your design based on your input below. No worries, you can change everything later.",
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={handleOnboardTenant}
            disabled={!tenantSettingsInputsSatisfied}
          >
            Next
          </Button2>
        ),
      },
      {
        id: 'webinar',
        icon: 'gift',
        component: WebinarSignupStep,
        title: 'Join our free Weekly Live Training',
        titleSubtext:
          'Learn about the full potential of Valuecase with in-depth feature walkthroughs, expert tips, and live Q&A.',
        componentProps: {
          onUpdateForm: handleWebinarSelect,
        },
      },
      ...mobileTemplateSteps,
      {
        id: 'space',
        icon: 'briefcase',
        component: SpaceCreationStep,
        title: 'Which customer is your first Space for?',
        titleSubtext: 'We’ll use this to automatically personalise your Space.',
        rightButton: (
          <Button2
            trailingIcon='arrow-forward-outline'
            onClick={async () => {
              if (useTemplateGallery) {
                trackCreateSpaceAndNext()
                await onboardTenantAsync({
                  isOnboarded: true,
                })

                const templateIdOrNull =
                  templateFromGalleryCreationPromise !== null
                    ? (await templateFromGalleryCreationPromise).id
                    : null

                // no await here, we don't need to wait for the space to be created
                createSpaceFromCopiedTemplate(templateIdOrNull)
                handleNext()
              } else {
                await startMobileAITemplateGeneration()
                trackCreateSpaceAndNext()
              }
            }}
            disabled={!spaceCreationInputsSatisfied}
          >
            Create Space
          </Button2>
        ),
      },
      ...(useTemplateGallery
        ? [
            {
              id: 'creating-space',
              component: CreateSpaceFromTemplateStep,
              takesUpFullScreen: true,
            },
          ]
        : []),
      {
        id: 'go-to-desktop',
        component: GoToDesktopStep,
      },
    ],
    [
      handleOnboardTenant,
      tenantSettingsInputsSatisfied,
      handleWebinarSelect,
      mobileTemplateSteps,
      spaceCreationInputsSatisfied,
      useTemplateGallery,
      trackCreateSpaceAndNext,
      onboardTenantAsync,
      templateFromGalleryCreationPromise,
      createSpaceFromCopiedTemplate,
      handleNext,
      startMobileAITemplateGeneration,
    ],
  )

  return (
    <>
      <FullScreenFlow
        isOpen={isOpen}
        currentStep={currentStep}
        steps={shouldUseMobileSteps ? mobileSteps : steps}
        componentProps={{
          form,
          onUpdateForm: handleUpdateForm,
        }}
        className='bg-gradient-to-b from-background to-blue-s2'
      />

      {/* Intercom component - Re-added because Onboarding flow is outside of the context of routes and so on where it usually lives */}
      <IntercomComponent hideDefaultLauncher={false} />
    </>
  )
}
