import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { SpaceOwnerFilterDropdown } from '@/modules/filtering/SpaceOwnerFilterDropdown'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { Table } from '@tanstack/react-table'
import {
  Badge,
  Button2,
  Command,
  CommandCheckboxItem,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
  FilterCheckboxItem,
  FilterDropdown,
  Input2,
  Highlight,
  cn,
} from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'
import { useAuthState } from '@/auth/auth'
import { useReadTemplates } from '@/modules/templates/hooks/useReadTemplates'
import useReadMyTeams from '@/modules/teams/hooks/useReadMyTeams'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import useReadTeams from '@/modules/teams/hooks/useReadTeams'
import { getCleanSub } from '@valuecase/common'

export const useTeamFilter = () => {
  const teamFilterParams = useSearch({
    from: '/dashboard/spaces',
  })

  const { teamIds, companyTeamAccess, collaboratorAccess, myTeamAccess } = teamFilterParams

  const teamFilterActive = useMemo(
    () => companyTeamAccess || collaboratorAccess || myTeamAccess || (teamIds?.length ?? 0) > 0,
    [collaboratorAccess, companyTeamAccess, myTeamAccess, teamIds?.length],
  )

  const activeFilterCount = useMemo(() => {
    if (companyTeamAccess || collaboratorAccess || myTeamAccess) {
      return 1
    }

    if (teamIds?.length) {
      return teamIds.length
    }

    return 0
  }, [collaboratorAccess, companyTeamAccess, myTeamAccess, teamIds])

  return { ...teamFilterParams, teamFilterActive, activeFilterCount }
}

export const SpaceTeamFilter = () => {
  const {
    teamFilterActive,
    collaboratorAccess,
    companyTeamAccess,
    teamIds,
    myTeamAccess,
    activeFilterCount,
  } = useTeamFilter()

  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')

  const { myTeams } = useReadMyTeams()

  const toggleCompanyTeamAccess = useCallback(() => {
    const shouldSetToActive = !companyTeamAccess

    navigate({
      to: '/spaces',
      search: (prev) => ({
        ...prev,
        companyTeamAccess: shouldSetToActive ? true : undefined,
        collaboratorAccess: undefined,
        myTeamAccess: undefined,
        teamIds: undefined,
      }),
    })
  }, [companyTeamAccess, navigate])

  const toggleCollaboratorAccess = useCallback(() => {
    const shouldSetToActive = !collaboratorAccess

    navigate({
      to: '/spaces',
      search: (prev) => ({
        ...prev,
        collaboratorAccess: shouldSetToActive ? true : undefined,
        companyTeamAccess: undefined,
        myTeamAccess: undefined,
        teamIds: undefined,
      }),
    })
  }, [collaboratorAccess, navigate])

  const toggleMyTeamAccess = useCallback(() => {
    const shouldSetToActive = !myTeamAccess

    navigate({
      to: '/spaces',
      search: (prev) => ({
        ...prev,
        myTeamAccess: shouldSetToActive ? true : undefined,
        collaboratorAccess: undefined,
        companyTeamAccess: undefined,
        teamIds: undefined,
      }),
    })
  }, [myTeamAccess, navigate])

  const toggleTeamId = useCallback(
    (id: string) => {
      const shouldSetToActive = !teamIds?.includes(id)

      navigate({
        to: '/spaces',
        search: (prev) => ({
          ...prev,
          teamIds: shouldSetToActive
            ? [...(teamIds ?? []), id]
            : teamIds?.filter((teamId) => teamId !== id),
          collaboratorAccess: undefined,
          companyTeamAccess: undefined,
          myTeamAccess: undefined,
        }),
      })
    },
    [teamIds, navigate],
  )

  const toggleItem = useCallback(
    (id: string) => {
      const shouldSetToActive = !teamIds?.includes(id)

      navigate({
        to: '/spaces',
        search: (prev) => ({
          ...prev,
          teamIds: shouldSetToActive
            ? [...(teamIds ?? []), id]
            : teamIds?.filter((teamId) => teamId !== id),
          collaboratorAccess: undefined,
          companyTeamAccess: undefined,
          myTeamAccess: undefined,
        }),
      })
    },
    [teamIds, navigate],
  )

  const label = useMemo(() => {
    if (activeFilterCount === 0) {
      return 'All Teams'
    }

    return (
      <span>
        Teams
        <Badge variant={'solid'} className='ml-2'>
          {activeFilterCount}
        </Badge>
      </span>
    )
  }, [activeFilterCount])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownMenuButton
          className={cn({
            'border-primary-s5 text-primary-s5 font-semibold': teamFilterActive,
          })}
          trailingIconClassName={cn({ 'text-primary-s5': teamFilterActive })}
        >
          {label}
        </DropdownMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className={'min-w-[284px]'} align='start'>
        <Command>
          <div className='p-1 flex flex-col gap-2'>
            <CommandInput
              className='mb-0'
              inputSize={'default'}
              value={searchTerm}
              onValueChange={setSearchTerm}
              placeholder={'Search teams...'}
            />
            <Button2
              variant='plain-subtle'
              size={'small'}
              onClick={() =>
                navigate({
                  to: '/spaces',
                  search: (prev) => ({
                    ...prev,
                    teamIds: undefined,
                    collaboratorAccess: undefined,
                    myTeamAccess: undefined,
                    companyTeamAccess: undefined,
                  }),
                })
              }
              leadingIcon='refresh-outline'
              className='w-full'
            >
              Reset and show all items
            </Button2>
          </div>
          <DropdownMenuContentScrollable className={'w-full'}>
            <CommandList>
              <CommandEmpty>
                <EmptyState icon={'telescope'} iconSize={'small'} className={'my-4'}>
                  <EmptyStateHeader>
                    <EmptyStateTitle>Nothing here!</EmptyStateTitle>
                    <EmptyStateDescription>
                      You may want to check for typos, try another search
                    </EmptyStateDescription>
                  </EmptyStateHeader>
                </EmptyState>
              </CommandEmpty>

              <CommandCheckboxItem
                selected={!!myTeamAccess}
                onSelect={toggleMyTeamAccess}
                className={'text-sm mt-1'}
              >
                <Highlight searchTerm={searchTerm}>My Teams</Highlight>
              </CommandCheckboxItem>

              <CommandCheckboxItem
                selected={!!collaboratorAccess}
                onSelect={toggleCollaboratorAccess}
                className={'text-sm mt-1'}
              >
                <Highlight searchTerm={searchTerm}>Other Teams (Collaborator access)</Highlight>
              </CommandCheckboxItem>

              <CommandCheckboxItem
                selected={!!companyTeamAccess}
                onSelect={toggleCompanyTeamAccess}
                className={'text-sm mt-1'}
              >
                <Highlight searchTerm={searchTerm}>Everyone in Valuecase</Highlight>
              </CommandCheckboxItem>

              <CommandGroup heading='My Teams' className='border-t border-grey-s2 mt-2 py-1'>
                {myTeams?.map((team) => (
                  <CommandCheckboxItem
                    key={team.id}
                    selected={teamIds?.includes(team.id) ?? false}
                    onSelect={() => toggleTeamId(team.id)}
                    className={'text-sm mt-1'}
                  >
                    <Highlight searchTerm={searchTerm}>{team.name}</Highlight>
                  </CommandCheckboxItem>
                ))}
              </CommandGroup>
            </CommandList>
          </DropdownMenuContentScrollable>
        </Command>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const useSpacesFilterReset = ({
  searchValue,
  setSearchValue,
}: {
  searchValue: string
  setSearchValue: (value: string) => void
}) => {
  const navigate = useNavigate()
  const auth = useAuthState()
  const userId = useMemo(() => (auth.authorized ? getCleanSub(auth.sub) : null), [auth])
  const { teamFilterActive } = useTeamFilter()

  const { ownerIds, spaceType, mapStatus, templateIds } = useSearch({ from: '/dashboard/spaces' })

  const selectedOwners = useMemo(() => (ownerIds ? new Set(ownerIds) : undefined), [ownerIds])

  const resetFilters = useCallback(() => {
    navigate({
      to: '/spaces',
      search: (prev) => ({
        ...prev,
        collaboratorAccess: undefined,
        companyTeamAccess: undefined,
        myTeamAccess: undefined,
        teamIds: undefined,
        ownerIds: userId ? [userId] : undefined,
        spaceName: undefined,
        spaceType: undefined,
        mapStatus: undefined,
        templateIds: undefined,
      }),
    })
    setSearchValue('')
  }, [navigate, setSearchValue, userId])

  const showFilterResetButton = useMemo(() => {
    const selectedOwnersContainOther = [...(selectedOwners ?? [])].some((id) => id !== userId)

    return (
      searchValue.length > 0 ||
      teamFilterActive ||
      selectedOwnersContainOther ||
      !selectedOwners ||
      spaceType !== 'active' ||
      mapStatus ||
      templateIds
    )
  }, [
    mapStatus,
    searchValue.length,
    selectedOwners,
    spaceType,
    teamFilterActive,
    templateIds,
    userId,
  ])

  return { resetFilters, showFilterResetButton, userId }
}

export const SpaceFilter = ({
  table,
  searchValue,
  setSearchValue,
}: {
  table: Table<any>
  searchValue: string
  setSearchValue: (value: string) => void
}) => {
  const { flags } = useLDflags()
  const { trackEvent } = useTrackEvent()
  const { spaceType, ownerIds, mapStatus, templateIds } = useSearch({ from: '/dashboard/spaces' })
  const { teamFilterActive } = useTeamFilter()

  const { resetFilters, showFilterResetButton, userId } = useSpacesFilterReset({
    searchValue,
    setSearchValue,
  })

  const navigate = useNavigate()

  const [localeSearchValue, setLocaleSearchValue] = useState(searchValue)
  useEffect(() => {
    setLocaleSearchValue(searchValue)
  }, [searchValue])

  const columnDescription = useCallback((columnId: string) => {
    if (columnId === 'mapStatus' || columnId === 'mapProgress') {
      return 'of action plan'
    }
  }, [])

  const setSearch = useCallback(
    (search: string) => {
      trackEvent({
        event: 'spaces-search',
        eventProperties: {
          indexPageName: 'Space List',
        },
      })
      navigate({
        to: '/spaces',
        search: (prev) => ({ ...prev, spaceName: search }),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const setSearchDebounced = useDebounceCallback(setSearch, 500)

  const selectedOwners = useMemo(() => (ownerIds ? new Set(ownerIds) : undefined), [ownerIds])
  const ownerFilter = useMemo(() => {
    return (
      <SpaceOwnerFilterDropdown
        labelForNoSelection='All Space Owners'
        selectedIds={selectedOwners}
        setSelectedIds={(ids) => {
          navigate({
            to: '/spaces',
            search: (prev) => ({ ...prev, ownerIds: ids ? [...ids] : undefined }),
          })

          const spacesIncludeOwnSpaces = userId && ids?.has(userId)

          trackEvent({
            event: spacesIncludeOwnSpaces ? 'spaces-filter-own' : 'spaces-filter-team',
            eventProperties: {
              indexPageName: 'Space List',
            },
          })
        }}
      />
    )
  }, [selectedOwners, navigate, userId, trackEvent])

  const selectedMapStatus = useMemo(() => (mapStatus ? new Set(mapStatus) : undefined), [mapStatus])
  const mapStatusFilter = useMemo(() => {
    return (
      <FilterDropdown
        labelForSelection='Action Plan Status'
        labelForNoSelection='Any Action Plan Status'
        selectedIds={selectedMapStatus}
        setSelectedIds={(ids) =>
          navigate({
            to: '/spaces',
            search: (prev) => ({
              ...prev,
              mapStatus: ids ? [...ids] : undefined,
            }),
          })
        }
      >
        <FilterCheckboxItem id={'notCreated'}>
          <div className='w-2 h-2 bg-grey-s4 rounded-full' />
          Not Created
        </FilterCheckboxItem>
        <FilterCheckboxItem id={'notStarted'}>
          <div className='w-2 h-2 bg-grey-s4 rounded-full' />
          Not Started
        </FilterCheckboxItem>
        <FilterCheckboxItem id={'inProgress'}>
          <div className='w-2 h-2 bg-primary-s4 rounded-full' />
          In Progress
        </FilterCheckboxItem>
        <FilterCheckboxItem id={'overdue'}>
          <div className='w-2 h-2 bg-warning-s4 rounded-full' />
          Overdue
        </FilterCheckboxItem>
        <FilterCheckboxItem id={'stalled'}>
          <div className='w-2 h-2 bg-[#FDBA74] rounded-full' />
          Stalled
        </FilterCheckboxItem>
        <FilterCheckboxItem id={'completed'}>
          <div className='w-2 h-2 bg-success-s4 rounded-full' />
          Completed
        </FilterCheckboxItem>
      </FilterDropdown>
    )
  }, [selectedMapStatus, navigate])

  const { templates } = useReadTemplates()
  const selectedTemplates = useMemo(
    () => (templates ? new Set(templateIds) : undefined),
    [templateIds, templates],
  )

  const templateFilter = useMemo(() => {
    return (
      <FilterDropdown
        labelForSelection='Templates'
        labelForNoSelection='All Templates'
        selectedIds={selectedTemplates}
        setSelectedIds={(ids) =>
          navigate({
            to: '/spaces',
            search: (prev) => ({
              ...prev,
              templateIds: ids ? [...ids] : undefined,
            }),
          })
        }
      >
        {templates?.map((template) => (
          <FilterCheckboxItem key={template.id} id={template.id}>
            {template.title}
          </FilterCheckboxItem>
        ))}
      </FilterDropdown>
    )
  }, [selectedTemplates, navigate, templates])

  const { data: teams } = useReadTeams()
  const showTeamFilter = useMemo(
    () => flags && flags['teams-management'] && !!teams?.length,
    [flags, teams],
  )

  const spaceStatusFiltered = useMemo(() => spaceType !== 'active', [spaceType])
  const spaceStatusFilter = useMemo(() => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <DropdownMenuButton
            className={cn({
              'border-primary-s5 text-primary-s5 font-semibold': spaceStatusFiltered,
            })}
            trailingIconClassName={cn({ 'text-primary-s5': spaceStatusFiltered })}
          >
            {spaceType === 'active' && 'Active Spaces'}
            {spaceType === 'archived' && 'Archived Spaces'}
            {spaceType === 'all' && 'All Spaces'}
            {spaceStatusFiltered && (
              <Badge variant={'solid'} className='ml-2'>
                1
              </Badge>
            )}
          </DropdownMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='start'>
          <DropdownMenuRadioGroup
            value={spaceType}
            onValueChange={(value: string) => {
              navigate({
                to: '/spaces',
                search: (prev) => ({ ...prev, spaceType: value as 'active' | 'archived' | 'all' }),
              })
            }}
          >
            <DropdownMenuRadioItem value='active'>Active Spaces</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value='archived'>Archived Spaces</DropdownMenuRadioItem>
            <DropdownMenuRadioItem value='all'>All Spaces</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }, [spaceStatusFiltered, spaceType, navigate])

  return (
    <div className='flex w-full justify-between gap-3'>
      <div className='flex gap-3 items-center'>
        <Input2
          leadingIcon='search'
          className='w-[200px]'
          placeholder='Search'
          value={localeSearchValue}
          onChange={(e) => {
            setLocaleSearchValue(e.target.value)
            setSearchDebounced(e.target.value)
          }}
        />
        {ownerIds && ownerFilter}
        {teamFilterActive && <SpaceTeamFilter />}
        {spaceStatusFiltered && spaceStatusFilter}
        {mapStatus && mapStatusFilter}
        {templateIds && templateFilter}
      </div>

      <div className='flex gap-3'>
        {showFilterResetButton && (
          <Button2 variant={'plain-subtle'} leadingIcon='refresh' onClick={resetFilters}>
            Reset
          </Button2>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button2 variant={'outlined-white'} leadingIcon='options' className='ml-auto'>
              Filters
            </Button2>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-[276px] flex flex-col gap-6 p-4' align='end'>
            {ownerFilter}
            {showTeamFilter && <SpaceTeamFilter />}
            {spaceStatusFilter}
            {mapStatusFilter}
            {templateFilter}
          </DropdownMenuContent>
        </DropdownMenu>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button2 variant={'outlined-white'} leadingIcon='c_table-settings'>
              Table Columns
            </Button2>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end' className='w-[260px] max-h-[450px] overflow-y-auto'>
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}
                    description={columnDescription(column.id)}
                  >
                    {(column.columnDef.meta as any)?.columnFilterLabel ||
                      (typeof column.columnDef.header === 'string'
                        ? column.columnDef.header
                        : column.id)}
                  </DropdownMenuCheckboxItem>
                )
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
