import { useModalControls } from '@valuecase/ui-components'
import { useCallback, useState } from 'react'
import { Navigation } from '../spaceCreationFlow/SpaceCreationNavigation'
import { useTrackEvent } from '../../../../mixpanel/useTrackEvent'
import { VLoaderAnimation } from '../../../../ui-components/VLoader/VLoader'
import { HubSpotCompany, HubSpotContact, HubSpotDeal } from '../../../integrations/hubspot/types'
import { useCreateSpaceIntegrationAttributes } from '../../hooks/useCreateSpaceIntegrationIdMapping'
import { useNotifications } from '../../../../utils/Notifications/Notifications'
import { HubSpotCurrentUser } from '../../../integrations/hubspot/types/HubSpotCurrentUser'
import { SpacesListDto } from '../spacesList/spacesList'
import { HubSpotObjectSelector } from '../../../integrations/hubspot/HubSpotObjectSelector'

/**
 * Shows a list of HubSpot deals and allows the user to select one to link to the given space.
 */
export function SpaceToHubSpotLinker({
  space,
  hubSpotCurrentUser,
}: {
  space: SpacesListDto
  hubSpotCurrentUser: HubSpotCurrentUser
}) {
  const { trackEvent } = useTrackEvent()
  const { success, error } = useNotifications()
  const { close } = useModalControls()
  const [selectedObjectType, setSelectedObjectType] = useState<
    'Deal' | 'Company' | 'Contact' | undefined
  >(undefined)
  const selectedObjectTypeLowerCase = selectedObjectType?.toLowerCase()
  const onSuccess = useCallback(() => {
    success(`Space successfully connected to Hubspot ${selectedObjectTypeLowerCase}`)
    trackEvent({
      event: `space-linked-to-hubspot-${selectedObjectTypeLowerCase}`,
      eventProperties: {
        indexPageName: 'Create Space Modal',
        spaceId: space.id,
      },
    })
    close()
  }, [close, selectedObjectTypeLowerCase, space.id, success, trackEvent])
  const onError = useCallback(() => {
    error(`Error linking space to HubSpot ${selectedObjectTypeLowerCase}`)
    trackEvent({
      event: `space-linked-to-hubspot-deal-${selectedObjectTypeLowerCase}`,
      eventProperties: {
        indexPageName: 'Create Space Modal',
        spaceId: space.id,
      },
    })
    close()
  }, [close, error, selectedObjectTypeLowerCase, space.id, trackEvent])
  const { createSpaceIntegrationIdMapping, isLoading, isError, isSuccess } =
    useCreateSpaceIntegrationAttributes({
      onSuccess,
      onError,
    })

  const [selectedHubSpotObject, setSelectedHubSpotObject] = useState<
    HubSpotDeal | HubSpotCompany | HubSpotContact | undefined
  >(undefined)
  const handleUpdateSpace = useCallback(async () => {
    if (!selectedHubSpotObject || !selectedObjectType) {
      throw new Error('No object selected')
    }
    createSpaceIntegrationIdMapping({
      spaceId: space.id,
      payload: {
        integrationIdMappings: [
          {
            thirdParty: 'HubSpot',
            thirdPartyEntityType: selectedObjectType,
            thirdPartyId: `${hubSpotCurrentUser.hubSpotPortalId}_${selectedHubSpotObject.id}`,
            thirdPartyOrganizationId: hubSpotCurrentUser.hubSpotPortalId,
          },
          {
            thirdParty: 'HubSpot',
            thirdPartyEntityType: 'User',
            thirdPartyId: `${hubSpotCurrentUser.hubSpotPortalId}_${hubSpotCurrentUser.hubSpotUserId}`,
            thirdPartyOrganizationId: hubSpotCurrentUser.hubSpotPortalId,
          },
        ],
      },
    })
  }, [
    createSpaceIntegrationIdMapping,
    hubSpotCurrentUser.hubSpotPortalId,
    hubSpotCurrentUser.hubSpotUserId,
    selectedHubSpotObject,
    selectedObjectType,
    space.id,
  ])

  // Modal closes when request completes, so can retain the spinner for error and success states
  if (isLoading || isError || isSuccess) {
    return (
      <div className='flex justify-center items-center p-[200px]'>
        <VLoaderAnimation />
      </div>
    )
  }

  return (
    <div>
      <div className='flex flex-col items-ce text-center justify-center pb-10 gap-2'>
        <h1 className='text-2xl font-extrabold text-grey-s6 m-0 p-0'>Connect to HubSpot</h1>
        <p className='text-sm font-normal text-grey-s5 m-0 p-0'>
          Select a HubSpot object to link it to your space.
        </p>
      </div>
      <HubSpotObjectSelector
        spaceId={space.id}
        onObjectSelected={(object, objectType) => {
          // TODO make this a single source of truth in one object
          // e.g. param1 = { object: HubSpotDeal | HubSpotCompany | HubSpotContact; objectType: 'Deal' | 'Company' | 'Contact' }

          // Show a warning if the user is about to overwrite their selection from a different object type tab
          if (selectedObjectType !== objectType && selectedHubSpotObject) {
            const res = confirm(
              'You can only select one record at a time, this will unlink your previously selected record. Continue?',
            )
            if (!res) {
              return
            }
          }
          setSelectedHubSpotObject(object)
          setSelectedObjectType(objectType)
        }}
        selectedObject={selectedHubSpotObject}
      />
      <Navigation
        step={1}
        nextButtonLabel={'Link to Deal'}
        maxSteps={1}
        isNextButtonDisabled={!selectedHubSpotObject}
        onNext={handleUpdateSpace}
        showSkipButton={false}
        // Back button is not needed in this one-step flow
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onBack={() => {}}
      />
    </div>
  )
}
