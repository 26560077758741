import { useCallback, useEffect, useMemo, useState } from 'react'
import { TAssetLibraryEntry, TAssetLibraryQuery } from '@valuecase/common'
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table'
import { IonIcon } from '../../Icons/IonIcon'
import { DateTime } from 'luxon'
import { Button2, Button2Props, ButtonLink } from '../../components/ui/button'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
  DataTable,
  Pagination,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input2,
  OneLineTruncatedText,
  Skeleton,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useNotifications,
  DataTablePagination,
  Highlight,
} from '../../components/ui'
import { cn } from '../../lib'
import { useDebounceValue } from 'usehooks-ts'
import {
  EmptyState,
  EmptyStateAction,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
} from '../../components/ui/empty'
import { Loader } from '../../components/legacy'
import { PanelTabsList, PanelTabsTrigger, Tabs } from '../../components/ui/tabs'

import {
  useAssetLibraryFolder,
  useAssetLibraryFolders,
  useAssetLibraryItems,
  useMutateAssetLibraryEntry,
} from './hooks'
import { assetDescription, FileHelper, useDateFormatting } from '../../utils'
import { AssetFolderButton, MoveAssetOrFolderDialog } from './AssetFolder'

const gridStyling = 'grid md:grid-cols-3 xl:grid-cols-4 auto-rows-[max-content] gap-3'

export const AssetTable = ({
  onAddAsset,
  showActions,
  addAssetAllowed,
  onAssetClick,
  className,
  assetTypes,
  mimeTypes,
  gridOverflowScroll,
  disableClickOnEmbeds,
  parentFolderId: paramsParentFolderId,
  onParentFolderIdChange,
}: {
  onAddAsset: () => void
  showActions?: boolean
  addAssetAllowed?: boolean
  onAssetClick?: (asset: TAssetLibraryEntry) => void
  className?: string
  assetTypes?: TAssetLibraryQuery['types']
  mimeTypes?: TAssetLibraryQuery['mimeTypes']
  gridOverflowScroll?: boolean
  disableClickOnEmbeds?: boolean
  parentFolderId?: string | null
  onParentFolderIdChange?: (id: string | null) => void
}) => {
  const [view, setView] = useState<'LIST' | 'GRID'>('GRID')

  const { formatDateTimeShort } = useDateFormatting()

  const pageSize = 12
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  })

  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounceValue(search, 300)

  const [parentFolderId, setParentFolderId] = useState<string | null>(null)

  useEffect(() => {
    setParentFolderId(paramsParentFolderId ?? null)
  }, [paramsParentFolderId])

  const { data: folders } = useAssetLibraryFolders({
    parentFolderId,
  })

  const { data: currentFolder } = useAssetLibraryFolder(parentFolderId ?? undefined)

  const { items, totalPages, itemsExist, isLoading } = useAssetLibraryItems({
    page: pagination.pageIndex + 1,
    itemsPerPage: pagination.pageSize,
    search: debouncedSearch || undefined,
    types: assetTypes,
    mimeTypes,
    folderId: debouncedSearch.length > 0 ? undefined : parentFolderId, // search works globally
  })

  // If debouncedSearch or folder changes, reset the pagination to the first page
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }))
  }, [debouncedSearch, parentFolderId])

  const itemsOrFoldersExists = useMemo(
    () => itemsExist || (folders?.length ?? 0) > 0,
    [itemsExist, folders],
  )

  const assetThumbnail = useCallback((asset: TAssetLibraryEntry['asset']) => {
    if (asset.type === 'EMBED') {
      return <IonIcon name={'code-slash-outline'} className={'w-6 h-6 text-grey-s3'} />
    }

    if (!asset.data.thumbnail_s3ObjectUrl) {
      if (asset.type === 'EMBEDDABLE_LINK' || asset.type === 'LINK') {
        return <IonIcon name={'link'} className={'w-6 h-6 text-grey-s3'}></IonIcon>
      }
      return <IonIcon name={'document'} className={'w-6 h-6 text-grey-s3'}></IonIcon>
    }

    if (asset.type === 'EMBEDDABLE_LINK') {
      return (
        <img
          className={'object-cover w-full h-full'}
          src={asset.data.thumbnail_s3ObjectUrl}
          alt={asset.data.name}
        />
      )
    }

    if (asset.type === 'LINK') {
      return (
        <img
          className={'object-cover w-full h-full'}
          src={asset.data.thumbnail_s3ObjectUrl}
          alt={asset.data.linkName}
        />
      )
    }

    if (asset.type === 'FILE' && asset.data.thumbnail_s3ObjectUrl) {
      return (
        <img
          className={'object-cover w-full h-full'}
          src={asset.data.thumbnail_s3ObjectUrl}
          alt={asset.data.fileName}
        />
      )
    }

    return <IonIcon name={'document'} className={'w-6 h-6 text-grey-s3'} />
  }, [])

  const columns = useMemo<ColumnDef<TAssetLibraryEntry>[]>(
    () => [
      {
        id: 'name',
        header: 'Name',
        size: 600,
        cell: (ctx) => {
          const { asset, name } = ctx.row.original

          return (
            <div className={'flex items-center gap-3'}>
              <div
                className={cn(
                  'w-10 h-10 flex-shrink-0 rounded-lg border border-solid border-grey-s2 overflow-hidden',
                  'bg-primary-s1 flex justify-center items-center',
                )}
              >
                {assetThumbnail(asset)}
              </div>
              <div className={'flex flex-col gap-1'}>
                <Highlight searchTerm={debouncedSearch}>
                  <p
                    className={
                      'text-sm font-semibold max-h-[3em] line-clamp-2 overflow-hidden overflow-ellipsis'
                    }
                  >
                    {ctx.row.original.name}
                  </p>
                </Highlight>
                {ctx.row.original.description && (
                  <OneLineTruncatedText className={'text-xs text-grey-s5'}>
                    {ctx.row.original.description}
                  </OneLineTruncatedText>
                )}
              </div>
            </div>
          )
        },
      },
      {
        id: 'type',
        header: 'Type',
        size: 80,
        cell: (ctx) => assetDescription(ctx.row.original.asset),
      },
      {
        id: 'createdBy',
        header: 'Created by',
        cell: ({
          row: {
            original: { createdBy },
          },
        }) => {
          return (
            <span className={'text-xs'}>
              {createdBy.firstName} {createdBy.lastName}
            </span>
          )
        },
      },
      {
        id: 'lastModified',
        header: 'Last modified',
        cell: (ctx) => {
          if (!ctx.row.original.updatedAt) {
            return null
          }
          return formatDateTimeShort(DateTime.fromJSDate(ctx.row.original.updatedAt))
        },
      },
      {
        id: 'actions',
        size: 100,
        cell: (ctx) => {
          const { asset } = ctx.row.original

          return (
            <div
              className={'flex gap-2 items-center'}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {asset.type === 'FILE' && (
                <ButtonLink
                  variant={'plain'}
                  leadingIcon={'open-outline'}
                  size={'small'}
                  href={asset.data.s3ObjectUrl}
                  target={'_blank'}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              )}
              {(asset.type === 'LINK' ||
                asset.type === 'EMBEDDABLE_LINK' ||
                (asset.type === 'EMBED' && asset.data.embedType !== 'html')) && (
                <ButtonLink
                  variant={'plain'}
                  leadingIcon={'open-outline'}
                  size={'small'}
                  href={
                    asset.type === 'LINK' || asset.type === 'EMBEDDABLE_LINK'
                      ? asset.data.url
                      : asset.data.payload
                  }
                  target={'_blank'}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              )}
              {asset.type === 'EMBED' && asset.data.embedType === 'html' && (
                <Tooltip>
                  <TooltipTrigger>
                    <Button2
                      variant={'plain'}
                      leadingIcon={'open-outline'}
                      size={'small'}
                      disabled={true}
                    />
                  </TooltipTrigger>
                  <TooltipContent>HTML embeds can not be previewed</TooltipContent>
                </Tooltip>
              )}
              {showActions && (
                <AssetActionsDropdown triggerButtonSize={'small'} entry={ctx.row.original} />
              )}
            </div>
          )
        },
      },
    ],
    [assetThumbnail, debouncedSearch, formatDateTimeShort, showActions],
  )

  const assetTable = useReactTable<(typeof items)[number]>({
    columns,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    state: {
      pagination,
    },
    onPaginationChange: (state) => {
      if (typeof state === 'function') {
        const newState = state(pagination)
        setPagination(newState)
      }
    },
    manualSorting: true,
    manualPagination: true,
    pageCount: totalPages,
  })

  if (!itemsOrFoldersExists && isLoading) {
    return (
      <div className={'w-full h-full flex justify-center items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      {!itemsOrFoldersExists && parentFolderId === null ? (
        <div className={cn('h-full flex items-center justify-center', className)}>
          <EmptyState icon={'file-tray-outline'} iconSize={'large'}>
            <EmptyStateHeader>
              <EmptyStateTitle> No assets uploaded to your library yet.</EmptyStateTitle>
              <EmptyStateDescription>
                Add files and embeds to the library to make them available to everyone in your
                company.
              </EmptyStateDescription>
            </EmptyStateHeader>
            {addAssetAllowed && <EmptyStateAction onClick={onAddAsset}>Add asset</EmptyStateAction>}
          </EmptyState>
        </div>
      ) : (
        <div
          className={cn('flex flex-col gap-4', className, {
            'h-full overflow-hidden': gridOverflowScroll,
          })}
        >
          <div className={'flex justify-between'}>
            <div className={'w-[200px]'}>
              <Input2
                placeholder={'Search...'}
                leadingIcon={'search'}
                value={search}
                onInput={(e) => setSearch(e.currentTarget.value)}
              />
            </div>
            <Tabs value={view} onValueChange={(value) => setView(value as 'GRID' | 'LIST')}>
              <PanelTabsList>
                <PanelTabsTrigger value={'GRID'} leadingIcon='grid-outline' />
                <PanelTabsTrigger value={'LIST'} leadingIcon='list' />
              </PanelTabsList>
            </Tabs>
          </div>
          <div
            className={cn('flex flex-col gap-4 flex-grow', {
              'overflow-hidden': gridOverflowScroll,
            })}
          >
            <div className={'flex flex-col gap-4 flex-shrink overflow-auto'}>
              {!debouncedSearch && (
                <>
                  {parentFolderId !== null && (
                    <div className={'flex gap-2 items-center'}>
                      <Button2
                        className={'flex-shrink-0'}
                        size={'small'}
                        variant={'outlined'}
                        leadingIcon={'folder-open'}
                        onClick={() => {
                          if (onParentFolderIdChange) {
                            onParentFolderIdChange(null)
                          } else {
                            setParentFolderId(null)
                          }
                        }}
                      >
                        Library
                      </Button2>
                      {currentFolder && (
                        <OneLineTruncatedText>
                          <p className={'text-lg font-bold'}>
                            <span className={'font-light'}>/ </span> {currentFolder.name}
                          </p>
                        </OneLineTruncatedText>
                      )}
                    </div>
                  )}

                  {!!folders && folders.length > 0 && (
                    <>
                      <h3 className={'font-bold text-sm'}>Folders</h3>
                      <div className={cn(gridStyling)}>
                        {folders.map((f) => (
                          <AssetFolderButton
                            folder={f}
                            showActions={showActions}
                            key={f.id}
                            onClick={() => {
                              if (onParentFolderIdChange) {
                                // we use external state management
                                onParentFolderIdChange(f.id)
                              } else {
                                setParentFolderId(f.id)
                              }
                            }}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}

              {items.length > 0 && <h3 className={'font-bold text-sm'}>Files</h3>}

              {items.length === 0 && !isLoading && !debouncedSearch && (
                <div className={cn('h-full flex items-center justify-center', className)}>
                  <EmptyState icon={'file-tray'}>
                    <EmptyStateHeader>
                      <EmptyStateDescription>No assets in this folder yet.</EmptyStateDescription>
                    </EmptyStateHeader>
                    {addAssetAllowed && (
                      <EmptyStateAction leadingIcon={'cloud-upload'} onClick={onAddAsset}>
                        Add assets
                      </EmptyStateAction>
                    )}
                  </EmptyState>
                </div>
              )}

              {view === 'LIST' && (items.length > 0 || debouncedSearch) && (
                <DataTable
                  className={'flex flex-col flex-shrink-0 flex-grow'}
                  rowClickHandler={onAssetClick ? (row) => onAssetClick(row.original) : undefined}
                  isLoading={isLoading}
                  table={assetTable}
                  tableWrapperClassName={'rounded-lg border border-grey-s2'}
                  resetFiltersClickHandler={() => {
                    setSearch('')
                  }}
                  hidePagination
                />
              )}
              {view === 'GRID' && (
                <>
                  {items.length === 0 && debouncedSearch && !isLoading && (
                    <div className={'w-full h-full flex justify-center items-center'}>
                      <EmptyState icon={'search'} iconSize={'small'}>
                        <EmptyStateHeader>
                          <EmptyStateTitle>No matches found!</EmptyStateTitle>
                          <EmptyStateDescription>
                            Try changing or clearing out your filters.
                          </EmptyStateDescription>
                        </EmptyStateHeader>
                        <EmptyStateAction onClick={() => setSearch('')}>
                          Reset filter
                        </EmptyStateAction>
                      </EmptyState>
                    </div>
                  )}

                  <div className={cn(gridStyling)}>
                    {isLoading &&
                      Array.from({ length: pageSize }).map((i) => (
                        <Skeleton key={i as number} className={'h-[206px] rounded-lg'}></Skeleton>
                      ))}

                    {items.map((entry) => (
                      <AssetLibraryGridCard
                        key={entry.id}
                        entry={entry}
                        showActions={showActions}
                        onClick={() => {
                          onAssetClick?.(entry)
                        }}
                        disableClickOnEmbeds={disableClickOnEmbeds}
                        searchTerm={debouncedSearch}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>

            {view === 'LIST' && totalPages > 1 && (
              <DataTablePagination className={'mb-1'} table={assetTable} />
            )}

            {view === 'GRID' && totalPages > 1 && (
              <Pagination
                className={'mb-1'}
                goToNextPage={() => {
                  setPagination({
                    ...pagination,
                    pageIndex: pagination.pageIndex + 1,
                  })
                }}
                goToPreviousPage={() => {
                  setPagination({
                    ...pagination,
                    pageIndex: pagination.pageIndex - 1,
                  })
                }}
                activeIndex={pagination.pageIndex}
                pageItems={Array.from({ length: totalPages }, (_, index) => index)}
                onPageItemClick={(pageIndex: number) => {
                  setPagination({
                    ...pagination,
                    pageIndex,
                  })
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

const AssetLibraryGridCard = ({
  entry,
  showActions,
  onClick,
  disableClickOnEmbeds,
  searchTerm,
}: {
  entry: TAssetLibraryEntry
  showActions?: boolean
  onClick?: () => void
  disableClickOnEmbeds?: boolean
  searchTerm: string
}) => {
  const { asset, id, name, updatedAt } = entry
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { formatDateShort } = useDateFormatting()

  // This state is used to hide the action only with a debounce and reduce flickering
  const [debouncedCardHovered, setDebouncedCardHovered] = useDebounceValue(false, 150)
  useEffect(() => {
    if (!dropdownOpen) {
      setDebouncedCardHovered(false)
    }
  }, [dropdownOpen, setDebouncedCardHovered])

  return (
    <div
      className={cn(
        'group flex flex-col bg-white rounded-lg border border-solid border-grey-s2 shadow-sm overflow-hidden',
        'relative',
        {
          'transition-colors cursor-pointer': !(
            asset.type === 'EMBED' &&
            asset.data.embedType === 'html' &&
            disableClickOnEmbeds
          ),
        },
      )}
      onMouseEnter={() => setDebouncedCardHovered(true)}
      onMouseLeave={() => setDebouncedCardHovered(false)}
      onClick={onClick}
    >
      <div
        className={
          'absolute right-3 top-3 text-white bg-[#767880]/80 backdrop-blur text-xxs font-semibold rounded px-1 leading-4'
        }
      >
        {assetDescription(entry.asset)}
      </div>
      {(asset.type === 'FILE' || asset.type === 'LINK' || asset.type === 'EMBEDDABLE_LINK') && (
        <div
          className={cn(
            'w-full h-36 overflow-hidden flex-shrink-0 flex justify-center items-center',
          )}
        >
          {asset.data.thumbnail_s3ObjectUrl ? (
            <img
              className={'object-cover w-full h-full'}
              src={asset.data.thumbnail_s3ObjectUrl}
              alt={name}
            />
          ) : (
            <IonIcon
              name={asset.type === 'FILE' ? 'document' : 'link'}
              className={'text-grey-s3 w-12 h-12'}
            ></IonIcon>
          )}
        </div>
      )}
      {asset.type === 'EMBED' && (
        <div className={'w-full h-36 pt-3 flex items-center justify-center flex-shrink-0'}>
          <IonIcon name={'code-slash-outline'} className={'text-grey-s3 w-12 h-12'}></IonIcon>
        </div>
      )}
      <div className={'h-[52px]'}></div>
      <div
        className={cn(
          'absolute p-3 flex flex-col w-full gap-2',
          'rounded-t-lg bg-white/95 backdrop-blur-lg',
          'shadow-[0px_-1px_4px_rgba(0,0,0,0.1)]',
          '-bottom-2',
          {
            'group-hover:bg-white/90': !(
              asset.type === 'EMBED' &&
              asset.data.embedType === 'html' &&
              disableClickOnEmbeds
            ),
          },
        )}
      >
        <div className={'flex justify-between gap-3 w-full'}>
          <div className={'flex flex-col gap-1 h-full'}>
            <Highlight searchTerm={searchTerm}>
              <OneLineTruncatedText className={'text-xs font-bold'}>{name}</OneLineTruncatedText>
            </Highlight>

            <p className={'text-xs text-grey-s4'}>
              <span className={'align-middle'}>
                <IonIcon
                  name={'cloud-upload-outline'}
                  className={'text-grey-s3 w-3 h3 flex-shrink-0 mt-[2px] mr-1'}
                ></IonIcon>
              </span>
              on {formatDateShort(DateTime.fromJSDate(updatedAt))} by {entry.createdBy.firstName}{' '}
              {entry.createdBy.lastName}
            </p>
          </div>
          {showActions && (
            <div
              className={cn('hidden group-hover:block', {
                block: dropdownOpen || debouncedCardHovered,
              })}
            >
              <AssetActionsDropdown entry={entry} onDropdownOpenChange={setDropdownOpen} />
            </div>
          )}
        </div>
        <div
          className={cn(
            'max-h-0 group-hover:max-h-[112px] transition-[max-height] duration-300',
            'text-xs text-grey-s5 overflow-auto',
            { 'max-h-[112px]': dropdownOpen },
          )}
        >
          <p
            className={cn('pb-2', {
              italic: !entry.description,
            })}
          >
            {entry.description ?? 'No description'}
          </p>
        </div>
      </div>
    </div>
  )
}

const AssetActionsDropdown = ({
  triggerButtonSize,
  entry,
  onDropdownOpenChange,
}: {
  triggerButtonSize?: Button2Props['size']
  entry: TAssetLibraryEntry
  onDropdownOpenChange?: (open: boolean) => void
}) => {
  const { updateAsset, deleteAsset } = useMutateAssetLibraryEntry()
  const { success, error } = useNotifications()

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const [hasOpenDialog, setHasOpenDialog] = useState(false)

  const [nameValue, setNameValue] = useState<string>(entry.name ?? '')
  const [descriptionValue, setDescriptionValue] = useState<string>()

  const { data: folders } = useAssetLibraryFolders({})

  const noFoldersCreated = useMemo(() => {
    return !folders || folders.length === 0
  }, [folders])

  useEffect(() => {
    setDescriptionValue(entry.description ?? undefined)
  }, [entry.description, hasOpenDialog])

  useEffect(() => {
    if (entry.asset.type === 'FILE') {
      setNameValue(FileHelper.fileNameAndExtension(entry.name).fileName)
      return
    }
    setNameValue(entry.name)
  }, [entry.asset.type, entry.name])

  const handleDialogItemOpenChange = useCallback(
    (open: boolean) => {
      setHasOpenDialog(open)
      if (!open) {
        setDropdownMenuOpen(false)
        onDropdownOpenChange?.(false)
      }
    },
    [onDropdownOpenChange],
  )

  const saveAssetChanges = useCallback(async () => {
    try {
      const newAssetData: TAssetLibraryEntry['asset'] = {
        ...entry.asset,
      }

      const newName =
        newAssetData.type === 'FILE' // renaming files without touching extension
          ? FileHelper.maybeRewriteFilenameWithCustomName(entry.name, nameValue)
          : nameValue

      if (newAssetData.type === 'FILE') {
        newAssetData.data.fileName = newName
      } else if (newAssetData.type === 'LINK') {
        newAssetData.data.linkName = newName
      } else if (newAssetData.type === 'EMBEDDABLE_LINK') {
        newAssetData.data.name = newName
      } else {
        newAssetData.data.embedName = newName
      }

      await updateAsset({
        id: entry.id,
        asset: newAssetData,
        name: newName,
        description: (descriptionValue?.length ?? 0) > 0 ? descriptionValue : null,
      })
      success('Asset successfully updated')
    } catch (e) {
      error('Failed to update asset')
    }
  }, [entry.asset, entry.name, entry.id, nameValue, updateAsset, descriptionValue, success, error])

  return (
    <DropdownMenu
      open={dropdownMenuOpen}
      onOpenChange={(open) => {
        setDropdownMenuOpen(open)
        onDropdownOpenChange?.(open)
      }}
      modal={false}
    >
      <DropdownMenuTrigger asChild>
        <Button2
          onClick={(e) => e.stopPropagation()}
          variant={'plain'}
          size={triggerButtonSize}
          leadingIcon={'ellipsis-vertical'}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align={'end'}
        className={cn({
          hidden: hasOpenDialog || !dropdownMenuOpen,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <Dialog onOpenChange={handleDialogItemOpenChange}>
          <DialogTrigger>
            <DropdownMenuItem
              onSelect={(event) => {
                event.preventDefault()
              }}
            >
              <div className={'flex gap-2 items-center'}>
                <IonIcon name={'create-outline'} className={'w-4 h-4 text-grey-s4'} />
                <p>Edit</p>
              </div>
            </DropdownMenuItem>
          </DialogTrigger>

          <DialogContent onClick={(e) => e.stopPropagation()}>
            <DialogHeader>
              <DialogTitle>Edit asset</DialogTitle>
            </DialogHeader>

            <div className={'flex flex-col gap-2'}>
              <label htmlFor={'previewName'} className={'text-grey-s4 text-xs font-semibold'}>
                Preview Name
              </label>
              <Input2
                placeholder={'Preview Name'}
                id={'previewName'}
                name={'previewName'}
                value={nameValue}
                onInput={(e) => {
                  setNameValue(e.currentTarget.value)
                }}
              />
            </div>

            <div className={'flex flex-col gap-2'}>
              <label
                htmlFor={'previewDescription'}
                className={'text-grey-s4 text-xs font-semibold'}
              >
                Description
              </label>
              <Textarea
                id={'previewDescription'}
                name={'previewDescription'}
                maxLength={250}
                className={'h-20 resize-none'}
                value={descriptionValue}
                onInput={(e) => {
                  setDescriptionValue(e.currentTarget.value)
                }}
              />
              <span className={'text-xxs text-grey-s4'}>Max. 250 characters</span>
            </div>

            <DialogFooter className='flex items-center justify-end'>
              <DialogClose asChild>
                <Button2 variant={'plain'}>Cancel</Button2>
              </DialogClose>
              <DialogClose asChild>
                <Button2 leadingIcon={'checkmark-outline'} type='submit' onClick={saveAssetChanges}>
                  Save changes
                </Button2>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <MoveAssetOrFolderDialog
          onNewFolderSelected={async (folderId) => {
            try {
              await updateAsset({
                id: entry.id,
                asset: entry.asset,
                name: entry.name,
                description: entry.description,
                folderId: folderId,
              })
              success('Asset successfully moved')
            } catch (e) {
              console.error(e)
              error('Failed to move asset')
            }
          }}
          onOpenChange={handleDialogItemOpenChange}
          folderOrAsset={'asset'}
          currentFolderId={entry.folderId ?? null}
          disabled={noFoldersCreated}
        >
          <Tooltip open={noFoldersCreated ? undefined : false}>
            <TooltipTrigger className={'w-full'}>
              <DropdownMenuItem
                disabled={noFoldersCreated}
                onSelect={(event) => {
                  event.preventDefault()
                }}
              >
                <div className={'flex gap-2 items-center'}>
                  <IonIcon name={'return-up-forward'} className={'w-4 h-4 text-grey-s4'} />
                  Move
                </div>
              </DropdownMenuItem>
            </TooltipTrigger>
            <TooltipContent>Disabled - No folders created yet</TooltipContent>
          </Tooltip>
        </MoveAssetOrFolderDialog>

        <AlertDialog onOpenChange={handleDialogItemOpenChange}>
          <AlertDialogTrigger>
            <DropdownMenuItem
              onSelect={(event) => {
                event.preventDefault()
              }}
            >
              <div className={'flex gap-2 items-center'}>
                <IonIcon name={'trash-outline'} className={'w-4 h-4 text-warning-s4'} />
                <p className={'text-warning-s5'}>Delete</p>
              </div>
            </DropdownMenuItem>
          </AlertDialogTrigger>
          <AlertDialogContent onClick={(e) => e.stopPropagation()}>
            <AlertDialogHeader iconName='trash'>Delete asset?</AlertDialogHeader>
            <AlertDialogDescription>
              Are you sure you want to delete this asset? Please note that if this asset is used in
              any space, it will not be removed from there.
            </AlertDialogDescription>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={async () => {
                  try {
                    await deleteAsset(entry.id)
                    success('Asset successfully deleted')
                  } catch (e) {
                    error('Failed to delete asset')
                  }
                }}
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
