import { useCallback, useState } from 'react'
import AccountLockedDialog from './AccountLockedDialog'
import PricingDialog from './PricingDialog'

const Paywall = () => {
  const [showPricingDialog, setShowPricingDialog] = useState(false)

  const handleUpgradeClick = useCallback(() => {
    setShowPricingDialog(true)
  }, [])

  const handlePricingClose = useCallback(() => {
    setShowPricingDialog(false)
  }, [])

  return (
    <>
      <AccountLockedDialog onUpgradeClick={handleUpgradeClick} />
      <PricingDialog open={showPricingDialog} onClose={handlePricingClose} />
    </>
  )
}

export default Paywall
