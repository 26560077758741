import { useMemo } from 'react'
import { IWhitelistedUser, MonitorableNotificationSettingsArray } from '@valuecase/common'

export const notificationLabels = {
  allComments: 'Comments',
  dueToday: 'Task due reminder',
  milestoneDone: 'Milestone completed',
  taskAssigned: 'Task assigned',
  unsubscribed: 'Unsubscribed',
} as const

export type WhitelistedUserNotificationsSettings = {
  allNotificationsEnabled: boolean
  allNotificationsDisabled: boolean
  someNotificationsEnabled: boolean
}

/**
 * This hook returns the notification status based on the user's notification settings
 * @param settings WhitelistedUserNotificationsSettings
 * @returns notification status
 */
export const useNotificationStatus = (
  settings: IWhitelistedUser['WhitelistedUserNotificationsSettings'],
): WhitelistedUserNotificationsSettings => {
  return useMemo(() => {
    if (!settings) {
      return {
        allNotificationsEnabled: false,
        allNotificationsDisabled: true,
        someNotificationsEnabled: false,
      }
    }

    const { id, whitelistedUserId, unsubscribed, ...notifications } = settings

    const notificationKeys = Object.keys(notifications)
    const activeNotifications = notificationKeys.filter(
      (key) => notifications[key as keyof typeof notifications],
    )

    const allNotificationsEnabled = activeNotifications.length === notificationKeys.length
    const allNotificationsDisabled = activeNotifications.length === 0
    const someNotificationsEnabled = !allNotificationsDisabled && !allNotificationsEnabled

    return {
      allNotificationsEnabled,
      allNotificationsDisabled,
      someNotificationsEnabled,
    }
  }, [settings])
}

export const getNotificationText = ({
  status,
  settings,
  monitoredSettings,
}: {
  status: WhitelistedUserNotificationsSettings | null
  settings: IWhitelistedUser['WhitelistedUserNotificationsSettings'] | undefined
  monitoredSettings?: MonitorableNotificationSettingsArray
}) => {
  if (!settings || !status) {
    return 'All notifications off'
  }

  const { allNotificationsEnabled, allNotificationsDisabled, someNotificationsEnabled } = status

  // If monitoring specific settings, only show those that are enabled
  if (monitoredSettings?.length) {
    const enabledMonitoredSettings = monitoredSettings
      .filter((setting) => settings[setting] === true)
      .map((setting) => notificationLabels[setting])

    if (enabledMonitoredSettings.length === 0) {
      return 'No relevant notifications settings are on'
    }

    return enabledMonitoredSettings.join(', ')
  }

  // Default behavior when not monitoring specific settings
  if (allNotificationsEnabled) {
    return 'All notifications on'
  } else if (allNotificationsDisabled) {
    return 'All notifications off'
  } else if (someNotificationsEnabled) {
    const { id, whitelistedUserId, unsubscribed, ...notifications } = settings
    const activeNotifications = Object.entries(notifications)
      .filter(([_, enabled]) => enabled)
      .map(([key]) => notificationLabels[key as keyof typeof notificationLabels])

    return activeNotifications.join(', ')
  }

  return 'All notifications off'
}
