import { Button, ModalControls, Spinner, useOverlay } from '@valuecase/ui-components'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useReadUserProfile } from './hooks/useReadUserProfile'
import { ProfileDetails, ProfileImageWrapper, ProfileSummaryLayout } from './ProfileAccountParts'
import UserProfileEditorModal from './UserProfileEditor/UserProfileEditorModal'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { ProfileUpdateSuccessQueryParamKey } from './UserProfileEditor/UserProfileEditor'
import { useSignedImageUrl } from '../../api/spacesApi/signedS3Images'
import { useReadSeller } from './hooks/useReadSeller'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import IntercomLearnMoreButton from '../navigation/Intercom/IntercomLearnMoreButton'

const ProfileAccount = () => {
  const [mixpanel_pageVisitedTracked, set_mixpanel_pageVisitedTracked] = useState(false)
  const { trackEvent } = useTrackEvent()
  const overlay = useOverlay()
  const controls = useRef<ModalControls | null>(null)
  const { userProfile, isLoading } = useReadUserProfile()
  const { seller } = useReadSeller()
  const { imageUrl: profilePhotoUrl } = useSignedImageUrl(
    userProfile?.spacesProfile?.photo as {
      source: 'S3'
      s3Key: string
    },
  )

  const showEditProfileOverlay = useCallback(() => {
    overlay.show(<UserProfileEditorModal controlsRef={controls} />)
  }, [overlay])
  const { success } = useNotifications()

  // Show success message after a successful profile update + relogin (to get new tokens)
  // See: UserProfileEditor.tsx
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    if (searchParams.has(ProfileUpdateSuccessQueryParamKey)) {
      success('Your profile information is now up to date.')

      searchParams.delete(ProfileUpdateSuccessQueryParamKey)
      const updatedUrl = new URL(window.location.href)
      updatedUrl.search = searchParams.toString()
      window.history.replaceState(null, '', updatedUrl.href)
    }
  }, [success])

  //mixpanel track  page visit
  useEffect(() => {
    if (!mixpanel_pageVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Profile Account page',
        },
      }).finally(() => {
        set_mixpanel_pageVisitedTracked(true)
      })
    }
  }, [mixpanel_pageVisitedTracked, trackEvent, set_mixpanel_pageVisitedTracked])

  const email = useMemo(
    () => userProfile?.spacesProfile?.email || seller?.email || seller?.contactEmail || '',
    [userProfile, seller],
  )

  const handleLearnMoreClick = useCallback(() => {
    window.Intercom('showArticle', 77767)
  }, [])

  if (!userProfile || isLoading) return <Spinner />

  return (
    <div className='flex flex-col py-11 w-full'>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-140'}>
          <div className={'flex gap-3 items-center'}>
            <h1 className={'text-2xl font-bold'}>Profile</h1>
            <IntercomLearnMoreButton onClick={handleLearnMoreClick} />
          </div>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Insert all your information here. It’s visible in the Get-in-Touch element and the
            book-a-meeting- link is available as a quick action button in the action plan block.
          </p>
        </div>
        <div className={'flex items-center'}>
          <Button
            label='Edit'
            iconPosition={'right'}
            icon={'c_edit-outline'}
            onClick={() => {
              trackEvent({
                event: 'profile-edit-open_modal',
                eventProperties: {
                  indexPageName: 'Edit User Profile',
                },
              })
              showEditProfileOverlay()
            }}
          />
        </div>
      </div>
      <div className='w-full border-grey-s2 border-t-[1px] mt-10' />

      <ProfileSummaryLayout>
        <ProfileImageWrapper $profilePhotoUrl={profilePhotoUrl} />
        <div className={'flex flex-col gap-1'}>
          <h2
            className={'font-bold text-base'}
          >{`${userProfile.firstName} ${userProfile.lastName}`}</h2>
          <p className={'font-medium text-grey-s5 leading-[145%]'}>{email}</p>
        </div>
      </ProfileSummaryLayout>
      <ProfileDetails className={'items-baseline text-sm'}>
        <div>Job Title</div>
        <div>{userProfile.position}</div>
        <div>Phone Number</div>
        <div>{userProfile.phoneNumber}</div>
        <div>Book Meeting Link</div>
        <div>{userProfile.spacesProfile?.meetingUrl}</div>
        <div>Contact Email</div>
        <div>{seller?.contactEmail}</div>
      </ProfileDetails>
    </div>
  )
}

export default ProfileAccount
