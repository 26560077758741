import SellerApi from '../SellerApi'
import { TBillingDetails, TSubscriptionInfo } from '@valuecase/common'

export default {
  createCustomerPortalSession: () =>
    SellerApi.post<{ url: string }, undefined, undefined>('/billing/customer-portal', undefined),

  upsertBillingDetails: (tenantId: string, billingDetails: TBillingDetails) =>
    SellerApi.post<undefined, TBillingDetails, undefined>(
      `/billing/${encodeURIComponent(tenantId)}`,
      billingDetails,
    ),

  getIsAccountLocked: () => SellerApi.get<boolean, undefined>('/billing/account-locked', undefined),

  getSeatLimit: () => SellerApi.get<number | null, undefined>('/billing/seat-limit', undefined),
  getSubscriptionInfo: () =>
    SellerApi.get<TSubscriptionInfo | null, undefined>('/billing/subscription-info', undefined),

  getTrialStatus: () =>
    SellerApi.get<{ daysLeft: number | null }, undefined>('/billing/trial-status', undefined),
}
