import { Button2, useNotifications } from '@valuecase/ui-components'
import { SettingsInfo } from './SettingsInfo'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { InputColorPicker } from '@valuecase/ui-components/src/components/ui/color-picker'

export const CustomColorsSetting = () => {
  const { success } = useNotifications()
  const { companySettings, updateCompanySettings } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      success('Custom colors updated successfully')
    },
  })

  const [customColors, setCustomColors] = useState<string[]>([])

  useEffect(() => {
    if (companySettings?.customColors) {
      setCustomColors(companySettings.customColors)
    }
  }, [companySettings])

  const isMaxColorsReached = useMemo(() => {
    return customColors.length >= 5
  }, [customColors.length])

  const updateColors = useCallback(
    (newColors: string[]) => {
      setCustomColors(newColors)
      updateCompanySettings({ customColors: newColors.length > 0 ? newColors : null })
    },
    [updateCompanySettings],
  )

  const handleAddColor = useCallback(() => {
    if (isMaxColorsReached) {
      return
    }
    const newColors = [...customColors, '#000000']
    updateColors(newColors)
  }, [customColors, isMaxColorsReached, updateColors])

  const handleRemoveColor = useCallback(
    (index: number) => {
      const newColors = customColors.filter((_, i) => i !== index)
      updateColors(newColors)
    },
    [customColors, updateColors],
  )

  const handleColorChange = useCallback(
    (newColor: string, index: number) => {
      const newColors = [...customColors]
      newColors[index] = newColor
      updateColors(newColors)
    },
    [customColors, updateColors],
  )

  return (
    <div className='flex justify-between w-full items-start'>
      <div className='flex flex-col gap-2'>
        <SettingsInfo
          title='RTE font colors'
          description='Customize text color in the RTE with a color palette or hex code for emphasis and styling. Maximum of 5 colors allowed.'
        />
      </div>
      <div className='flex flex-col gap-4 w-[172px]'>
        {customColors.map((color, index) => (
          <div key={index} className='flex items-center gap-2'>
            <InputColorPicker
              value={color}
              onColorChange={(newColor) => handleColorChange(newColor, index)}
            />
            <Button2
              variant='subtle'
              leadingIcon='remove-outline'
              onClick={() => handleRemoveColor(index)}
            />
          </div>
        ))}
        <div className='flex justify-end'>
          {customColors.length === 0 && (
            <Button2 leadingIcon='add-outline' onClick={handleAddColor}>
              Add a Font Color
            </Button2>
          )}
          {customColors.length > 0 && (
            <Button2
              onClick={handleAddColor}
              leadingIcon='add-outline'
              disabled={isMaxColorsReached}
            />
          )}
        </div>
      </div>
    </div>
  )
}
