import styled from 'styled-components'

export const TemplateEditorHeaderWrapper = styled.div`
  position: relative;
  padding: 0 8px 8px;
`

export const TemplateEditorHeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 33px;
  color: #31333a;
  text-align: center;
`

export const TemplateEditorHeaderSubtitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s5};
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;

  span {
    font-weight: 800;
  }
`
