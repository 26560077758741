import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SpaceDTO, SpaceInput } from './types'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { useAuthState } from '../../../auth/auth'
import { createLogger } from '@/logging/Logger'

// Add type for the response
export type CreateSpaceResponse = {
  id: string
  rootNode: null
  type: string
  data: {
    language: string
    companyName: string
    meetingLink: null
    contactLastName: string
    contactFirstName: string
    contactEmail?: string
  }
  unversionedData: {
    auth: {
      token: string
    }
  }
  metadata: {
    ownerId: string
    parentTemplateId: string
  }
  children: any[]
  childrenIds: string[]
  createdAt: string
  parentIds: string[]
  virtualData: {}
}

async function postSpaceToBackend(spaceInput: SpaceInput, sub: string) {
  const createSpaceDto: SpaceDTO = {
    templateId: spaceInput.parentTemplateId,
    data: {
      companyName: spaceInput.companyName,
      contactFirstName: spaceInput.contactFirstName,
      contactLastName: spaceInput.contactLastName,
      contactEmail: spaceInput.contactEmail,
      companyWebsite: spaceInput.websiteUrl || undefined,
      language: spaceInput.language,
      companyLogo_s3ObjectKey: spaceInput.logo,
    },
    ownerId: sub,
    integrationIds: spaceInput.integrationIdMappings,
    customProperties: spaceInput.customProperties,
    teamIds: spaceInput.teamIds,
  }

  return await spacesApi.post<CreateSpaceResponse>(`/spaces`, createSpaceDto)
}

export const useCreateSpace = (props?: { onSuccess: (space: CreateSpaceResponse) => void }) => {
  const auth = useAuthState()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async (spacesInput: SpaceInput) => {
      if (!auth.authorized) {
        createLogger().error('Authorization missing during space creation.')
        throw new Error('Authorization missing')
      }
      const response = await postSpaceToBackend(spacesInput, auth.sub)
      return response.data
    },
    onSuccess: (space) => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] })

      if (props?.onSuccess) {
        props.onSuccess(space)
      }
    },
    onError: (error) => {
      createLogger().error('Failed to create space:')
      if (error && typeof error === 'object' && (error as any).response?.data?.message) {
        createLogger().error(JSON.stringify((error as any).response.data.message))
      } else {
        createLogger().error(JSON.stringify(error))
      }
    },
  })

  return {
    isSuccess: mutation.isSuccess,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    createSpace: (spaceInput: SpaceInput) => mutation.mutate(spaceInput),
    createSpaceAsync: (spaceInput: SpaceInput) => mutation.mutateAsync(spaceInput),
    createdSpace: mutation.data,
  }
}
