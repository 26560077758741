import { useMemo } from 'react'
import { SpaceUserAvatarThumbnail } from '../../components/ui'
import UserInfoTooltip, { UserNotificationSettingsProps } from './user-info-tooltip'
import { IWhitelistedUserDealSide, MonitorableNotificationSettingsArray } from '@valuecase/common'

export const getUserInitials = (
  user: Pick<UserNotificationSettingsProps, 'firstName' | 'lastName' | 'name' | 'email'>,
): string => {
  if (user.firstName && user.lastName) {
    return `${user.firstName[0]}${user.lastName[0]}`
  } else if (user.name) {
    return user.name.slice(0, 2)
  } else if ((user.firstName || user.lastName) && !user.email) {
    return (user.firstName || user.lastName || 'NA').slice(0, 2)
  } else if (user.email) {
    return user.email.slice(0, 2)
  }
  return 'NA'
}

interface UserAvatarWithNotificationSettingsProps {
  user: UserNotificationSettingsProps
  shape?: 'CIRCLE' | 'SQUARE'
  size?: 'EXTRASMALL' | 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRALARGE'
  greyedOut?: boolean
  monitoredSettings?: MonitorableNotificationSettingsArray
}

export const UserAvatarWithNotificationSettings = ({
  user,
  shape = 'CIRCLE',
  size = 'MEDIUM',
  greyedOut,
  monitoredSettings,
}: UserAvatarWithNotificationSettingsProps): JSX.Element => {
  const initials = useMemo(() => getUserInitials(user), [user])
  const dealSide = useMemo<IWhitelistedUserDealSide>(() => user.dealSide, [user.dealSide])

  return (
    <UserInfoTooltip user={user} monitoredSettings={monitoredSettings}>
      <SpaceUserAvatarThumbnail
        initials={initials}
        greyedOut={greyedOut}
        dealSide={dealSide}
        shape={shape}
        type='user'
        size={size}
      />
    </UserInfoTooltip>
  )
}

export default UserAvatarWithNotificationSettings
