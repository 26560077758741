import { useMemo } from 'react'
import { TaskColumnView } from './TaskColumnView'
import { useNextUnscheduledTasks } from '@valuecase/ui-components'
import { EmptyStateColumnWrapper } from './EmptyStateColumnWrapper'
import { useNavigate } from '@tanstack/react-router'
import { NextUnscheduledTasksEmptyState } from './empty-states'
import { useTasksFilter } from './useTasksFilter'
import { TActionPlanTaskExpanded } from '@valuecase/common'

export const NextUnscheduledTasksColumn = () => {
  const navigate = useNavigate()
  const { spaceCreatedAfter, ownerIds } = useTasksFilter()
  const tasksQuery = useNextUnscheduledTasks({ spaceCreatedAfter, ownerIds })

  const emptyState = useMemo(() => {
    return (
      <EmptyStateColumnWrapper>
        <NextUnscheduledTasksEmptyState />
      </EmptyStateColumnWrapper>
    )
  }, [])

  return (
    <TaskColumnView
      id='dashboard-tasks-without-due-date-column'
      iconName='arrow-forward-circle'
      iconClassName='text-orange-s4'
      title='Next Task Without Due Date'
      tooltip='This column only shows one task per action plan.'
      taskStatus='nextUnscheduled'
      tasksCount={tasksQuery.data?.totalItems || 0}
      tasks={(tasksQuery.data?.items as TActionPlanTaskExpanded[]) || []}
      emptyState={emptyState}
      isLoading={tasksQuery.isLoading}
      onViewAllClick={() =>
        navigate({
          to: '/tasks',
          search: (prev) => ({ ...prev, view: 'list', taskStatus: 'nextUnscheduled' }),
        })
      }
    />
  )
}
