import {
  ComponentPropsWithoutRef,
  useRef,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  ForwardedRef,
} from 'react'
import { Command, CommandInput, CommandItem, CommandList } from './command'
import { Popover, PopoverAnchor, PopoverContent } from './popover'
import { cn, Input2, InputProps2 } from '../..'

export type AutocompleteItemBase = {
  id: string
  icon?: string | null | undefined
  value: string
}

export type AutocompleteItemProps = {
  id: string
  icon?: string | null | undefined
  onSelect: () => void
  children: ReactNode
}

export function AutocompleteItem({ id, icon, onSelect, children }: AutocompleteItemProps) {
  return (
    <CommandItem
      key={id}
      value={id}
      onSelect={onSelect}
      className='flex gap-2 focus:bg-grey-s1 active:bg-grey-s1 hover:bg-grey-s1 cursor-pointer mt-0 text-sm'
    >
      {icon && <img src={icon} alt='' className='h-6 w-6 object-contain' />}
      {children}
    </CommandItem>
  )
}

type AutocompleteProps<T> = ComponentPropsWithoutRef<typeof CommandInput> &
  Pick<InputProps2, 'leadingIcon'> & {
    value: string
    onValueChange: (value: string) => void
    suggestions: T[]
    onTakeSuggestion?: (value: T) => void
    children: ReactNode
    open: boolean
    setOpen: (open: boolean) => void
  }

/**
 * Input field which also shows a list of suggested values based on the current input value; the
 * user can select a suggestion to use it as the current input value.
 */
export const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps<AutocompleteItemBase>>(
  (
    {
      onTakeSuggestion,
      value = '',
      onValueChange,
      suggestions,
      className,
      children,
      open,
      setOpen,
      ...props
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const internalRef = useRef<HTMLInputElement>(null)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => internalRef.current!)
    const commandRef = useRef<HTMLDivElement>(null)

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverAnchor>
          <Input2
            {...props}
            ref={internalRef}
            className={cn('mb-0', className)}
            inputSize={'default'}
            leadingIcon={null}
            value={value}
            onChange={(e) => {
              onValueChange(e.target.value)
            }}
            onClick={(e) => {
              if (!open) {
                e.preventDefault()
                e.stopPropagation()
                setOpen(true)
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setOpen(false)
                setTimeout(() => {
                  internalRef.current?.focus()
                }, 10)
              } else if (e.key === 'ArrowDown') {
                setTimeout(() => {
                  commandRef.current?.focus()
                  commandRef.current?.dispatchEvent(
                    new KeyboardEvent('keydown', { key: 'ArrowDown' }),
                  )
                }, 10)
              }
            }}
          />
        </PopoverAnchor>
        <PopoverContent
          hideClose={true}
          onOpenAutoFocus={(e) => e.preventDefault()}
          onInteractOutside={(e) => {
            const isInteractionWithInput =
              e.target === internalRef.current || internalRef.current?.contains(e.target as Node)
            if (isInteractionWithInput) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          onCloseAutoFocus={(e) => {
            e.preventDefault()
            e.stopPropagation()
            internalRef.current?.focus()
          }}
          className='w-[--radix-popover-trigger-width] p-0'
        >
          <Command className='outline-none' ref={commandRef} shouldFilter={false} loop>
            <CommandList>{children}</CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    )
  },
)

Autocomplete.displayName = 'Autocomplete'
