import { useCallback, useEffect, useMemo, useState } from 'react'

import { Button2, Card, useDateFormatting } from '@valuecase/ui-components'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { VLoaderAnimation } from '../../ui-components/VLoader/VLoader'
import { useReadUsers } from './hooks/useReadUsers'
import { InviteUserModal } from './InviteUserModal'
import UsersList from './UsersList/UsersList'
import { useReadSubscriptionInfo } from './hooks/useReadSubscriptionInfo'
import { DateTime } from 'luxon'
import IntercomLearnMoreButton from '../navigation/Intercom/IntercomLearnMoreButton'

const UserManagement = () => {
  const [mixpanel_pageVisitedTracked, set_mixpanel_pageVisitedTracked] = useState(false)
  const { users, isLoading } = useReadUsers()
  const { trackEvent } = useTrackEvent()
  const [inviteUserModalIsOpen, setInviteUserModalIsOpen] = useState(false)

  const { data: subscriptionInfo } = useReadSubscriptionInfo()

  //mixpanel track  page visit
  useEffect(() => {
    if (!mixpanel_pageVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'User Management page',
        },
      }).finally(() => {
        set_mixpanel_pageVisitedTracked(true)
      })
    }
  }, [mixpanel_pageVisitedTracked, trackEvent, set_mixpanel_pageVisitedTracked])

  // valuecase users are support users and are not billed for
  const currentUserCount = useMemo(() => {
    const usersToCount = (users ?? []).filter((user) => !user.email.includes('@valuecase.'))
    return usersToCount.length
  }, [users])

  const seatAllowance = useMemo(() => {
    if (subscriptionInfo && subscriptionInfo?.seatLimit) {
      return subscriptionInfo.seatLimit.toString()
    }
    return 'n/a'
  }, [subscriptionInfo])

  const { formatDateShort } = useDateFormatting()

  const billingPeriod = useMemo(() => {
    if (subscriptionInfo && subscriptionInfo?.nextBillDate) {
      return formatDateShort(DateTime.fromJSDate(subscriptionInfo.nextBillDate))
    }
    return 'n/a'
  }, [formatDateShort, subscriptionInfo])

  const paidSeatsHint = useMemo(() => {
    if (!subscriptionInfo || !subscriptionInfo.seatLimit) {
      return ''
    } else if (subscriptionInfo.seatLimit > currentUserCount) {
      return 'The number of paid seats in your workspace'
    } else {
      // if a tenant is on yearly billing and adds more seats
      // we will upgrade not at the beginning of next billing period (could be in 11 months)
      // but instead - next month
      const isYearlyBilling =
        subscriptionInfo.planInterval && subscriptionInfo.planInterval === 'year'
      return `Your workspace is currently billed for ${subscriptionInfo.seatLimit} seat${subscriptionInfo.seatLimit > 1 ? 's' : ''}. Your bill will remain the same until it is adjusted at the beginning of next ${isYearlyBilling ? 'month' : 'billing period'}.`
    }
  }, [subscriptionInfo, currentUserCount])

  const billingPeriodHint = useMemo(() => {
    if (!subscriptionInfo || !subscriptionInfo.nextBillDate) {
      return ''
    } else {
      return 'You have paid for seats until the next billing period, and you may add more members before the next billing period'
    }
  }, [subscriptionInfo])

  const currentSeatsHint = useMemo(() => {
    if (!subscriptionInfo || !subscriptionInfo.seatLimit) {
      return 'This is how many active users you currently have in your workspace.'
    } else if (subscriptionInfo.seatLimit > currentUserCount) {
      const freeSeats = subscriptionInfo.seatLimit - currentUserCount
      return `You can invite ${freeSeats} more member${freeSeats > 1 ? 's' : ''} without an additional charge, since you have already paid for those seats this billing period. You can always add more members at an additional charge.`
    } else {
      return `All seats are currently filled. You can always add more members at an additional charge.`
    }
  }, [subscriptionInfo, currentUserCount])

  const handleLearnMoreClick = useCallback(() => {
    window.Intercom('showArticle', 77764)
  }, [])

  return (
    <div className={'flex flex-col py-11 w-full'}>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-118'}>
          <div className={'flex gap-3 items-center'}>
            <h1 className={'text-2xl font-bold'}>User Management</h1>
            <IntercomLearnMoreButton onClick={handleLearnMoreClick} />
          </div>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Manage your team members with ease. Add or remove users, reassign spaces, change roles
            and permissions.
          </p>
        </div>
        <div className={'flex items-center'}>
          <Button2
            leadingIcon='add'
            onClick={() => {
              trackEvent({
                event: 'users-newuser-open_modal',
                eventProperties: {
                  indexPageName: 'User Management',
                },
              })
              setInviteUserModalIsOpen(true)
            }}
          >
            Add User
          </Button2>
        </div>
      </div>
      <div className='w-full mt-10'>
        <div className='grid grid-cols-3 gap-3 mb-4'>
          <Card
            iconName='briefcase'
            iconClassName='text-grey-s5'
            label='Paid seats'
            value={seatAllowance}
            tooltip={paidSeatsHint}
          />
          <Card
            iconName='people'
            iconClassName='text-grey-s5'
            label='Current users'
            value={currentUserCount.toString()}
            tooltip={currentSeatsHint}
          />
          <Card
            iconName='calendar'
            iconClassName='text-grey-s5'
            label='Next billing cycle'
            value={billingPeriod}
            tooltip={billingPeriodHint}
          />
        </div>
      </div>
      {isLoading && (
        <div className={'p-16 grid place-items-center'}>
          <VLoaderAnimation />
        </div>
      )}
      <InviteUserModal
        isOpen={inviteUserModalIsOpen}
        onOpenChange={(open) => setInviteUserModalIsOpen(open)}
      />
      {users && <UsersList users={users} />}
    </div>
  )
}

export default UserManagement
