import { cn } from '../../lib/utils'
import * as React from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { forwardRef, InputHTMLAttributes, useImperativeHandle, useMemo, useRef } from 'react'
import { IonIcon } from '../../Icons/IonIcon'
import { Slot } from '@radix-ui/react-slot'

const inputVariants = cva(
  cn(
    'bg-white rounded-lg border placeholder:text-grey-s4 text-grey-s6',
    'font-normal w-full inline-flex gap-2 align-middle items-center justify-start',
    'transition-colors transition-shadow cursor-text',
  ),
  {
    variants: {
      inputSize: {
        default: 'h-9 px-3 py-2 text-xs',
        large: 'h-11 px-4 py-3 text-sm',
      },
      variant: {
        // the has-[:focus] modifier is required to style the parent div of the input
        default:
          'border-grey-s2 hover:shadow-text-input has-[:focus]:border-primary-s4 has-[:focus]:shadow-text-input',
        error:
          'border-warning-s5 hover:shadow-text-input-error has-[:focus]:border-warning-s5 has-[:focus]:shadow-text-input-error',
      },
    },
    defaultVariants: {
      inputSize: 'default',
      variant: 'default',
    },
  },
)

export interface InputProps2
  extends InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
  asChild?: boolean
  inputClassName?: HTMLInputElement['className']
}

const Input2 = forwardRef<HTMLInputElement, InputProps2>(
  (
    {
      className,
      type,
      variant,
      inputSize,
      leadingIcon,
      trailingIcon,
      asChild = false,
      children,
      disabled,
      inputClassName,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'input'
    const internalRef = useRef<HTMLInputElement>(null)

    // Combine and manage both refs (internal and forwarded ref)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => internalRef.current!)

    const iconClassName = useMemo(
      () =>
        cn('text-primary-s3 flex-shrink-0', {
          'w-4 h-4': inputSize === 'default',
          'w-5 h-5': inputSize === 'large',
        }),
      [inputSize],
    )

    return (
      <div
        role='textbox'
        className={cn(inputVariants({ inputSize, variant }), className, {
          'border-grey-s2 bg-grey-s1 text-grey-s4 pointer-events-none': disabled,
        })}
        onClick={() => internalRef.current?.focus()}
      >
        {typeof leadingIcon === 'string' ? (
          <IonIcon name={leadingIcon} className={iconClassName} />
        ) : (
          leadingIcon
        )}

        <Comp
          disabled={disabled}
          type={type}
          className={cn('outline-none flex-grow w-full bg-transparent', inputClassName)}
          ref={internalRef}
          {...{ ...props, children: asChild ? children : undefined }}
        />

        {typeof trailingIcon === 'string' ? (
          <IonIcon name={trailingIcon} className={iconClassName} />
        ) : (
          trailingIcon
        )}

        {!asChild && children}
      </div>
    )
  },
)
Input2.displayName = 'Input2'

export { Input2 }
