import { useModalControls } from '@valuecase/ui-components'
import { useCallback, useState } from 'react'
import { Navigation } from '../spaceCreationFlow/SpaceCreationNavigation'
import { useTrackEvent } from '../../../../mixpanel/useTrackEvent'
import { VLoaderAnimation } from '../../../../ui-components/VLoader/VLoader'
import { useCreateSpaceIntegrationAttributes } from '../../hooks/useCreateSpaceIntegrationIdMapping'
import { useNotifications } from '../../../../utils/Notifications/Notifications'
import { SpacesListDto } from '../spacesList/spacesList'
import { SalesforceObjectSelector } from '../../../integrations/salesforce/SalesforceObjectSelector'
import { SalesforceObject } from '@valuecase/common'
import { useReadSalesforceIntegrationStatus } from '../../../integrations/salesforce/useReadSalesforceIntegrationStatus'

/**
 * Shows a list of Salesforce deals and allows the user to select one to link to the given space.
 */
export function SpaceToSalesforceLinker({ space }: { space: SpacesListDto }) {
  const { trackEvent } = useTrackEvent()
  const { success, error } = useNotifications()
  const { close } = useModalControls()
  const { salesforceIntegrationStatus } = useReadSalesforceIntegrationStatus()
  const [selectedObjectType, setSelectedObjectType] = useState<string | undefined>(undefined)
  const selectedObjectTypeLowerCase = selectedObjectType?.toLowerCase()
  const onSuccess = useCallback(() => {
    success(`Space successfully connected to Salesforce ${selectedObjectTypeLowerCase}`)
    trackEvent({
      event: `space-linked-to-salesforce-${selectedObjectTypeLowerCase}`,
      eventProperties: {
        indexPageName: 'Create Space Modal',
        spaceId: space.id,
      },
    })
    close()
  }, [close, selectedObjectTypeLowerCase, space.id, success, trackEvent])
  const onError = useCallback(() => {
    error(`Error linking space to Salesforce ${selectedObjectTypeLowerCase}`)
    trackEvent({
      event: `space-linked-to-salesforce-${selectedObjectTypeLowerCase}`,
      eventProperties: {
        indexPageName: 'Create Space Modal',
        spaceId: space.id,
      },
    })
    close()
  }, [close, error, selectedObjectTypeLowerCase, space.id, trackEvent])
  const { createSpaceIntegrationIdMapping, isLoading, isError, isSuccess } =
    useCreateSpaceIntegrationAttributes({
      onSuccess,
      onError,
    })

  const [selectedSalesforceObject, setSelectedSalesforceObject] = useState<
    SalesforceObject | undefined
  >(undefined)
  const handleUpdateSpace = useCallback(async () => {
    if (!selectedSalesforceObject || !selectedObjectType) {
      throw new Error('No object selected')
    }
    createSpaceIntegrationIdMapping({
      spaceId: space.id,
      payload: {
        integrationIdMappings: [
          {
            thirdParty: 'Salesforce',
            thirdPartyEntityType: selectedObjectType,
            thirdPartyId: selectedSalesforceObject.Id,
            thirdPartyOrganizationId:
              salesforceIntegrationStatus?.settings?.thirdPartyOrganizationId,
          },
        ],
      },
    })
  }, [
    createSpaceIntegrationIdMapping,
    selectedSalesforceObject,
    selectedObjectType,
    space.id,
    salesforceIntegrationStatus?.settings?.thirdPartyOrganizationId,
  ])

  // Modal closes when request completes, so can retain the spinner for error and success states
  if (isLoading || isError || isSuccess) {
    return (
      <div className='flex justify-center items-center p-[200px]'>
        <VLoaderAnimation />
      </div>
    )
  }

  return (
    <div>
      <div className='flex flex-col items-ce text-center justify-center pb-10 gap-2'>
        <h1 className='text-2xl font-extrabold text-grey-s6 m-0 p-0'>Connect to Salesforce</h1>
        <p className='text-sm font-normal text-grey-s5 m-0 p-0'>
          Select a Salesforce object to link it to your space.
        </p>
      </div>
      <SalesforceObjectSelector
        spaceId={space.id}
        onObjectSelected={(object, objectType) => {
          // Show a warning if the user is about to overwrite their selection from a different object type tab
          if (selectedObjectType !== objectType && selectedSalesforceObject) {
            const res = confirm(
              'You can only select one record at a time, this will unlink your previously selected record. Continue?',
            )
            if (!res) {
              return
            }
          }
          setSelectedSalesforceObject(object)
          setSelectedObjectType(objectType)
        }}
        selectedObject={selectedSalesforceObject}
      />
      <Navigation
        step={1}
        nextButtonLabel={'Link to Salesforce'}
        maxSteps={1}
        isNextButtonDisabled={!selectedSalesforceObject}
        onNext={handleUpdateSpace}
        showSkipButton={false}
        // Back button is not needed in this one-step flow
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onBack={() => {}}
      />
    </div>
  )
}
