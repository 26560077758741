import { useQuery } from '@tanstack/react-query'

/**
 * Hook for fetching integration items based on a search term.
 * M = a mapping type that associates entity types to their data types
 * U = a type of string literals representing the integration entity types
 */
export function useSearchIntegrationItemsQuery<M, U extends keyof M>({
  queryKeyPrefix,
  enabled,
  searchTerm,
  objectType,
  objectTypeName,
  searchFunction,
}: {
  queryKeyPrefix: string
  enabled?: boolean
  searchTerm: string
  objectType: U
  objectTypeName: string
  // Use conditional types to link U to the corresponding type in M
  searchFunction: (
    searchTerm: string,
    objectType: U,
    objectTypeName: string,
  ) => Promise<M[U][] | undefined>
}) {
  const query = useQuery({
    retry: false,
    queryKey: [queryKeyPrefix, searchTerm, objectTypeName],
    queryFn: () => searchFunction(searchTerm, objectType, objectTypeName),
    enabled,
    // Since we're using the same query for multiple search terms, we want to keep the previous data
    // while the new data is being fetched as the user types new search input.
    keepPreviousData: true,
  })

  return query
}
