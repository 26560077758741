import { useCallback, useMemo } from 'react'
import TemplateBlank from './templateImages/template_blank.svg'
import TemplateOnboardingSaasImage from './templateImages/template_onboarding_saas.png'
import TemplateOnboardingAgencyImage from './templateImages/template_onboarding_agency.png'
import TemplateOnboardingHardwareImage from './templateImages/template_onboarding_hardware.png'
import TemplateOnboardingHRImage from './templateImages/template_onboarding_hr.png'
import TemplateSalesValuecaseImage from './templateImages/template_sales_valuecase.png'
import TemplateSalesEnterpriseImage from './templateImages/template_sales_enterprise.png'
import { cn } from '@valuecase/ui-components'
import { ReactNode } from '@tanstack/react-router'

const TemplateCard = ({
  title,
  description,
  children,
  className,
  onClick,
}: {
  title: string
  description: string
  children: ReactNode
  className?: string
  onClick?: () => void
}) => {
  return (
    <div
      onClick={onClick}
      role='button'
      className={cn(
        'flex md:w-1/4 flex-col rounded-lg overflow-hidden bg-white',
        'md:hover:outline outline-1 outline-offset-2 outline-blue-s4 hover:shadow-sm transition-all',
      )}
    >
      <div className={cn('flex justify-center items-end bg-white', 'bg-gradient-to-b', className)}>
        {children}
      </div>
      <div className='flex flex-col py-3 px-4 md:pb-10'>
        <h3 className='font-semibold'>{title}</h3>
        <p className='text-sm text-grey-s5'>{description}</p>
      </div>
    </div>
  )
}

type TemplateCardProps = {
  handleTemplateChange: (templateId: string | null, templateTitle: string) => void
}

const SaasOnboardingTemplateCard = ({ handleTemplateChange }: TemplateCardProps) => {
  const title = 'Security SaaS Onboarding'
  return (
    <TemplateCard
      title={title}
      description='End-to-end onboarding on cyber security certification service in multiple phases.'
      className='from-[#9B97A8] to-[#686576]'
      onClick={() => handleTemplateChange('cm074lx0j0fcc29u4a08dvo0t', title)}
    >
      <img src={TemplateOnboardingSaasImage} />
    </TemplateCard>
  )
}
const AgencyOnboardingTemplateCard = ({ handleTemplateChange }: TemplateCardProps) => {
  const title = 'Agency Onboarding'
  return (
    <TemplateCard
      title={title}
      description='Client intake and project delivery for agencies and professional services.'
      className='from-[#4261DA] to-[#2941A1]'
      onClick={() => handleTemplateChange('cm6gvli2w0faogiu0rynoq3le', title)}
    >
      <img src={TemplateOnboardingAgencyImage} />
    </TemplateCard>
  )
}
const HardwareOnboardingTemplateCard = ({ handleTemplateChange }: TemplateCardProps) => {
  const title = 'Hardware Onboarding'
  return (
    <TemplateCard
      title={title}
      description='For companies providing hardware with installation and implementation support.'
      className='from-[#8DDA9F] to-[#5B9D78]'
      onClick={() => handleTemplateChange('cm6i8rbkq13lbgiu0zvwojae9', title)}
    >
      <img src={TemplateOnboardingHardwareImage} />
    </TemplateCard>
  )
}
const HRSaasOnboardingTemplateCard = ({ handleTemplateChange }: TemplateCardProps) => {
  const title = 'HR SaaS Onboarding'
  return (
    <TemplateCard
      title={title}
      description='Structured go-live planning for SaaS implementations involving multiple stakeholders.'
      className='from-[#4261DA] to-[#2941A1]'
      onClick={() => handleTemplateChange('cm6i8poa813exgiu0acndyx4e', title)}
    >
      <img src={TemplateOnboardingHRImage} />
    </TemplateCard>
  )
}

const ValuecaseSalesTemplateCard = ({ handleTemplateChange }: TemplateCardProps) => {
  const title = 'Valuecase Sales Template'
  return (
    <TemplateCard
      title={title}
      description="Inspired by Valuecase's own sales template for mid-market deals."
      className='from-[#0B53A6] to-[#09376C]'
      onClick={() => handleTemplateChange('jrsedkhhggi6pokk12d8h1y9', title)}
    >
      <img src={TemplateSalesValuecaseImage} />
    </TemplateCard>
  )
}
const EnterpriseSalesTemplateCard = ({ handleTemplateChange }: TemplateCardProps) => {
  const title = 'Enterprise Sales'
  return (
    <TemplateCard
      title={title}
      description='Seamless buying experience for complex deals, incl. business case, action plan & more.'
      className='from-[#FE7D3F] to-[#BA4E25]'
      onClick={() => handleTemplateChange('x7cezwnwzdwzc1ik0nj158h1', title)}
    >
      <img src={TemplateSalesEnterpriseImage} />
    </TemplateCard>
  )
}

const StartFromBlankTemplateCard = ({ handleTemplateChange }: TemplateCardProps) => {
  const title = 'Start from blank'
  return (
    <div
      onClick={() => handleTemplateChange(null, title)}
      role='button'
      className={cn(
        'flex w-1/4 flex-col rounded-lg overflow-hidden',
        'hover:outline outline-1 outline-offset-2 outline-blue-s4 hover:shadow-sm transition-all',
        'border border-dashed border-blue-s4 hover:border-transparent',
      )}
    >
      <div
        className={cn(
          'flex justify-center items-end pt-4 px-4',
          'bg-gradient-to-b from-blue-s2 to-[#DFE5FF]',
        )}
      >
        <img src={TemplateBlank} />
      </div>
      <div className='flex flex-col py-3 px-4 md:pb-16'>
        <h3 className='font-semibold'>{title}</h3>
        <p className='text-sm text-grey-s5'>Build your own template from scratch.</p>
      </div>
    </div>
  )
}

export const TemplateGalleryStep = ({
  onUpdateForm,
  onTemplateSelect,
  form,
}: {
  form: {
    useCase: string
  }
  onUpdateForm: (field: string, value: string | null) => void
  onTemplateSelect?: (templateId: string | null, templateTitle: string) => void
}) => {
  const handleTemplateChange = useCallback(
    (templateId: string | null, templateTitle: string) => {
      // Template IDs are hardcoded from the production environment's public template gallery.
      // In staging and local environments, different template IDs are used, resulting in the creation of an empty template.
      onUpdateForm('templateId', templateId)
      onTemplateSelect?.(templateId, templateTitle)
    },
    [onTemplateSelect, onUpdateForm],
  )

  const templateCards = useMemo(() => {
    if (form.useCase === 'onboarding') {
      return (
        <>
          <SaasOnboardingTemplateCard handleTemplateChange={handleTemplateChange} />
          <AgencyOnboardingTemplateCard handleTemplateChange={handleTemplateChange} />
          <HardwareOnboardingTemplateCard handleTemplateChange={handleTemplateChange} />
          <HRSaasOnboardingTemplateCard handleTemplateChange={handleTemplateChange} />
        </>
      )
    }
    if (form.useCase === 'sales') {
      return (
        <>
          <ValuecaseSalesTemplateCard handleTemplateChange={handleTemplateChange} />
          <EnterpriseSalesTemplateCard handleTemplateChange={handleTemplateChange} />
        </>
      )
    }
    return <StartFromBlankTemplateCard handleTemplateChange={handleTemplateChange} />
  }, [form.useCase, handleTemplateChange])

  return (
    <div className='flex justify-center flex-col md:flex-row gap-4 w-full'>{templateCards}</div>
  )
}
