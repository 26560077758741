import {
  Badge,
  Button2,
  ButtonLink,
  cn,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  defaultTaskSearchTimeRange,
  IonIcon,
  ScrollArea,
  ScrollAreaGradient,
  useOverdueTasks,
} from '@valuecase/ui-components'

import { AnchorHTMLAttributes, forwardRef, ReactNode, useMemo, useRef } from 'react'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'

import { createLink, useLocation } from '@tanstack/react-router'
import { CollapsibleChevron, useStoreCollapsableState } from './utils'
import { useOpenCreateSpaceModal } from '@/modules/spaces/hooks/useOpenCreateSpaceModal'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useHasPermission } from '@/auth/permissions'
import { useActivityFeedCount } from '@/modules/activityFeed/hooks/useActivityFeed'
import { DateTime } from 'luxon'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import { useCreateCustomerPortalSession } from '@/modules/billing/hooks/useCreateCustomerPortalSession'
import { SidebarFooter } from './SidebarFooter'
import { useAuthState } from '@/auth/auth'
import { getCleanSub } from '@valuecase/common'

const menuItemButtonBaseClasses =
  'text-sm font-medium py-2 px-4 text-left hover:bg-[#E9EDFF] rounded flex gap-3'

const MenuItemButtonBaseComponent = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement> & { icon?: string; activeIcon?: string }
>(({ icon, activeIcon, children, className, ...props }, ref) => {
  return (
    <a
      ref={ref}
      className={cn(
        'data-[status=active]:bg-[#E9EDFF] data-[status=active]:font-semibold',
        'group',
        menuItemButtonBaseClasses,
        className,
      )}
      {...props}
    >
      {icon && (
        <>
          <IonIcon
            name={icon}
            className={cn('w-5 h-5 flex-shrink-0', 'text-grey-s4', {
              'group-data-[status=active]:hidden': !!activeIcon,
            })}
          />
          {activeIcon && (
            <IonIcon
              name={activeIcon}
              className={cn(
                'w-5 h-5 flex-shrink-0',
                'text-blue-s5 hidden',
                'group-data-[status=active]:block',
              )}
            />
          )}
        </>
      )}
      {children}
    </a>
  )
})
MenuItemButtonBaseComponent.displayName = 'MenuItemButtonBaseComponent'

const MenuItemButtonLink = createLink(MenuItemButtonBaseComponent)

const MenuItemButton = ({
  id,
  onClick,
  children,
}: {
  id?: string
  onClick: () => void
  children: ReactNode
}) => {
  return (
    <button id={id} onClick={onClick} className={menuItemButtonBaseClasses}>
      {children}
    </button>
  )
}

const Button2Link = createLink(ButtonLink)

export const Sidebar = () => {
  const { trackEvent } = useTrackEvent()
  const { openCreateSpaceModal } = useOpenCreateSpaceModal()

  const { tenant } = useCurrentTenantQuery()
  const { createCustomerPortalSession } = useCreateCustomerPortalSession()

  const [assetsMenuOpen, setAssetsMenuOpen] = useStoreCollapsableState('assetsMenu')
  const [analyticsMenuOpen, setAnalyticsMenuOpen] = useStoreCollapsableState('analyticsMenu')

  const { pathname } = useLocation()

  const showSettingsSidebar = useMemo(() => {
    return (
      pathname.startsWith('/dashboard/mySettings') ||
      pathname.startsWith('/dashboard/administration')
    )
  }, [pathname])

  const { flags } = useLDflags()

  const { unreadCount } = useActivityFeedCount()

  const hasSpacesAllAdministrationPermission = useHasPermission('SPACES_ALL_ADMINISTRATION')
  const auth = useAuthState()

  const userId = useMemo(() => (auth.authorized ? getCleanSub(auth.sub) : null), [auth])

  const overdueTasksQuery = useOverdueTasks({
    spaceCreatedAfter: DateTime.now().minus(defaultTaskSearchTimeRange).startOf('day').toJSDate(),
    ownerIds: hasSpacesAllAdministrationPermission && userId ? [userId] : undefined,
  })

  const overdueTasks = useMemo(() => overdueTasksQuery.data?.totalItems || 0, [overdueTasksQuery])

  // "Customers" menu
  const taskManagementEnabled = useMemo(() => flags && flags['task-management'], [flags])

  // "Assets" menu
  const hasTenantSettingsPermission = useHasPermission('TENANT_SETTINGS_MANAGE')
  const hasTemplateManagementPermission = useHasPermission('TEMPLATES_MANAGE')

  const hasAssetLibraryPermission = useHasPermission('ASSET_LIBRARY_VIEW')
  const assetLibraryEnabled = useMemo(
    () => flags && flags['asset-management'] && hasAssetLibraryPermission,
    [flags, hasAssetLibraryPermission],
  )
  const spacePropertiesEnabled = useHasPermission('SPACE_PROPERTIES_MANAGE')
  const assetsMenuEnabled = useMemo(
    () => hasTemplateManagementPermission || assetLibraryEnabled || spacePropertiesEnabled,
    [hasTemplateManagementPermission, assetLibraryEnabled, spacePropertiesEnabled],
  )

  const workflowsEnabled = useMemo(
    () => flags && flags['workflows'] && hasTemplateManagementPermission,
    [flags, hasTemplateManagementPermission],
  )

  // "Analytics" menu
  const hasSpacesModificationPermission = useHasPermission('SPACES_MODIFICATION')

  const actionPlanAnalyticsEnabled = useMemo(() => {
    return (
      flags &&
      flags['dashboard-action-plan-analytics'] &&
      (hasSpacesModificationPermission || hasTenantSettingsPermission)
    )
  }, [flags, hasSpacesModificationPermission, hasTenantSettingsPermission])
  const teamActivityAnalyticsEnabled = useMemo(
    () => flags && flags['dashboard-analytics-page'] && hasTenantSettingsPermission,
    [flags, hasTenantSettingsPermission],
  )
  const analyticsMenuEnabled = useMemo(
    () => actionPlanAnalyticsEnabled || teamActivityAnalyticsEnabled,
    [actionPlanAnalyticsEnabled, teamActivityAnalyticsEnabled],
  )

  //"Admin settings" menu
  const hasUserManagePermission = useHasPermission('USERS_MANAGE')

  const companySettingsEnabled = useMemo(
    () => hasTenantSettingsPermission,
    [hasTenantSettingsPermission],
  )
  const usersManagementEnabled = useMemo(() => hasUserManagePermission, [hasUserManagePermission])
  const teamsManagementEnabled = useMemo(
    () => hasTenantSettingsPermission && flags && flags['teams-management'],
    [flags, hasTenantSettingsPermission],
  )
  const billingEnabled = useMemo(
    () => hasTenantSettingsPermission && tenant && tenant.payload.hasBillingDetails,
    [hasTenantSettingsPermission, tenant],
  )
  const adminSettingsMenuEnabled = useMemo(
    () =>
      companySettingsEnabled || usersManagementEnabled || teamsManagementEnabled || billingEnabled,
    [companySettingsEnabled, usersManagementEnabled, teamsManagementEnabled, billingEnabled],
  )

  const scrollAreaRef = useRef<HTMLDivElement>(null)
  const settingsScrollAreaRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className={cn(
        'flex flex-col justify-between',
        'min-w-[280px] w-[280px] border-r border-grey-s2 bg-white',
      )}
    >
      {!showSettingsSidebar && (
        <>
          <ScrollArea maxHeight={'100%'} viewportRef={scrollAreaRef}>
            <ScrollAreaGradient scrollAreaRef={scrollAreaRef} />
            <div className='flex flex-col gap-4 p-4'>
              <Button2
                leadingIcon='add'
                className='text-sm h-10'
                onClick={() => {
                  trackEvent({
                    event: 'spaces-newspace-open',
                    eventProperties: {
                      indexPageName: 'Spaces',
                    },
                  })
                  openCreateSpaceModal()
                }}
              >
                Create New Space
              </Button2>

              <div
                className='rounded-lg bg-[#F4F6FF] flex flex-col gap-4 p-3'
                data-intercom-target='dashboard-sidebar-section-customers'
              >
                <h2 className='font-bold'>Customers</h2>
                <div className='flex flex-col gap-2'>
                  <MenuItemButtonLink
                    to='/spaces'
                    id='dashboard-sidebar-spaces'
                    icon='documents-outline'
                    activeIcon='documents'
                    search={userId ? { ownerIds: [userId] } : undefined}
                    activeOptions={{
                      includeSearch: false,
                    }}
                  >
                    Spaces
                  </MenuItemButtonLink>
                  {taskManagementEnabled && (
                    <MenuItemButtonLink
                      id='dashboard-sidebar-tasks'
                      icon='checkbox-outline'
                      activeIcon='checkbox'
                      to='/tasks'
                      activeOptions={{
                        includeSearch: false,
                      }}
                      search={{
                        ownerIds:
                          hasSpacesAllAdministrationPermission && userId ? [userId] : undefined,
                        searchTimeRange: { months: 3 },
                      }}
                    >
                      <div className='flex justify-between w-full'>
                        Tasks
                        {overdueTasks > 0 && (
                          <Badge variant={'info'} size={'small'}>
                            {overdueTasks}
                          </Badge>
                        )}
                      </div>
                    </MenuItemButtonLink>
                  )}
                  <MenuItemButtonLink
                    id='dashboard-sidebar-activity-feed'
                    icon='c_activity'
                    activeIcon='c_activity'
                    to='/activityFeed'
                    search={userId ? { ownerIds: [userId] } : undefined}
                    activeOptions={{
                      includeSearch: false,
                    }}
                  >
                    <div className='flex justify-between w-full'>
                      Activity Feed
                      {!!unreadCount?.unreadActivitiesCount && (
                        <Badge variant={'info'} size={'small'}>
                          {unreadCount?.unreadActivitiesCount}
                        </Badge>
                      )}
                    </div>
                  </MenuItemButtonLink>
                </div>
              </div>

              {assetsMenuEnabled && (
                <Collapsible
                  data-intercom-target='dashboard-sidebar-section-assets'
                  open={assetsMenuOpen}
                  onOpenChange={setAssetsMenuOpen}
                  className={'p-3'}
                >
                  <CollapsibleTrigger className={'w-full'}>
                    <div className={'w-full flex items-center justify-between font-bold'}>
                      <span>Assets</span>
                      <CollapsibleChevron open={assetsMenuOpen} className='hover:text-grey-s3' />
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent className='pt-3'>
                    <div className='flex flex-col gap-2'>
                      {hasTemplateManagementPermission && (
                        <MenuItemButtonLink
                          id='dashboard-sidebar-templates'
                          icon='grid-outline'
                          activeIcon='grid'
                          to='/templates'
                        >
                          Templates
                        </MenuItemButtonLink>
                      )}
                      {assetLibraryEnabled && (
                        <MenuItemButtonLink
                          id='dashboard-sidebar-asset-library'
                          icon='folder-open-outline'
                          activeIcon='folder'
                          to='/assetLibrary'
                        >
                          Asset Library
                        </MenuItemButtonLink>
                      )}
                      {spacePropertiesEnabled && (
                        <MenuItemButtonLink
                          id='dashboard-sidebar-space-properties'
                          icon='bookmark-outline'
                          activeIcon='bookmark'
                          to='/spaceProperties'
                        >
                          Space Properties
                        </MenuItemButtonLink>
                      )}
                      {workflowsEnabled && (
                        <MenuItemButtonLink
                          id='dashboard-sidebar-workflows'
                          icon='git-branch-outline'
                          activeIcon='git-branch'
                          to='/workflows'
                        >
                          Workflows
                          <Badge variant={'subtle-info'} size={'small'}>
                            BETA
                          </Badge>
                        </MenuItemButtonLink>
                      )}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              )}

              {analyticsMenuEnabled && (
                <Collapsible
                  data-intercom-target='dashboard-sidebar-section-analytics'
                  open={analyticsMenuOpen}
                  onOpenChange={setAnalyticsMenuOpen}
                  className={'p-3'}
                >
                  <CollapsibleTrigger className={'w-full'}>
                    <div className={'w-full flex items-center justify-between font-bold'}>
                      <span>Analytics</span>
                      <CollapsibleChevron open={analyticsMenuOpen} className='hover:text-grey-s3' />
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent className='pt-3'>
                    <div className='flex flex-col gap-2'>
                      {actionPlanAnalyticsEnabled && (
                        <MenuItemButtonLink
                          id='dashboard-sidebar-action-plan-analytics'
                          icon='clipboard-outline'
                          activeIcon='clipboard'
                          to='/analytics/actionPlanAnalytics'
                        >
                          Action Plan
                        </MenuItemButtonLink>
                      )}
                      {teamActivityAnalyticsEnabled && (
                        <MenuItemButtonLink
                          id='dashboard-sidebar-team-activity-analytics'
                          icon='people-outline'
                          activeIcon='people'
                          to='/analytics/teamActivityAnalytics'
                        >
                          Team Activity
                        </MenuItemButtonLink>
                      )}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              )}
            </div>
          </ScrollArea>
          <SidebarFooter />
        </>
      )}
      {showSettingsSidebar && (
        <ScrollArea maxHeight={'100%'} viewportRef={settingsScrollAreaRef}>
          <ScrollAreaGradient scrollAreaRef={settingsScrollAreaRef} />

          <div className='flex flex-col gap-4 p-4'>
            <div>
              <Button2Link
                leadingIcon='grid-outline'
                variant={'subtle'}
                to={'/spaces'}
                size={'small'}
                search={userId ? { ownerIds: [userId] } : undefined}
              >
                Back to Dashboard
              </Button2Link>
            </div>

            <div className='flex flex-col gap-4 p-3'>
              <h2 className='font-bold'>My settings</h2>
              <div className='flex flex-col gap-2'>
                <MenuItemButtonLink
                  id='dashboard-sidebar-profile-account'
                  to='/mySettings/profileAccount'
                >
                  Profile
                </MenuItemButtonLink>
                <MenuItemButtonLink
                  id='dashboard-sidebar-integrations'
                  to='/mySettings/integrations'
                >
                  Integrations
                </MenuItemButtonLink>
                <MenuItemButtonLink
                  id='dashboard-sidebar-mail-notifications'
                  to='/mySettings/mailNotifications'
                >
                  Notifications
                </MenuItemButtonLink>
              </div>
            </div>
            {adminSettingsMenuEnabled && (
              <div className='flex flex-col gap-4 p-3'>
                <h2 className='font-bold'>Admin settings</h2>
                <div className='flex flex-col gap-2'>
                  {companySettingsEnabled && (
                    <MenuItemButtonLink
                      id='dashboard-sidebar-company-settings'
                      to='/administration/companySettings'
                    >
                      Company settings
                    </MenuItemButtonLink>
                  )}
                  {usersManagementEnabled && (
                    <MenuItemButtonLink
                      id='dashboard-sidebar-user-management'
                      to='/administration/userManagement'
                    >
                      Users
                    </MenuItemButtonLink>
                  )}
                  {teamsManagementEnabled && (
                    <MenuItemButtonLink
                      id='dashboard-sidebar-team-management'
                      to='/administration/teamManagement'
                    >
                      Teams
                    </MenuItemButtonLink>
                  )}
                  {billingEnabled && (
                    <MenuItemButton
                      id='dashboard-sidebar-customer-portal'
                      onClick={() => {
                        createCustomerPortalSession()
                      }}
                    >
                      Billing
                    </MenuItemButton>
                  )}
                </div>
              </div>
            )}
          </div>
        </ScrollArea>
      )}
    </div>
  )
}
