import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import {
  formatDateShort as formatDateShortCommon,
  formatDateLong as formatDateLongCommon,
  formatDateTimeShort as formatDateTimeShortCommon,
  formatTime as formatTimeCommon,
  formatDateWithRelativeWords as formatDateWithRelativeWordsCommon,
} from '@valuecase/common'
import { createContext, ReactNode, useCallback, useContext } from 'react'

type DateFormattingLocaleContextType = {
  locale: string
}

export const DateFormattingLocaleContext = createContext<DateFormattingLocaleContextType | null>(
  null,
)

export function DateFormattingLocaleProvider({
  children,
  locale,
}: {
  children: ReactNode
  locale: string
}): JSX.Element {
  return (
    <DateFormattingLocaleContext.Provider value={{ locale }}>
      {children}
    </DateFormattingLocaleContext.Provider>
  )
}

export function useDateFormattingLocale(): DateFormattingLocaleContextType {
  const context = useContext(DateFormattingLocaleContext)
  if (!context) {
    throw new Error('useDateFormattingLocale must be used within a DateFormattingLocaleProvider')
  }
  return context
}

export function useDateFormatting() {
  // todo re-use parsing
  const { locale } = useDateFormattingLocale()

  const parseDate = useCallback((date: Date | string | DateTime) => {
    if (date instanceof Date) {
      date = DateTime.fromJSDate(date)
    } else if (typeof date === 'string') {
      date = DateTime.fromISO(date)
    }
    return date
  }, [])

  const formatDateShort = useCallback(
    (date: Date | string | DateTime) => {
      return formatDateShortCommon(parseDate(date), locale)
    },
    [locale, parseDate],
  )

  const formatDateLong = useCallback(
    (date: Date | string | DateTime) => {
      return formatDateLongCommon(parseDate(date), locale)
    },
    [locale, parseDate],
  )

  const formatDateTimeShort = useCallback(
    (date: Date | string | DateTime) => {
      return formatDateTimeShortCommon(parseDate(date), locale)
    },
    [locale, parseDate],
  )
  const formatTime = useCallback(
    (date: Date | string | DateTime) => {
      return formatTimeCommon(parseDate(date), locale)
    },
    [locale, parseDate],
  )

  const formatDateWithRelativeWords = useCallback(
    (date: Date | string | DateTime, dateFormatting: 'long' | 'short' = 'short') => {
      return formatDateWithRelativeWordsCommon(parseDate(date), dateFormatting, locale)
    },
    [locale, parseDate],
  )

  return {
    formatDateShort,
    formatDateLong,
    formatDateTimeShort,
    formatTime,
    formatDateWithRelativeWords,
  }
}

/**
 * Returns a function that formats a DateTime to a string like "x days ago". Uses translations.
 */
export function useFormatDateTimeDistance() {
  const { t } = useTranslation()

  /**
   * TODO use Luxon toRelative function here
   */
  const formatDateTimeDistance = useCallback(
    (date1: DateTime, date2: DateTime) => {
      if (!date1.isValid || !date2.isValid) {
        return ''
      }
      const diff = date1
        .diff(date2)
        .shiftTo('years', 'months', 'days', 'hours', 'minutes', 'seconds')
      const years = Math.floor(diff.years)
      const months = Math.floor(diff.months)
      const days = Math.floor(diff.days)
      const hours = Math.floor(diff.hours)
      const minutes = Math.floor(diff.minutes)
      const seconds = diff.seconds < 0 ? 0 : Math.floor(diff.seconds)

      if (years === 1) return t('utils.date.oneYearAgo')
      if (years > 0) return t('utils.date.xYearsAgo', { n: years })
      if (months === 1) return t('utils.date.oneMonthAgo')
      if (months > 0) return t('utils.date.xMonthsAgo', { n: months })
      if (days === 1) return t('utils.date.oneDayAgo')
      if (days > 0) return t('utils.date.xDaysAgo', { n: days })
      if (hours === 1) return t('utils.date.oneHourAgo')
      if (hours > 0) return t('utils.date.xHoursAgo', { n: hours })
      if (minutes === 1) return t('utils.date.oneMinuteAgo')
      if (minutes > 0) return t('utils.date.xMinutesAgo', { n: minutes })
      if (seconds === 1) return t('utils.date.oneSecondAgo')
      return t('utils.date.xSecondsAgo', { n: seconds })
    },
    [t],
  )

  return {
    formatDateTimeDistance,
  }
}

export function useDayDifferenceCalculator({ language }: { language?: string } = {}) {
  const { t } = useTranslation()
  const { formatDateShort } = useDateFormatting()

  const daysDiffFromNowOrFormatted = useCallback(
    ({
      to,
      labelledRelativeDatesRange = 7,
    }: {
      to: DateTime
      // If -1, all dates will be shown as date stamps in given locale time, e.g. 01/01/2024
      // If 0, "Today" will get a label, all other dates will be shown as datestamps in given
      //   locale
      // If 1, "Today", "Tomorrow", and "Yesterday" will get labels, all other dates will be shown
      //   as datestamps in given locale
      // If 2 or more, "Today", "Tomorrow", "Yesterday", "In X days", and "X days ago" will get
      //   labels up to the value of the labelledRelativeDatesRange. Dates beyond this range will be
      //   shown as datestamps in given locale.
      labelledRelativeDatesRange?: number
    }) => {
      // Set the 'to' date to the local timezone, then truncate to the start of the day (midnight)
      const incomingLocalDate = to
        .setZone(DateTime.local().zoneName, { keepLocalTime: true })
        .startOf('day')

      // Get the current local date, truncated to the start of the day (midnight)
      const localMidnight = DateTime.now().setZone(DateTime.local().zoneName).startOf('day')

      const daysBetween = incomingLocalDate.diff(localMidnight, 'days').days
      const daysBetweenAbs = Math.abs(daysBetween)
      let formatted = ''

      if (daysBetweenAbs > labelledRelativeDatesRange) {
        formatted = formatDateShort(to)
      } else if (daysBetween > 0) {
        if (daysBetween > 1) {
          formatted = t('utils.date.inXDays', { n: Math.abs(daysBetweenAbs), lng: language })
        } else {
          formatted = t('utils.date.tomorrow', language ? { lng: language } : undefined)
        }
      } else if (daysBetween < 0) {
        if (daysBetween < -1) {
          formatted = t('utils.date.xDaysAgo', { n: Math.abs(daysBetweenAbs), lng: language })
        } else {
          formatted = t('utils.date.yesterday', language ? { lng: language } : undefined)
        }
      } else {
        formatted = t('utils.date.today', language ? { lng: language } : undefined)
      }

      return {
        days: daysBetween,
        message: formatted,
      }
    },
    [formatDateShort, language, t],
  )

  return {
    daysDiffFromNowOrFormatted,
  }
}

export function convertLocalToUTCDate(date: Date | string | null) {
  if (!date) {
    return null
  }
  date = new Date(date)
  if (date.toString() === 'Invalid Date') {
    console.warn('Invalid date when converting local to utc date', date)
    return null
  }
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}

export function convertUTCToLocalDate(date: Date | null) {
  if (!date) {
    return date
  }
  if (date.toString() === 'Invalid Date') {
    console.warn('Invalid date when converting local to utc date', date)
    return null
  }
  date = new Date(date)
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}
