import { FC } from 'react'
import { IonIcon } from '../../Icons/IonIcon'
import { cn } from '../../lib'
import { OneLineTruncatedText } from './text'
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip'

export const Card: FC<{
  id?: string
  iconName: string
  iconClassName?: string
  label: string
  value: string
  tooltip?: string
}> = ({ id, iconName, iconClassName, label, value, tooltip }) => {
  return (
    <div
      id={id}
      className='border py-2 px-6 rounded-lg flex gap-2 justify-between bg-white/85 hover:bg-white/100 transition w-full hover:shadow-text-input border-white'
    >
      <div className='flex gap-2 items-center'>
        <IonIcon className={cn('min-w-4 max-w-4', iconClassName)} name={iconName} />
        <OneLineTruncatedText className='text-xs text-grey-s6 font-normal'>
          {label}
        </OneLineTruncatedText>
        {!!tooltip && (
          <Tooltip>
            <TooltipTrigger>
              <IonIcon className='text-grey-s4' name='help-circle'></IonIcon>
            </TooltipTrigger>
            <TooltipContent align='center' className='max-w-xs'>
              {tooltip}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <div className='text-l font-semibold'>{value}</div>
    </div>
  )
}
