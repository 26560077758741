import { FC, useCallback, useMemo } from 'react'
import { TaskCardView } from './TaskCardView'
import { TActionPlanTaskExpanded, TActionPlanTasksQuery } from '@valuecase/common'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import {
  UpdateTaskParams,
  useDayDifferenceCalculator,
  useUpdateTaskMutation,
} from '@valuecase/ui-components'
import { DateTime } from 'luxon'
import { useNotifications } from '@/utils/Notifications/Notifications'

export const TaskCard: FC<{
  task: TActionPlanTaskExpanded
  taskStatus: TActionPlanTasksQuery['taskStatus']
}> = ({ task, taskStatus }) => {
  const { trackEvent } = useTrackEvent()
  const { error } = useNotifications()
  const updateTaskMutation = useUpdateTaskMutation()

  const dayDifferenceCalculator = useDayDifferenceCalculator({
    // Force english language for seller dashboard - other languages only supported for spaces frontend
    language: 'en-GB',
  })
  const timeToDueDate = useMemo(() => {
    if (!task.dueDate) {
      return null
    }
    const result = dayDifferenceCalculator.daysDiffFromNowOrFormatted({
      to: DateTime.fromJSDate(task.dueDate, {
        zone: 'utc',
      }),
    })
    return result.message
  }, [dayDifferenceCalculator, task.dueDate])

  const handleTaskCardClick = useCallback(() => {
    // Track the click
    trackEvent({
      event: 'task-clicked-task-dashboard',
      eventProperties: {
        taskStatus,
      },
    })

    // Open space/page/block of the task in a new tab
    const url = new URL(location.origin)
    url.pathname = `/spaces/${task.spaceId}`
    url.searchParams.set('token', task.spaceToken)
    url.searchParams.set('block', task.blockId)
    window.open(url.toString(), '_blank')
  }, [task.blockId, task.spaceId, task.spaceToken, taskStatus, trackEvent])

  const handleUpdateTask = useCallback(
    async (params: UpdateTaskParams) => {
      try {
        await updateTaskMutation.mutateAsync(params)
        trackEvent({
          event: 'task-completed-task-dashboard',
          eventProperties: {
            completed: !!params.data.checked,
            taskStatus,
          },
        })
      } catch (err) {
        console.error(err)
        error('Error updating task')
      }
    },
    [error, taskStatus, trackEvent, updateTaskMutation],
  )

  return (
    <TaskCardView
      task={task}
      timeToDueDate={timeToDueDate}
      onClick={handleTaskCardClick}
      handleUpdateTask={handleUpdateTask}
    />
  )
}
