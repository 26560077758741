import { Skeleton } from '@valuecase/ui-components'

export const TaskCardViewSkeleton = () => {
  return (
    <div className='flex flex-col w-full bg-white/85 rounded-lg p-2 gap-2'>
      <div className='flex flex-row items-start gap-3'>
        {/* Checkbox skeleton */}
        <Skeleton className='w-4 h-4 rounded' />

        <div className='flex gap-2'>
          {/* Avatar skeleton */}
          <Skeleton className='w-8 h-8 rounded-md' />

          <div className='flex flex-col flex-1 min-w-0 gap-1'>
            {/* Company name skeleton */}
            <Skeleton className='w-24 h-3' />
            {/* Title skeleton */}
            <Skeleton className='w-48 h-4' />
            {/* Milestone title skeleton */}
            <Skeleton className='w-32 h-3' />
          </div>
        </div>
      </div>

      <div className='flex flex-row items-center w-full'>
        <div className='flex-1'>
          {/* Assignees skeleton */}
          <div className='flex gap-1'>
            <Skeleton className='w-6 h-6 rounded-full' />
            <Skeleton className='w-6 h-6 rounded-full' />
          </div>
        </div>
        {/* Date skeleton */}
        <div className='flex items-center gap-2 ml-auto'>
          <Skeleton className='w-3 h-3' />
          <Skeleton className='w-16 h-3' />
        </div>
      </div>
    </div>
  )
}
