import { Progress } from '@valuecase/ui-components'
import { useLoadingDots } from './CreateTemplateAndSpaceLoadingStep'
import { useState, useEffect, useCallback, useMemo } from 'react'

export const CreateSpaceFromTemplateStep = () => {
  const loadingDots = useLoadingDots()
  const [progress, setProgress] = useState(0)

  const calculateNextProgress = useCallback((currentProgress: number) => {
    if (currentProgress >= 95) {
      return currentProgress + 0.1
    } else if (currentProgress >= 80) {
      return currentProgress + 0.3
    } else if (currentProgress >= 60) {
      return currentProgress + 0.5
    } else {
      return currentProgress + 1
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((currentProgress) => {
        const nextProgress = calculateNextProgress(currentProgress)
        return nextProgress >= 98 ? 98 : nextProgress
      })
    }, 100)

    return () => {
      clearInterval(timer)
    }
  }, [calculateNextProgress])

  const progressValue = useMemo(() => {
    return Math.min(Math.round(progress), 98)
  }, [progress])

  return (
    <div className='w-full h-full flex flex-col gap-3 items-center justify-center p-8'>
      <div className='md:w-1/3 w-full flex flex-col gap-3'>
        <span className='text-lg font-semibold text-grey-s6'>
          Hang on! We&apos;re creating your first space{loadingDots}
        </span>
        <Progress className='w-full bg-transparent' variant='info' value={progressValue} />
        <span className='text-xs text-grey-s5'>
          This usually takes less than 5 seconds, but in rare cases, it may take up to 30 seconds.
        </span>
      </div>
    </div>
  )
}
