import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateTemplateQueryTemplateInput } from '../types/CreateTemplateQueryTemplateInput'
import { CreateTemplateReqDTO } from './types/CreateTemplateReqDTO'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

export async function createTemplateQueryFn(templateInput: CreateTemplateQueryTemplateInput) {
  const { ...template } = templateInput
  const templateDto: CreateTemplateReqDTO = {
    sourceTemplateId: template.sourceTemplateId,
    teamIds: template.teamIds,
    data: {
      description: template.description,
      title: template.title,
      icon: template.icon,
    },
  }
  const response = await spacesApi.post(`/templates`, templateDto)
  return response.data
}

export function useCreateTemplate(props?: { onSuccess: (data: any) => void; onError: () => void }) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: createTemplateQueryFn,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['readTemplates'] })
      if (props?.onSuccess) {
        props.onSuccess(data)
      }
    },
    onError: () => {
      if (props?.onError) {
        props.onError()
      }
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    createTemplate: (templateInput: CreateTemplateQueryTemplateInput) =>
      mutation.mutate(templateInput),
    createTemplateAsync: async (templateInput: CreateTemplateQueryTemplateInput) =>
      mutation.mutateAsync(templateInput),
  }
}
