import { useCallback, useMemo, useState } from 'react'
import { IntegrationItemSelector } from '../IntegrationItemSelector'
import { ColumnHelper, SortingState } from '@tanstack/react-table'
import { useIntegrationSearch } from '../hooks/useIntegrationSearch'
import {
  HubSpotIntegrationObjectTypeMapping,
  useSearchHubSpotObjects,
} from '@/modules/integrations/hubspot/useSearchHubSpotObjects'
import { HubSpotDeal, HubSpotCompany, HubSpotContact } from '@/modules/integrations/hubspot/types' // Ensure these are defined
import { LabelRadioInput } from '@valuecase/ui-components/src/components/ui/label-radio-input'
import { useReadSpaceIntegrationIdMappings } from '@/modules/spaces/hooks/useReadSpaceIntegrationIdMappings'

export function HubSpotObjectSelector({
  onObjectSelected,
  selectedObject,
  spaceId,
}: {
  onObjectSelected: (
    object: HubSpotDeal | HubSpotCompany | HubSpotContact,
    objectType: 'Deal' | 'Company' | 'Contact',
  ) => void
  selectedObject?: HubSpotDeal | HubSpotCompany | HubSpotContact
  spaceId?: string
}) {
  const { data: existingMappings } = useReadSpaceIntegrationIdMappings(spaceId)
  const [activeTab, setActiveTab] = useState<'Deals' | 'Companies' | 'Contacts'>('Deals')
  const [itemType, setItemType] = useState<'Deal' | 'Company' | 'Contact'>('Deal')
  const createDateColumnId = 'createDate'
  const [sorting, onSortingChange] = useState<SortingState>([
    { id: createDateColumnId, desc: true },
  ])
  const limit = 100
  const alreadyLinkedIds = useMemo(() => {
    if (!Array.isArray(existingMappings) || !existingMappings.length) {
      return new Set()
    }
    return new Set(
      existingMappings
        .filter((m) => m.thirdParty === 'HubSpot' && m.thirdPartyEntityType === itemType)
        .map((m) => stripPortalIdFromHubSpotId(m.thirdPartyId)),
    )
  }, [existingMappings, itemType])

  const dealSearch = useIntegrationSearch<HubSpotIntegrationObjectTypeMapping, 'deals'>({
    readFunction: useSearchHubSpotObjects,
    enabled: activeTab === 'Deals',
    limit,
    objectType: 'deals',
    objectTypeName: 'Deal',
  })

  const companySearch = useIntegrationSearch<HubSpotIntegrationObjectTypeMapping, 'companies'>({
    readFunction: useSearchHubSpotObjects,
    enabled: activeTab === 'Companies',
    limit,
    objectType: 'companies',
    objectTypeName: 'Company',
  })

  const contactSearch = useIntegrationSearch<HubSpotIntegrationObjectTypeMapping, 'contacts'>({
    readFunction: useSearchHubSpotObjects,
    enabled: activeTab === 'Contacts',
    limit,
    objectType: 'contacts',
    objectTypeName: 'Contact',
  })

  const renderColumns = useCallback(
    (columnHelper: ColumnHelper<any>) => {
      const columns = [
        columnHelper.display({
          id: 'checkbox',
          maxSize: 48,
          header(props) {
            return <div {...props} className='max-w-48'></div>
          },
          cell: (ctx) => {
            const isAlreadyLinked = alreadyLinkedIds.has(ctx.row.original.id)
            return (
              <LabelRadioInput
                // If already linked, then move it out of radio group into it's own little group
                // where it can be preselected
                name={isAlreadyLinked ? ctx.row.original.id : 'hubSpotObject'}
                disabled={isAlreadyLinked}
                value={ctx.row.original.id}
                checked={isAlreadyLinked ? true : selectedObject?.id === ctx.row.original.id}
                // Read only because change to state is handled by the parent component
                readOnly={true}
                screenReaderText={`Select ${activeTab.toLowerCase()} ${
                  ctx.row.original.properties.name || ctx.row.original.properties.dealname
                }`}
              />
            )
          },
          meta: {
            cellClass: 'border-r border-solid border-grey-s2',
          },
        }),
      ]
      if (activeTab === 'Deals') {
        columns.push(
          columnHelper.accessor('properties.dealname', {
            size: 700,
            id: 'dealName',
            header: 'Deal Name',
            cell: (ctx) => ctx.row.original.properties.dealname,
          }) as any,
        )
      }
      if (activeTab === 'Companies') {
        columns.push(
          columnHelper.accessor('properties.name', {
            size: 280,
            id: 'name',
            header: 'Company Name',
            cell: (ctx) => ctx.row.original.properties.name,
          }) as any,
        )
        columns.push(
          columnHelper.accessor('properties.website', {
            size: 210,
            id: 'website',
            header: 'Website',
            cell: (ctx) => ctx.row.original.properties.website,
          }) as any,
        )
        columns.push(
          columnHelper.accessor('properties.ownername', {
            size: 180,
            id: 'ownerName',
            header: 'Owner',
            cell: (ctx) => ctx.row.original.properties.ownername,
          }) as any,
        )
      }
      if (activeTab === 'Contacts') {
        columns.push(
          columnHelper.accessor('properties.firstname', {
            id: 'name',
            size: 330,
            header: 'Name',
            cell: (ctx) => (
              <span>
                {ctx.row.original.properties.firstname} {ctx.row.original.properties.lastname}
              </span>
            ),
          }) as any,
        )
        columns.push(
          columnHelper.accessor('properties.email', {
            size: 360,
            id: 'email',
            header: 'Email',
            cell: (ctx) => ctx.row.original.properties.email,
          }) as any,
        )
      }
      columns.push(
        columnHelper.accessor('properties.createdate', {
          id: createDateColumnId,
          header: 'Created',
          size: 147,
          cell: (ctx) => new Date(ctx.row.original.properties.createdate).toLocaleDateString(),
        }) as any,
      )
      return columns
    },
    [activeTab, alreadyLinkedIds, selectedObject?.id],
  )

  const {
    data,
    searchTerm,
    searchStatus,
    searchErrorMessage,
    onSearchTermInput,
    initialLoadComplete,
    hasMoreData,
  } = activeTab === 'Deals' ? dealSearch : activeTab === 'Companies' ? companySearch : contactSearch

  return (
    <div>
      <IntegrationItemSelector
        sorting={sorting}
        onSortingChange={onSortingChange}
        items={data || []}
        tabs={['Deals', 'Companies', 'Contacts']}
        activeTab={activeTab}
        setActiveTab={(value) => {
          if (value === 'Deals' || value === 'Companies' || value === 'Contacts') {
            setActiveTab(value)
            setItemType(value === 'Deals' ? 'Deal' : value === 'Companies' ? 'Company' : 'Contact')
          } else {
            throw new Error('Invalid tab ' + value + ' selected.')
          }
        }}
        searchStatus={searchStatus}
        searchTerm={searchTerm}
        searchPlaceholder={`Search ${activeTab.toLowerCase()}...`}
        initialLoadComplete={initialLoadComplete}
        searchErrorMessage={searchErrorMessage}
        selectItem={(item, itemType) => {
          if (alreadyLinkedIds.has(item.id)) {
            return
          }
          onObjectSelected(item, itemType as 'Deal' | 'Company' | 'Contact')
        }}
        itemType={itemType}
        selectedItem={selectedObject}
        onSearchItems={onSearchTermInput}
        renderColumns={renderColumns as any}
        footerText={hasMoreData && `Displaying the ${limit} most recent items. Search to view all.`}
      />
    </div>
  )
}

export function stripPortalIdFromHubSpotId(dealId: string) {
  const parts = dealId.split('_')
  if (parts.length === 2) {
    return parts[1]
  }
  return dealId
}
