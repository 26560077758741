import { useQuery } from '@tanstack/react-query'
import BillingApi from '../../../api/resources/BillingApi'
import { subscriptionInfoSchema } from '@valuecase/common'

const fetchSubscriptionInfo = async () => {
  const response = await BillingApi.getSubscriptionInfo()
  const result = subscriptionInfoSchema.safeParse(response.payload)
  if (!result.success) {
    throw new Error('Failed to fetch subscription info')
  }
  return result.data
}

export const useReadSubscriptionInfo = () => {
  return useQuery(['subscriptionInfo'], () => fetchSubscriptionInfo(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  })
}
